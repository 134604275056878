import * as api from '../api';

// Action Creators
export const login = (email, password) => async (dispatch) => {
  try {
    const { data } = await api.loginUser({ email, password });
    dispatch({ type: 'LOGIN', payload: data });
    localStorage.setItem('user', data);
  } catch (error) {
    console.log(error.message);
  }
};

export const logout = () => async (dispatch) => {
  try {
    dispatch({ type: 'LOGOUT' });
    localStorage.removeItem('user');
  } catch (error) {
    console.log(error.message);
  }
};

export const signup = (email, password) => async (dispatch) => {
  try {
    const { data } = await api.signupUser({ email, password });
    dispatch({ type: 'LOGIN', payload: data });
    localStorage.setItem('user', data);
  } catch (error) {
    console.log(error.message);
  }
};


export const getToken = (email) => async (dispatch) => {
  try {
    const { data } = await api.getToken({email});
    dispatch({ type: 'GET_TOKEN', payload: data });
    localStorage.setItem('token', data.token);
  } catch (error) {
    console.log(error.message);
  }  
};

export const getSubscriptionDetails = () => async (dispatch) => {
  try {
    const { data } = await api.getSubscriptionDetails();
    dispatch({ type: 'SET_SUBSCRIPTION', payload: data });
    localStorage.setItem('subscriptionType', data.stripe.subscriptionType);
    localStorage.setItem('billingPeriod', data.stripe.billingPeriod);
    localStorage.setItem('stripeCustomerId', data.stripe.customerId);
    localStorage.setItem('stripeSubscriptionId', data.stripe.subscriptionId);
  } catch (error) {
    console.log(error.message);
  }  
};

export const updateSubscriptionType = (type, periodType) => async (dispatch) => {
  const {data} = await api.updateSubscriptionType(type, periodType);
  dispatch({ type: 'UPDATE_SUBSCRIPTION_TYPE', payload: data });
  localStorage.setItem('subscriptionType', data.stripe.subscriptionType);
};

export const setSubscriptionType = (stripeDetails) => async (dispatch) => {
  try {
    await api.setSubscriptionType(stripeDetails);    
    const { data } = await api.getSubscriptionDetails();
    dispatch({ type: 'SET_SUBSCRIPTION', payload: data });
    localStorage.setItem('subscriptionType', data.stripe.subscriptionType);
    localStorage.setItem('billingPeriod', data.stripe.billingPeriod);
    localStorage.setItem('stripeCustomerId', data.stripe.customerId);
    localStorage.setItem('stripeSubscriptionId', data.stripe.subscriptionId);
    return data;
  } catch (error) {
    console.log(error.message);
  }  
};


export const deleteUser = () => async () => {
  try {
    const {data} = await api.deleteUser();
    return data;
  } catch (error) {
    console.log(error.message);
  }  
};