import * as api from '../api';

// Action Creators
export const updateTheme = (theme) => async (dispatch) => {
  try {
    dispatch({ type: 'SET_THEME', payload: theme });
  } catch (error) {
    console.log(error.message);
  }
};

export const getTableMetrics = () => async (dispatch, getState) => {
  try {
    const { data } = await api.getTableMetrics();
    dispatch({ type: 'SET_TABLE_METRICS', payload: data.tableMetrics });
  } catch (error) {
    console.log(error.message)
  }
}

export const updateTableMetrics = (metrics) => async (dispatch) => {
  try {
    await api.updateTableMetrics(metrics);
    dispatch({ type: 'SET_TABLE_METRICS', payload: metrics });
    return true;
  } catch (error) {
    console.log(error.message);
    return false;
  }
};

export const getTableRows = () => async (dispatch, getState) => {
  try {
    const { data } = await api.getTableRows();
    dispatch({ type: 'SET_TABLE_ROWS', payload: data.tableRows });
  } catch (error) {
    console.log(error.message)
  }
}

export const updateTableRows = (rows) => async (dispatch) => {
  try {
    dispatch({ type: 'SET_TABLE_ROWS', payload: rows });
    await api.updateTableRows(rows);
  } catch (error) {
    console.log(error.message);
  }
};

export const getFundamentalPeriodType = () => async (dispatch, getState) => {
  try {
    const { data } = await api.getFundamentalPeriodType();
    dispatch({ type: 'SET_FUNDAMENTAL_PERIOD_TYPE', payload: data });
  } catch (error) {
    console.log(error.message)
  }
}

export const updateFundamentalPeriodType = (period) => async (dispatch) => {
  try {
    dispatch({ type: 'SET_FUNDAMENTAL_PERIOD_TYPE', payload: period });
    await api.updateFundamentalPeriodType(period);
  } catch (error) {
    console.log(error.message);
  }
};

export const updatePriceChartTimeframe = (timeframe) => async (dispatch) => {
  dispatch({ type: 'SET_PRICE_CHART_TIMEFRAME', payload: timeframe });
  await api.updatePriceChartTimeframe(timeframe);
};


export const getFundamentalTimeHorizon = () => async (dispatch, getState) => {
  try {
    const { data } = await api.getFundamentalTimeHorizon();
    dispatch({ type: 'SET_FUNDAMENTAL_TIME_HORIZON', payload: data });
  } catch (error) {
    console.log(error.message)
  }
}

export const updateFundamentalTimeHorizon = (timeHorizon) => async (dispatch) => {
  try {
    dispatch({ type: 'SET_FUNDAMENTAL_TIME_HORIZON', payload: timeHorizon });
    await api.updateFundamentalTimeHorizon(timeHorizon);
  } catch (error) {
    console.log(error.message);
  }
};

export const setDisplayTheme = (theme) => async (dispatch) => {
  try {
    dispatch({ type: "SET_THEME", payload: theme });
    localStorage.setItem('theme', theme);
    await api.setDisplayTheme(theme);
  } catch (error) {
    console.log(error.message);
  }
};

export const getUserPreferences = () => async (dispatch) => {
  try {
    const {data} = await api.getUserPreferences();
    dispatch({ type: "SET_PRICE_CHART_TIMEFRAME", payload: data.preferences.priceChartTimeframe });
    dispatch({ type: "SET_THEME", payload: data.preferences.theme });
    dispatch({type: "SET_TABLE_METRICS", payload: data.preferences.tableMetrics});
    dispatch({type: "SET_TABLE_ROWS", payload: data.preferences.tableRows});
    dispatch({type: "SET_FUNDAMENTAL_PERIOD_TYPE", payload: data.preferences.fundamentalPeriodType});
    dispatch({type: "SET_FUNDAMENTAL_TIME_HORIZON", payload: data.preferences.fundamentalTimeHorizon}); 
  } catch (error) {
    console.log(error.message);
  }
};

export const getUserGraphSettings = () => async (dispatch) => {
  try {
    const {data: {graphSettings}} = await api.getUserGraphSettings();
    dispatch({
      type: "SET_USER_GRAPH_SETTINGS",
      payload: graphSettings,
    });
  } catch (err) {
    console.log(err);
  }
}

export const setUserGraphSettings = (func, option, setting) => async (dispatch) => {
  try {
    await api.updateUserGraphSettings({option, setting});
    func[option] = setting;
    dispatch({
      type: "SET_USER_GRAPH_SETTINGS",
      payload: func,
    });
  } catch (error) {
    console.log(error)
  }
}

export const getUserGraphPreferences = () => async (dispatch) => {
  try {
    const {data: {graphPreferences}} = await api.getUserGraphPreferences();
    dispatch({
      type: "SET_USER_GRAPH_PREFERENCES",
      payload: graphPreferences,
    });
  } catch (err) {
    console.log(err);
  }
}

export const setUserGraphPreferences = (func, statType, option, preference) => async (dispatch) => {
  try {
    await api.updateUserGraphPreferences({statType, option, preference});
    func[statType][option] = preference;
    dispatch({
      type: "SET_USER_GRAPH_PREFERENCES",
      payload: func,
    });
  } catch (error) {
    console.log(error)
  }
}