
import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import styled, { keyframes } from 'styled-components'
import tw from 'twin.macro'

// Keyframes for animations
const pulseGreen = keyframes`
  0%, 100% {
    ${tw`text-textBase`}
  }
  50% {
    ${tw`text-green-500`}
  }
`;

const pulseRed = keyframes`
  0%, 100% {
    ${tw`text-textBase`}
  }
  50% {
    ${tw`text-red-500`}
  }
`;

// Styled components
const TickerView = styled(motion.div)` ${tw`h-full m-auto flex flex-row-reverse overflow-hidden text-textBase text-xl relative`} `;
const NumberPlaceholder = styled.span` ${tw`invisible`} `;
const TickerColumnContainer = styled.div` ${tw`relative`} `;
const TickerDigit = styled.div` ${tw`h-[10%]`}`;

const TickerColumn = styled(motion.div)` ${tw`h-[1000%] absolute bottom-0`}
  &.increase {
    animation: ${pulseGreen} 500ms cubic-bezier(0.4, 0, 0.6, 1) 1;
  }

  &.decrease {
    animation: ${pulseRed} 500ms cubic-bezier(0.4, 0, 0.6, 1) 1;
  }
`;

const formatForDisplay = (number = 0) => {
  return parseFloat(Math.max(number, 0)).toFixed(2).split("").reverse();
}

const DecimalColumn = () => {
  return (
    <div>
      <span>.</span>
    </div>
  );
}

const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

const NumberColumn = ({ digit, delta }) => {
  const [position, setPosition] = useState(0);
  const [animationClass, setAnimationClass] = useState(null);
  const previousDigit = usePrevious(digit);
  const columnContainer = useRef();

  const setColumnToNumber = (number) => {
    setPosition(columnContainer.current.clientHeight * parseInt(number, 10));
  };

  useEffect(() => setAnimationClass(previousDigit !== digit ? delta : ""), [digit, delta]);
  useEffect(() => setColumnToNumber(digit), [digit]);

  return (
    <TickerColumnContainer ref={columnContainer}>
      <TickerColumn animate={{ y: position }} className={animationClass} onAnimationComplete={() => setAnimationClass("")}>
        {[...Array(10).keys()].reverse().map( num => (
          <TickerDigit key={num}>
            <span>{num}</span>
          </TickerDigit>
        ))}
      </TickerColumn>
      <NumberPlaceholder>0</NumberPlaceholder>
    </TickerColumnContainer>
  );
}

export const AnimatingNumber = ({ value }) => {
  const numArray = formatForDisplay(value);
  const previousNumber = usePrevious(value);

  const delta = value > previousNumber ? "increase" : value < previousNumber ? "decrease" : null;

  return (
    <TickerView>
      {numArray.map((number, index) =>
        number === "." ? (
          <DecimalColumn key={index} />
        ) : (
          <NumberColumn key={index} digit={number} delta={delta} />
        )
      )}
    </TickerView>
  );
}