import { useEffect } from "react"

const useWindowSize = (handler) => {
    useEffect(() => {

        window.addEventListener('resize', handler)

        return () => window.removeEventListener('resize', handler)
    }, [])
}

export default useWindowSize