import * as api from '../api';

// Action Creators
export const fetchWatchList = () => async (dispatch) => {
  try {
    const { data } = await api.fetchWatchList();
    dispatch({ type: 'FETCH_WATCHLIST', payload: data });
  } catch (error) {
    console.log(error.message)
  }
}

export const getWatchList = () => async (dispatch) => {
  try {
    const { data } = await api.getWatchList();
    dispatch({ type: 'GET_WATCHLIST', payload: data });
  } catch (error) {
    console.log(error.message)
  }
}

export const removeFromWatchList = (ticker) => async (dispatch) => {
  try {
    await api.removeFromWatchList(ticker);
    dispatch({ type: 'REMOVE_DATA_FROM_WATCHLIST', payload: ticker });
  } catch (error) {
    console.log(error.message)
  }
}

export const addToWatchList = (ticker) => async (dispatch) => {
  try {
    const { data } = await api.addToWatchList(ticker);
    dispatch({ type: 'ADD_DATA_TO_WATCHLIST', payload: data });
  } catch (error) {
    console.log(error.message)
  }
}