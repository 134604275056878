import { nanoid } from '@reduxjs/toolkit'
import { BsPerson, BsCreditCard2Front, BsShieldLock } from 'react-icons/bs'
import { IoNotificationsOutline, IoHeadsetOutline } from 'react-icons/io5'
import { HiOutlineDocumentText } from 'react-icons/hi'

export const profileSettings = [
  {
    key: nanoid(),
    icon: BsPerson,
    header: 'Personal info',
    content:
      'Update your email, password, personal details or delete your account',
    page: '/account/personal-info',
  },
  {
    key: nanoid(),
    icon: BsCreditCard2Front,
    header: 'Payments and packages',
    content:
      'Update your card details, change your package or update your payment plan',
    page: '/account/payments-and-packages',
  },
  /**Currently disable */
  //   {
  //     key: nanoid(),
  //     icon: IoNotificationsOutline,
  //     header: 'Notifications',
  //     content: 'Choose notification preferences and how you want to be contacted',
  //     page: '/account/notifications',
  //   },
  {
    key: nanoid(),
    icon: HiOutlineDocumentText,
    header: 'Terms of service',
    content: 'Outlines the rules and guidelines for using our platform(s)',
    page: '/account/terms-of-service',
  },
  {
    key: nanoid(),
    icon: BsShieldLock,
    header: 'Privacy policy',
    content: 'Details how we handle and protect your personal information',
    page: '/account/privacy',
  },
  {
    key: nanoid(),
    icon: IoHeadsetOutline,
    header: 'Support',
    content: 'Contact us for technical issues, suggestions, and general questions',
    page: '/account/support',
  },
]
