import initialState from "../state";

// Reducer to fetch all macroeconomics data from the database
const screenerReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_SCREENER_OPTIONS':
      return {
        ...state,
        screenerOptions: action.payload
      };
    case 'FETCH_SCREENER_RESULTS':
      return {
        ...state,
        screenerResults: action.payload
      };
    default:
      return state;
  }
}

export default screenerReducer;