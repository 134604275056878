// External Imports
import React from 'react';
import styled from "styled-components";
import tw from "twin.macro";
import { motion } from "framer-motion";
import { NumericFormat } from "react-number-format";
import { IoClose, IoArrowUp, IoArrowDown } from "react-icons/io5";
import { TiTick } from "react-icons/ti";

// Styling
const TableName = styled.div` ${tw`font-semibold text-sm`}; `; 
const Table = styled.table`${tw`border-collapse`}`;
const TableTextCell = styled.td`${tw`text-left align-middle text-xs text-textMuted`}; min-width: ${props => props.minWidth}px;`;
const TableValueCell = styled.td`${tw`text-right align-middle text-xs min-w-[70px]`}`;
const TableChangeCell = styled(motion.div)`${tw`flex flex-row justify-end items-center font-semibold text-[#0F4C81] pl-1`}`;
const IconChangeContainer = styled(motion.td)` ${tw`w-[25px] flex-row items-center text-center font-semibold`}; `;

export const OverviewTable = ({data, minWidth}) => {
  const colorVariants = { green: {color: "var(--textPositive)"}, red: {color: "var(--textNegative)"}}; //"#C9003C" #0F4C81

  return (
    <>
      {data.name && (<TableName>{data.name}</TableName>)}
      <Table>
        <tbody>
          {data.metrics.map((metric, index) => {
            const logic = parseFloat(metric.value) > metric.threshold ;
            const isValueNA = isNaN(parseFloat(metric.value)) || metric.value === undefined;

            return (
              <tr key={index}>
                {metric.threshold !== undefined && (
                  <IconChangeContainer
                    animate={logic ? "green" : "red"}
                    variants={colorVariants}
                  >
                    {logic ? <TiTick /> : <IoClose/>}
                  </IconChangeContainer>
                )}
                <TableTextCell minWidth={minWidth}>{metric.name}</TableTextCell>
                <TableValueCell>
                  {isValueNA ? "N/A" : (
                    <>
                      <NumericFormat value={metric.value} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale={true} />
                      {metric.suffix}
                    </>
                  )}
                  {metric.change !== undefined && !isValueNA && (
                    <TableChangeCell
                      animate={metric.change > 0 ? "green" : "red"}
                      variants={colorVariants}
                    > 
                      {metric.change > 0 ? <IoArrowUp/> : <IoArrowDown/>}
                      {Math.abs(metric.change)}
                      {metric.changeSuffix}
                    </TableChangeCell>
                  )}
                </TableValueCell>
              </tr>
            );

          })}
        </tbody>
      </Table>
    </>
  )
}