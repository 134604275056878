export const getFormattedValue = ({ value, valueFormat, maxValue, decimals }) => {
  const suffixes = ['T', 'B', 'M', 'K'];
  const divisors = [1e12, 1e9, 1e6, 1e3];
  let suffix = '';
  let divisor = 1;

  for (let i = 0; i < suffixes.length; i++) {
    if (Math.abs(maxValue) >= divisors[i]) {
      suffix = suffixes[i];
      divisor = divisors[i];
      break;
    }
  }

  const formattedValue = (Math.abs(value) / divisor).toFixed(decimals);
  const valueFormats = {
    percentage: () => {
      const formattedPercentage = (value * 100).toFixed(decimals);
      return `${formattedPercentage.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}%`;
    },
    currency: () => `${value < 0 ? '-' : ''}$${formattedValue}${suffix}`,
    default: () => `${value < 0 ? '-' : ''}${formattedValue}${suffix}`
  };

  const formatter = valueFormats[valueFormat] || valueFormats.default;
  return formatter();
};

export const formatValue = (value) => {
  const units = ['', 'K', 'M', 'B', 'T'];
  let i = 0;
  value = Number(value);
  while (Math.abs(value) >= 1e3 && i < units.length - 1) {
    value /= 1e3;
    i++;
  }
  return value.toFixed(2) + units[i];
};