// External Imports
import React from 'react'
import { useDispatch } from 'react-redux';
import styled from "styled-components";
import tw from "twin.macro";
import numeral from 'numeral';
import { IoArrowUp, IoArrowDown } from "react-icons/io5";

// Styling
const Container = styled.div` ${tw`rounded-md w-[fit-content] ml-2 text-xs space-x-2 flex flex-row items-center justify-center`};`;
const ChangeContainer = styled.div` ${tw`ml-1 flex flex-row items-center justify-center`}; `;
const NumericButton = styled.button` 
  ${tw`w-7 h-7 p-1 flex items-center justify-center rounded-md hover:font-semibold hover:text-textBase hover:shadow-md border-1 border-primaryBg hover:border-blue-500`};
  background-color: ${({ active }) => active ? "var(--activeBg)" : "var(--neutralBg)"};
  color: ${({ active }) => active ? "var(--textButtonActive)" : "var(--textMuted)"};
  pointer-events: ${({ active }) => active ? "none" : "auto"};
`; 
const TextButton = styled.button` 
  ${tw`w-[100px] text-xs font-semibold cursor-pointer rounded p-1 flex justify-center hover:text-textBase hover:shadow-md border-1 border-primaryBg hover:border-blue-500`}; 
  background-color: ${({ active }) => active ? "var(--activeBg)" : "var(--neutralBg)"};
  /* border-color: ${({ active }) => active ? "var(--activeBg)" : "var(--neutral)"}; */
  color: ${({ active }) => active ? "var(--textButtonActive)" : "var(--textMuted)"};
  pointer-events: ${({ active }) => active ? "none" : "auto"};
  width: ${({ fit }) => fit ? "auto" : ""};
  padding-left: ${({ fit }) => fit ? "10px" : ""};
  padding-right: ${({ fit }) => fit ? "10px" : ""};
`;

// Displays a list of numeric options that can be selected. Selected item is styled as active.
export const NumericOptionSelector = ({ name, options, selection, setSelection, setSelectionResolve=()=>{} }) => {
  const dispatch = useDispatch();

  const handleSelect = (value) => {
    dispatch(setSelection(value)).then(setSelectionResolve);
  };

  return (
    <>
      {name && <div className="text-sm">{name}:</div>}
      <Container>
        {options.map((value) => (
          <NumericButton key={value} type='button' active={value === selection} onClick={() => handleSelect(value)}> {value} </NumericButton>
        ))}
      </Container>
    </>
  );
};

// Displays a list of text options that can be selected. Selected item is styled as active. Where the option has value changes, display the value changes,
export const TextOptionSelector = ({ options, selection, setSelection, fit, isDispatchable=true, priceChange }) => {
  const dispatch = useDispatch();

  const handleSelect = (value) => {
    if(isDispatchable)
      dispatch(setSelection(value));
    else
      setSelection(value);
  };

  return(
    <div className='space-x-2 flex flex-row'>
      {options.map((value) => {
        const metric = priceChange && priceChange.find(item => item.name.includes(value));
        const isActive = value === selection;

        return (
          <TextButton key={value} type='button' fit={fit} active={isActive} onClick={() => handleSelect(value)}>
            {value}
            {metric && 
              <ChangeContainer className={`${metric.change > 0 ? 'text-textPositive' : metric.change < 0 ? 'text-textNegative' : isActive ? 'text-textInverted' : 'text-textBase'} transition-colors`}>
                {metric.change > 0 ? <IoArrowUp className='h-full flex justify-center'/> : metric.change < 0 ? <IoArrowDown className='h-full flex justify-center'/> : null} 
                {numeral(Math.abs(metric.change)).format('0,0.00')}% 
              </ChangeContainer>
            }
          </TextButton>
        )
      })}
    </div>
  )
}