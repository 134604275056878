// External imports
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { motion } from 'framer-motion'
import { IoCaretDown, IoCaretUp } from 'react-icons/io5'

// Internal imports
import { useClickOutside } from '../../hooks'
import { nameConversions } from '../../data/metricOptions'
import { metricOptions } from '../../data/metricOptions'

// Styling
const DropDownContainer = styled.div`
  ${tw`select-none cursor-pointer relative text-xs `};
`
const DropDownInputContainer = styled.div`
  ${tw`flex items-center bg-neutralBg text-xs hover:text-textBase text-textMuted justify-between rounded cursor-pointer hover:shadow-md border-1 border-primaryBg hover:border-blue-500`};
`
const DropDownInput = styled.input`
  ${tw`h-full outline-none rounded cursor-pointer text-xs bg-[transparent] select-none`};
  &::placeholder {
    transition: all 500ms ease-in-out;
    color: ${({ theme }) => theme.colors.placeholderText};
  }
  &:hover&::placeholder {
    color: ${({ theme }) => theme.colors.placeholderTextHover};
  }
`
const DropDownContent = styled.div`
  ${tw`flex flex-col w-full max-h-[146px] absolute mt-2 overflow-x-hidden overflow-y-auto cursor-auto text-textBase bg-primaryBg text-xs rounded shadow-md border-1 border-neutral hover:border-blue-500`};
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #e2e8f0;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #42464F;
    border-radius: 1px;
    &:hover {
      background-color: #42464FCE;
    }
  }
`
const DropDownItem = styled(motion.div)`
  ${tw`flex items-center py-1 text-xs cursor-pointer bg-primaryBg text-textMuted hover:text-textBase hover:bg-positiveBg`};
`

// Dropdown that allows multiple options to be selected from a list, whilst preventing the same option from being selected twice
export const MultiSelectDropdown = ({
  category,
  inputPlaceholder,
  dropDownOptions,
  selected,
  setSelected,
}) => {
  const optionActivition = {
    active: { color: 'var(--textBase)' },
    inactive: { color: 'var(--textMuted)' },
  }
  const [isOpen, setIsOpen] = useState(false)

  let domNode = useClickOutside(() => {
    setIsOpen(false)
  })

  // Convert short names to expanded names
  const getExpandedName = (shortName) => {
    const conversion = nameConversions.find(
      (name) => name.shortName === shortName
    )
    return conversion ? conversion.name : shortName
  }

  // Add selected option to selected list and close the dropdown. Does not allow an item to be selected twice
  const handleSelect = (option) => {
    if (!selected.includes(option)) {
      setSelected(selected.concat(option))
      setIsOpen(false)
    }
  }

  return (
    <DropDownContainer ref={domNode}>
      <DropDownInputContainer onClick={(e) => setIsOpen(!isOpen)}>
        <DropDownInput type="text" placeholder={inputPlaceholder} />
        <span className="mr-1">
          {!isOpen ? <IoCaretDown /> : <IoCaretUp />}
        </span>
      </DropDownInputContainer>
      {isOpen && (
        <DropDownContent className="h-[178px]">
          {dropDownOptions?.sort().map((option) => (
            <DropDownItem
              key={option}
              className="px-2"
              animate={selected.includes(option) ? 'inactive' : 'active'}
              variants={optionActivition}
              transition={{ duration: 0 }}
              onClick={(e) => handleSelect(option)}
            >
              {category === 'Countries' && (
                <img
                  className="mr-2.5"
                  src={`https://flagcdn.com/16x12/${option.toLowerCase()}.png`}
                  width="16"
                  height="12"
                  alt="Flag"
                  type="image/png"
                />
              )}
              {getExpandedName(option)}
            </DropDownItem>
          ))}
        </DropDownContent>
      )}
    </DropDownContainer>
  )
}

// Dropdown for selecting a single item from a list
export const SingleSelectDropdown = ({
  inputPlaceholder,
  dropDownOptions,
  setSelected,
  resetSelection,
  width,
  sortingMethod,
  prefix,
  suffix,
  useParentInput = false,
  parentInput = '',
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [prelimSelection, setPrelimSelection] = useState([])

  useEffect(() => {
    if (resetSelection) {
      setPrelimSelection('')
    }
  }, [resetSelection])

  let domNode = useClickOutside(() => {
    setIsOpen(false)
  })

  const handleSelect = (option) => {
    setSelected(option)
    setIsOpen(false)
    setPrelimSelection(option)
  }

  const sortedOptions =
    sortingMethod === 'asc'
      ? dropDownOptions.sort()
      : sortingMethod === 'dsc'
      ? dropDownOptions.sort().reverse()
      : dropDownOptions

  return (
    <DropDownContainer ref={domNode} className={`w-[${width}]`}>
      <DropDownInputContainer
        onClick={(e) => setIsOpen(!isOpen)}
        className={`w-full`}
      >
        <div className="flex flex-row p-2">
          {prefix && <span>{prefix}</span>}
          <DropDownInput
            className={`w-full`}
            type="text"
            placeholder={inputPlaceholder}
            readOnly
            value={useParentInput ? parentInput : prelimSelection}
          />
          {suffix && <span>{suffix}</span>}
        </div>
        <span className="mr-1">
          {!isOpen ? <IoCaretDown /> : <IoCaretUp />}
        </span>
      </DropDownInputContainer>
      {isOpen && (
        <DropDownContent className="h-fit">
          {sortedOptions?.map((option) => (
            <DropDownItem
              key={option}
              className="px-2"
              transition={{ duration: 0 }}
              onClick={(e) => {
                handleSelect(option)
              }}
            >
              {prefix && <span>{prefix}</span>}
              <span>{option}</span>
              {suffix && <span>{suffix}</span>}
            </DropDownItem>
          ))}
        </DropDownContent>
      )}
    </DropDownContainer>
  )
}

export const SingleSelectMetricDropdown = ({
  inputPlaceholder,
  setSelected,
  resetSelection,
  width,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [prelimSelection, setPrelimSelection] = useState([])

  useEffect(() => {
    if (resetSelection) {
      setPrelimSelection('')
    }
  }, [resetSelection])

  const groupedOptions = metricOptions.reduce((acc, metric) => {
    if (metric.isMetric) {
      if (!acc[metric.category]) {
        acc[metric.category] = []
      }
      acc[metric.category].push(metric)
    }
    return acc
  }, {})

  let domNode = useClickOutside(() => {
    setIsOpen(false)
  })

  const handleSelect = (option) => {
    setSelected(option)
    setIsOpen(false)
    setPrelimSelection(option)
  }

  return (
    <DropDownContainer ref={domNode} className={`z-[5] w-[${width}]`}>
      <DropDownInputContainer onClick={(e) => setIsOpen(!isOpen)}>
        <DropDownInput
          className={`w-[${width}] p-2`}
          type="text"
          placeholder={inputPlaceholder}
          readOnly
          value={prelimSelection}
        />
        <span className="mr-1">
          {!isOpen ? <IoCaretDown /> : <IoCaretUp />}
        </span>
      </DropDownInputContainer>
      {isOpen && (
        <DropDownContent className="h-fit">
          {Object.entries(groupedOptions)
            .filter(([options]) => options.length > 0)
            .map(([category, options]) => (
              <div key={category} className="">
                <div
                  className="px-1 py-1 text-xs font-semibold text-textPositive bg-neutralBg border-l-4 border-textPositive"
                  disabled
                >
                  {category}
                </div>
                {options.map((metric) => (
                  <DropDownItem
                    key={metric.field}
                    transition={{ duration: 0 }}
                    onClick={(e) => handleSelect(metric.displayedText)}
                    className="pl-3"
                  >
                    {metric.displayedText}
                  </DropDownItem>
                ))}
              </div>
            ))}
        </DropDownContent>
      )}
    </DropDownContainer>
  )
}
