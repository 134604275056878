import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router} from 'react-router-dom';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';
import store from './store/store';

import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Auth0Provider
      domain= {process.env.REACT_APP_AUTH0_DOMAIN}
      clientId= {process.env.REACT_APP_AUTH0_CLIENTID}
      authorizationParams={{
        redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI
      }}
    > 
    <Router>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
  </Auth0Provider>
);