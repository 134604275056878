import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useFetchInsiderTradingQuery } from '../../rtk'
import dayjs from 'dayjs'

// Internal imports
import { ContentContainer } from '../../components/index'
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
  getSortedRowModel,
} from '@tanstack/react-table'
import numeral from 'numeral'
import {
  TiArrowUnsorted,
  TiArrowSortedDown,
  TiArrowSortedUp,
} from 'react-icons/ti'

// Styling
const ListContainer = styled.div`
  ${tw`w-full overflow-y-auto max-h-[300px]`}
`

const toProperCase = (str) => {
  if (!str) return '';
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  )
}

const columnHelper = createColumnHelper()

const sortDateFunc = (rowA, rowB) => {
  const dateA = rowA.original.transactionDate
  const dateB = rowB.original.transactionDate
  return dayjs(dateA).diff(dayjs(dateB))
}

const sortSharesFunc = (rowA, rowB, columnId) => {
  const a = Number(rowA.original.securitiesTransacted)
  const b = Number(rowB.original.securitiesTransacted)
  return a < b ? -1 : a === b ? 0 : 1
}

const TableHeader = ({ header, accessorKey, state }) => {
  return (
    <div
      className={`flex justify-center items-center gap-1 hover:text-textBase hover:cursor-pointer ${
        accessorKey === state.id ? 'text-[#5288ce]' : ''
      }`}
    >
      <span>{header}</span>
      <span>
        {state.id === accessorKey ? (
          state.desc ? (
            <TiArrowSortedDown />
          ) : (
            <TiArrowSortedUp />
          )
        ) : (
          <TiArrowUnsorted />
        )}
      </span>
    </div>
  )
}

const columns = [
  columnHelper.accessor('transactionDate', {
    cell: (data) => {
      const value = data.getValue()
      return (
        <span
          className={`flex justify-start items-center text-[#3b82f6] font-semibold`}
        >
          {dayjs(value).format('DD MMM YYYY')}
        </span>
      )
    },
    header: (data) => {
      const sortedStatus = data.table.getState().sorting.at(0)
      return (
        <TableHeader
          header={'Date'}
          accessorKey={'transactionDate'}
          state={sortedStatus}
        />
      )
    },
    sortingFn: sortDateFunc,
  }),
  columnHelper.accessor('reportingName', {
    cell: (data) => {
      const value = data.getValue()
      return (
        <span className={`flex justify-start items-center`}>
          {toProperCase(value)}
        </span>
      )
    },
    header: (data) => {
      const sortedStatus = data.table.getState().sorting.at(0)
      return (
        <TableHeader
          header={'Name'}
          accessorKey={'reportingName'}
          state={sortedStatus}
        />
      )
    },
  }),
  columnHelper.accessor('typeOfOwner', {
    cell: (data) => {
      const value = data.getValue()
      return (
        <span className={`flex justify-start items-center`}>
          {toProperCase(value)}
        </span>
      )
    },
    header: (data) => {
      const sortedStatus = data.table.getState().sorting.at(0)
      return (
        <TableHeader
          header={'Position'}
          accessorKey={'typeOfOwner'}
          state={sortedStatus}
        />
      )
    },
  }),
  columnHelper.accessor('securitiesTransacted', {
    cell: (data) => {
      const value = data.getValue()
      return (
        <span
          className={`${
            value > 0 ? 'text-textPositive' : 'text-textNegative'
          } flex justify-end items-center font-semibold`}
        >
          {numeral(value).format('0,0')}
        </span>
      )
    },
    header: (data) => {
      const sortedStatus = data.table.getState().sorting.at(0)
      return (
        <TableHeader
          header={'Shares'}
          accessorKey={'securitiesTransacted'}
          state={sortedStatus}
        />
      )
    },
    sortingFn: sortSharesFunc,
  }),
]

export const InsiderTrading = ({ symbol }) => {
  const { data: insiderTradingData, isLoading: isInsiderTradingLoading } =
    useFetchInsiderTradingQuery(symbol)

  const [sortingState, setSortingState] = useState([
    { id: 'transactionDate', desc: true },
  ])

  const formattedData = useMemo(
    () =>
      insiderTradingData?.data.map((d) => {
        const acquistionOrDisposition = d.acquistionOrDisposition
        let securitiesTransacted = d.securitiesTransacted
        if (acquistionOrDisposition === 'D')
          securitiesTransacted = 0 - securitiesTransacted
        return { ...d, securitiesTransacted }
      }) ?? [],
    [insiderTradingData]
  )
  const table = useReactTable({
    data: formattedData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: sortingState,
    },
    onSortingChange: (e) => {
      const state = e()
      setSortingState(state)
    },
    enableSortingRemoval: false,
  })

  return (
    <>
      {insiderTradingData?.data?.length > 0 && (
        <ContentContainer
          Header="Insiders"
          maxWidth="2500px"
          Content={
            <ListContainer>
              {!isInsiderTradingLoading && insiderTradingData?.data?.length && (
                <table className="w-full">
                  <thead className="text-xs">
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th
                            key={header.id}
                            className="pb-2 text-textMuted font-normal"
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row) => (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                          <td
                            key={cell.id}
                            className="text-left border-t-1 border-neutralBg text-xs text-textMuted p-2"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </ListContainer>
          }
        />
      )}
    </>
  )
}
