import { termsOfServiceText } from '../../data/text'
import { AccountSettingCardLayout } from './accountSettingCardLayout'
import { profileSettings } from '../../data/profile'

export const AccountTermsOfService = () => {
  const termsOfService = profileSettings.find(
    (setting) => setting.header === 'Terms of service'
  )
  return (
    <AccountSettingCardLayout
      icon={termsOfService.icon}
      pageName={termsOfService.header}
      text={termsOfServiceText}
      yScroll={false}
      maxHeight={'80vh'}
    />
  )
}
