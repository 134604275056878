import axios from "axios";

const url = process.env.REACT_APP_SERVER_URL;

var authorized_axios = axios.create({
});

authorized_axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
});

/* STOCK AND MACROECONOMIC DATA API REQUESTS */
// Fetches a single stock's data
export const fetchStockData = (ticker) => authorized_axios.get(`${url}/stocks/search/${ticker}`);
// Fetches macroeconomic data
export const fetchMacroeconomics = () => axios.get(`${url}/macroeconomics/get`);
// Fetches a single stock's price chart data
export const getStockHistoricalPrice = (query) => authorized_axios.get(`${url}/stocks/priceChart`,{params: query});

/* SCREENER API REQUESTS */
// Fetches the screener options
export const fetchScreenerOptions = () => axios.get(`${url}/screener/options`);
// Fetches the screener results
export const fetchScreenerResults = (query) => axios.get(`${url}/screener/search`, { params: query });
export const fetchStockScreener = (searchCriteria, tableMetrics) =>  authorized_axios.post(`${url}/screener/fetch`, {searchCriteria, tableMetrics})

/* SEARCH API REQUESTS */
// Fetches a list of stocks based on a partial ticker symbol or company name
export const filterStockData = (query) => axios.get(`${url}/search/${query}`);
// Fetches the full searchList
export const getFullSearchList = () => axios.get(`${url}/search/fullList/get`);

/* SEARCH SUGGESTIONS API REQUESTS */
// Fetch the user's recent searches
export const getRecentSearchList = (userId) => authorized_axios.get(`${url}/search/recent/get?userId=${userId}`);
// Adds a recent search to the user's recent searches
export const addToRecentSearchList = (ticker) => authorized_axios.post(`${url}/search/recent/add`, {ticker});

/* USER API REQUESTS */
// Logs in the user
export const loginUser = (user) => axios.post(`${url}/user/login`, user);
// Signs up the user
export const signupUser = (user) => axios.post(`${url}/user/signup`, user);
// Get user token
export const getToken = (email) => axios.post(`${url}/user/getToken`, email);
// Delete user
export const deleteUser = () => authorized_axios.post(`${url}/user/delete`);

// SUBSCRIPTION API REQUESTS
// Get user subscription details
export const getSubscriptionDetails = () => authorized_axios.post(`${url}/subscription/details/`);
// Set subscription type
export const setSubscriptionType = (stripeDetails) => authorized_axios.post(`${url}/subscription/type/set`, {stripeDetails});
// Update subscription type
export const updateSubscriptionType = (type, periodType) => authorized_axios.post(`${url}/subscription/type/update`, {type, periodType});
//Create Stripe Customer
export const createCustomer = () => authorized_axios.post(`${url}/subscription/customer/create`);
//Create Stripe Customer Card
export const createCustomerCard = (card) => authorized_axios.post(`${url}/subscription/customer/card/create`,{card});
//Get Stripe Customer Cards
export const getCustomerCards = () => authorized_axios.get(`${url}/subscription/customer/card`);
//Create Stripe Subscription
export const createSubscription = (subscriptionDetails) => authorized_axios.post(`${url}/subscription/create`,{subscriptionDetails});
//Update Stripe Subscription
export const updateSubscription = (priceId) => authorized_axios.post(`${url}/subscription/update`,{priceId});
//Cancel Stripe Subscription
export const cancelSubscription = () => authorized_axios.post(`${url}/subscription/cancel`);
//Cancel Stripe Subscription
export const cancelSubscriptionById = (subscriptionId) => authorized_axios.post(`${url}/subscription/cancel/${subscriptionId}`);
//Create Setup Intent
export const createSetupIntent = () => authorized_axios.post(`${url}/subscription/setupIntent/create`);
//Set Stripe Default Customer Card
export const setDefaultCustomerCard = (id) => authorized_axios.post(`${url}/subscription/customer/card/default`,{id});
//Get Subscription Prices
export const getSubscriptionPrices = () => authorized_axios.get(`${url}/subscription/price`);
// set trial before to true
export const setTrialBefore = () => authorized_axios.post(`${url}/subscription/setTrialBefore`);
export const resumeSubscription = () => authorized_axios.post(`${url}/subscription/resume`)


/* USER PREFERENCE API REQUESTS */
// Fetches the user's tableMetrics preferences
export const getTableMetrics = () => authorized_axios.get(`${url}/preferences/tableMetrics/get`);
// Updates the user's tableMetrics preferences
export const updateTableMetrics = ( tableMetrics) => authorized_axios.post(`${url}/preferences/tableMetrics/post`, { tableMetrics });
// Fetches the user's tableRows preferences
export const getTableRows = () => authorized_axios.get(`${url}/preferences/rowsPerTable/get`);
// Updates the user's tableRows preferences
export const updateTableRows = ( tableRows) => authorized_axios.post(`${url}/preferences/rowsPerTable/post`, { tableRows });
// Fetches the user's fundamentalPeriodType preferences
export const getFundamentalPeriodType = () => authorized_axios.get(`${url}/preferences/fundamentalPeriodType/get`);
// Updates the user's fundamentalPeriodType preferences
export const updateFundamentalPeriodType = ( fundamentalPeriodType) => authorized_axios.post(`${url}/preferences/fundamentalPeriodType/post`, { fundamentalPeriodType });
// Fetches the user's fundamentalTimeHorizon preferences
export const getFundamentalTimeHorizon = () => authorized_axios.get(`${url}/preferences/fundamentalTimeHorizon/get`);
// Updates the user's fundamentalTimeHorizon preferences
export const updateFundamentalTimeHorizon = ( fundamentalTimeHorizon) => authorized_axios.post(`${url}/preferences/fundamentalTimeHorizon/post`, { fundamentalTimeHorizon });
// Set User's preferred display theme
export const setDisplayTheme = (theme) => authorized_axios.post(`${url}/preferences/theme`, {theme});
// Get User's preferences
export const getUserPreferences = () => authorized_axios.get(`${url}/preferences`);
// Updates the user's fundamentalPeriodType preferences
export const updatePriceChartTimeframe = (timeframe) => authorized_axios.post(`${url}/preferences/priceChartTimeframe`, { timeframe });
// get User's graph preferences
export const getUserGraphSettings = () => authorized_axios.get(`${url}/preferences/graphSettings`);
// update user graph preferences
export const updateUserGraphSettings = ({option, setting}) => authorized_axios.post(`${url}/preferences/graphSettings`, {option, setting});
// get User's graph preferences
export const getUserGraphPreferences = () => authorized_axios.get(`${url}/preferences/graphPreferences`);
// update user graph preferences
export const updateUserGraphPreferences = ({statType, option, preference}) => authorized_axios.post(`${url}/preferences/graphPreferences`, {statType, option, preference});


/* WATCHLIST API REQUESTS */
// Fetches the user's watchlist array
export const fetchWatchList = () => authorized_axios.get(`${url}/watchList`);
// Fetches the user's watchlist data
export const getWatchList = () => authorized_axios.get(`${url}/watchList/getData`);
// Adds stock to user's watchlist
export const addToWatchList = (ticker) => authorized_axios.post(`${url}/watchList/addData`, {ticker});
// Removes stock to user's watchlist
export const removeFromWatchList = (ticker) => authorized_axios.post(`${url}/watchList/remove`, {ticker});

/**
 * Resend verification code || email
 * @param {string} user_id 
 * @returns 
 */
export const resendVerificationCode = (user_id) => axios.post(`${url}/auth/send-verification-email`, {user_id});