import React from 'react';
import { BoxAnimation } from '../'

export const LoadingFullScreen = ({ text }) => {

  return (
  <div className='flex flex-col justify-center items-center h-screen'> 
    <BoxAnimation/>
    {/* <p className='pl-6'>{text}</p> */}
  </div>
  )
}

export default LoadingFullScreen;