import initialState from "../state";

const userPreferenceReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'SET_THEME':
      return {
        ...state,
        theme: action.payload
      };
    case 'SET_LOGOUT':
      return {
        ...state,
        hasLoggedOut: action.payload
      };
    case 'SETTING_THEME':
      return {
        ...state,
        isSettingTheme: action.payload
      };
    case 'SET_TABLE_METRICS':
    return {
      ...state,
      tableMetrics: action.payload
    };
    case 'SET_TABLE_ROWS':
    return {
      ...state,
      tableRows: action.payload
    };
    case 'SET_FUNDAMENTAL_PERIOD_TYPE':
    return {
      ...state,
      fundamentalPeriodType: action.payload
    };
    case 'SET_PRICE_CHART_TIMEFRAME':
    return {
      ...state,
      priceChartTimeframe: action.payload
    };
    case 'SET_FUNDAMENTAL_TIME_HORIZON':
    return {
      ...state,
      fundamentalTimeHorizon: action.payload
    };
    case 'SET_USER_GRAPH_SETTINGS':
      return {
        ...state,
        userGraphSettings: action.payload
      }
    case 'SET_USER_GRAPH_PREFERENCES':
      return {
        ...state,
        userGraphPreferences: action.payload
      }
    default:
      return state;
  }
};

export default userPreferenceReducer;