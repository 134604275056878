// External Imports
import React, { useState, useEffect, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'
import { motion } from 'framer-motion'

// Internal Imports
import { SearchBar, ConfettiAnimation } from '../../components/index'
import { Overview } from './overviewSection'
import { Valuation } from './valuationSection'
import { News } from './newsSection'
import { EarningsCalls } from './earningsCalls'
import { Fundamentals } from './fundamentalSection'
import { InsiderTrading } from './insiderTrading'
import { getStockData, getIsValidStock } from '../../actions/stocks'
import { addToRecentSearchList, updateMainSearchMetadata } from '../../actions/searchLists'
import { useParams } from 'react-router'
import { PlanRestrictionModal } from '../../components/modals/planRestrictionModal'
import { Avatar } from '@mui/material'
import { useGetStockDataQuery } from '../../rtk'

//Styling
const Overlay = styled(motion.div)`
  ${tw`absolute top-[0px] bottom-[0px] left-[0px] right-[0px] z-[10] flex w-full justify-center select-none bg-neutralBg`};
  backdrop-filter: blur(8.5px);
`
const OverlayContainer = styled.div`${tw`absolute top-[54px] flex flex-col items-center `};`
const SearchContainer = styled.div`${tw`relative w-full max-w-[2500px] z-[12] flex justify-center space-x-6`};`
// const PopularSearchContainer = styled.div`${tw`fixed top-[165px] z-[10] `}`;
const PopularSearchContainer = styled.div`${tw`fixed top-[165px] lg:top-[90px] z-[10] `}`
const HeaderText = styled.h1`${tw`text-xl font-semibold flex justify-center my-3 `}`
const CompanyCardContainer = styled.div`${tw`max-w-[600px] flex flex-wrap items-center justify-center px-4`};`
const CompanyCard = styled.div`
  ${tw`w-[125px] h-[45px] flex items-center justify-center rounded m-2 p-2 bg-primaryBg border-1 border-textMuted text-textMuted transition-transform duration-300 ease-in-out`}
  ${tw`hover:cursor-pointer hover:shadow-md hover:text-textBase hover:border-blue-500`}
  &:hover {transform: translateY(-2px)}
`
const Container = styled.div`${tw`w-full flex flex-col items-center p-6 gap-6`};`

// Page for analysing the valuation and fundamentals of a stock
export const StockAnalysis = () => {
  const [showConfetti, setShowConfetti] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const dispatch = useDispatch()
  const { recentSearchList, compareSymbol, latestStock, isFetchingRecentSearchList } = useSelector((state) => state.searchReducer)
  const [openOverlay, setOpenOverlay] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const { stock } = useParams()
  const { hasActivePremiumSubscription, isGettingSubscriptionDetails } = useSelector((state) => state.watchListReducer)
  const [showPlanRestriction, setShowPlanRestriction] = useState(false)
  const { searchedStockData } = useSelector((state) => state.stockReducer)
  const { priceChartTimeframe, fundamentalTimeHorizon, fundamentalPeriodType } = useSelector((state) => state.userPreferenceReducer)

  const { data: ticker, isFetching, isError } = useGetStockDataQuery(recentSearchList[0] ?? 'AAPL')

  // Show confetti animation on 1st signup
  // useEffect(() => {
  //   if (userData.isFirstSignUp) {
  //     setShowConfetti(true);
  //   }
  // }, [userData.isFirstSignUp])

  useEffect(() => {
    const companyData = {
      logo: searchedStockData?.profile?.logo,
      companyName: searchedStockData?.profile?.companyName,
      exchangeShortName: searchedStockData?.profile?.exchangeShortName,
    };

    dispatch(updateMainSearchMetadata(searchedStockData?.symbol, companyData));
  }, [searchedStockData])

  // Onload, load most recent stock or stock in url
  useEffect(() => {
    if (stock && !isFetchingRecentSearchList && !isGettingSubscriptionDetails) {
      let stockToSearch = stock.toUpperCase()
      setIsValidating(true)
      dispatch(getIsValidStock(stockToSearch)).then((res) => {
        setIsValidating(false)
        if (!res) {
          window.history.replaceState(null, '', `/stockanalysis/`)
          return
        }
        if (hasActivePremiumSubscription || recentSearchList.length < 5) {
          dispatch(addToRecentSearchList(stockToSearch))
          dispatch(getStockData(stockToSearch))
          return
        } else {
          if (recentSearchList.slice(0, 5).includes(stockToSearch)) {
            dispatch(getStockData(stockToSearch))
            return
          }
          setShowPlanRestriction(true)
          return
        }
      })
    }
  }, [stock, isFetchingRecentSearchList, isGettingSubscriptionDetails])

  // Get stock data on ticker change
  useEffect(() => {
    if (!isValidating && !isFetchingRecentSearchList && !stock)
      dispatch(getStockData(latestStock))
  }, [recentSearchList, isFetchingRecentSearchList])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // Hide overflow when overlay is open
  useEffect(() => {
    document.body.style.overflow = openOverlay ? 'hidden' : 'auto'
  }, [openOverlay])

  return (
    <Container>
      {showConfetti && <ConfettiAnimation />}
      <SearchContainer>
        <SearchBar
          onSearchClick={() => setOpenOverlay(true)}
          close={() => setOpenOverlay(false)}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
      </SearchContainer>
      <SearchSection
        searchQuery={searchQuery}
        open={openOverlay}
        close={() => setOpenOverlay(false)}
      />
      <Overview
        symbol={recentSearchList[0] ?? 'AAPL'}
        ticker={ticker}
        isError={isError}
        isFetching={isFetching}
      />
      <Valuation
        symbol={recentSearchList[0] ?? 'AAPL'}
        compareSymbol={compareSymbol.symbol ?? null}
        priceChartTimeframe={priceChartTimeframe}
        ticker={ticker}
        isTickerError={isError}
        isTickerFetching={isFetching}
      />
      <Fundamentals
        symbol={recentSearchList[0] ?? 'AAPL'}
        compareSymbol={compareSymbol.symbol ?? null}
        fundamentalPeriodType={fundamentalPeriodType}
        fundamentalTimeHorizon={fundamentalTimeHorizon}
      />
      <News symbol={recentSearchList[0] ?? 'AAPL'} />
      <EarningsCalls symbol={recentSearchList[0] ?? 'AAPL'} />
      <InsiderTrading 
        symbol={recentSearchList[0] ?? 'AAPL'}
      />
      <PlanRestrictionModal
        open={showPlanRestriction}
        close={() => {
          setShowPlanRestriction()
        }}
      />
    </Container>
  )
}

const setImgErrorReducer = (state, action) => {
  if (action.type === 'SET_ERROR') {
    const newState = [...state]
    newState[action.payload] = true
    return newState
  } else if (action.type === 'SET_ARRAY') {
    return Array(action.payload).fill(false)
  }
  return state
}

// Overlay for searching for a stock
const SearchSection = ({ open, close, searchQuery }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { recentSearchList, fullSearchList } = useSelector((state) => state.searchReducer)
  const { hasActivePremiumSubscription } = useSelector((state) => state.watchListReducer)
  const [preloadedImages, setPreloadedImages] = useState({})
  const searchLimit = hasActivePremiumSubscription ? 20 : 5
  const [loadImgErr, reducerDispatch] = useReducer(setImgErrorReducer, null, () => Array(recentSearchList.length).fill(false))

  // Find logo by symbol
  const findLogoBySymbol = (symbol) => {
    const foundObject = fullSearchList.find((obj) => obj.symbol === symbol)
    return foundObject?.logo ?? null
  }

  // Preload company logos on page load
  useEffect(() => {
    const preloadImages = () => {
      const images = {}
      const limitedRecentSearchList = recentSearchList.slice(0, searchLimit) // Only preload the 20 most recent searched stocks
      reducerDispatch({
        type: 'SET_ARRAY',
        payload: limitedRecentSearchList.length,
      })
      limitedRecentSearchList.forEach((ticker) => {
        const logo = findLogoBySymbol(ticker)
        if (logo) {
          const image = new Image()
          image.src = logo
          images[ticker] = image
        }
      })
      setPreloadedImages(images)
    }

    preloadImages()
  }, [recentSearchList, fullSearchList])

  const handleCompanySelect = (company) => {
    dispatch(addToRecentSearchList(company))
    navigate(`/stockanalysis/${company}`)
    close(false)
  };

  if (!open) return null

  return (
    <Overlay onClick={() => close(false)}>
      <OverlayContainer onClick={(e) => e.stopPropagation()}>
        <PopularSearchContainer>
          {!searchQuery && recentSearchList.length > 0 && (
            <>
              <div className="flex flex-row justify-center space-x-10">
                <HeaderText className="text-textBase">Recent</HeaderText>
                {/* <HeaderText className='text-textMuted'>Popular</HeaderText> */}
              </div>
              <CompanyCardContainer>
                {recentSearchList.slice(0, searchLimit).map((ticker, idx) => (
                  <CompanyCard
                    key={ticker}
                    onClick={() => handleCompanySelect(ticker)}
                  >
                    {preloadedImages[ticker] && !loadImgErr[idx] ? (
                      <img
                        src={preloadedImages[ticker].src}
                        alt=""
                        width="25px"
                        style={{
                          filter: 'drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.4))',
                          borderRadius: '3px',
                        }}
                        type="image/png"
                        onError={() =>
                          reducerDispatch({ type: 'SET_ERROR', payload: idx })
                        }
                      />
                    ) : (
                      <Avatar
                        sx={{
                          bgcolor: '#5f8799',
                          color: '#FFFFFF',
                          width: '32px',
                          height: '32px',
                        }}
                      >
                        {ticker[0]?.toLocaleUpperCase()}
                      </Avatar>
                    )}

                    <h1 className="text-xs pl-3">{ticker}</h1>
                  </CompanyCard>
                ))}
              </CompanyCardContainer>
            </>
          )}
        </PopularSearchContainer>
      </OverlayContainer>
    </Overlay>
  )
}
