// External imports
import React, { useState, useEffect } from 'react'
import styled, { useTheme } from 'styled-components'
import tw from 'twin.macro'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive';
import dayjs from 'dayjs'
import _ from 'lodash'
import { BsPiggyBank, BsPlusSquare, BsCreditCard2Front } from 'react-icons/bs'

// Internal imports
import {
  Graph,
  ContentContainer,
  PriceGraph,
  DataTable,
  CashTransactionModal,
  BuyModal,
  IconButtons,
  BoxAnimation,
  AnimatingNumber
} from '../../components/index'
import { metricOptions } from '../../data/metricOptions'
import { useGetPriceChartDataQuery } from '../../rtk'
import { useFetchWatchlistDataQuery, useFetchWatchlistQuery, useGetPortfolioDataQuery } from '../../rtk'
import { useGetFundamentalQuery } from '../../rtk'
import { formatValue } from '../../utils'
import { HoldingsTable } from '../portfolio/holdingsTable'

// Styling
const Container = styled.div`${tw`w-full flex flex-col items-center p-6 gap-6`};`
const ChartDiv = styled.div`${tw`w-full h-[250px] flex flex-col text-sm`};`
const borderColourSelector = (data) => {
  if (data.length >= 2 && data[0].close <= data[data.length - 1].close)
    return '#8b2126'
  return '#218B82'
}

const PortfolioValueGraph = () => {
  const palette = useTheme()

  const priceToggle = [
    {
      name: '1D',
      labels: 'HH:mm',
      interval: '1min',
      useLiveData: true,
      // state: price1dData,
    },
    {
      name: '5D',
      labels: 'DD MMM',
      interval: '15min',
      useLiveData: true,
      // state: price5dData,
    },
    {
      name: '1M',
      labels: 'DD MMM',
      interval: '30min',
      useLiveData: true,
      // state: price1mData,
    },
    {
      name: '6M',
      labels: 'MMM YY',
      interval: '1day',
      useLiveData: true,
      // state: price6mData,
    },
    // {name:"YTD", labels:"MMM", interval:null, useLiveData: true, state: price1dData},
    {
      name: '1Y',
      labels: 'MMM YY',
      interval: '1day',
      useLiveData: true,
      // state: price1yData,
    },
    {
      name: '5Y',
      labels: 'YYYY',
      interval: '1day',
      useLiveData: true,
      // state: price5yData,
    },
    {
      name: '10Y',
      labels: 'YYYY',
      interval: '1day',
      useLiveData: true,
      // state: price5yData,
    },
    {
      name: 'MAX',
      labels: 'YYYY',
      interval: '1day',
      useLiveData: true,
      // state: priceMaxData,
    },
  ]

  const getProperDay = (est) => {
    if (est.day() === 0) 
        return est.subtract(2, 'days')
      else if (est.day() === 1)
        return est.subtract(3, 'days')
      else 
        return est.subtract(1, 'days')
    } 

  const getBackDate = (timeframe) => {
    const localTime = dayjs()
    const est = localTime.tz('America/New_York')
    switch (timeframe) {
      case '1D':
        return getProperDay(est)
      case '5D':
        return est.subtract(5, 'days')
      case '1M':
        return est.subtract(1, 'months')
      case '6M':
        return est.subtract(6, 'months')
      case 'YTD':
        return est.startOf('year')
      case '1Y':
        return est.subtract(1, 'years')
      case '5Y':
        return est.subtract(5, 'years')
      case '10Y':
        return est.subtract(10, 'years')
      default:
        return dayjs(new Date(1950, 1, 1))
    }
  }

  const { data, isFetching } = useGetPriceChartDataQuery({
    symbol: 'AAPL',
    interval: '1day',
    fromDate: getBackDate('6M')?.format('YYYY-MM-DD'),
    toDate: dayjs().format('YYYY-MM-DD'),
    timeframe: '6M',
  })

  const colorVariants = (value) => {
    if (value > 0) {
      return { backgroundColor: '#6ec2b834', color: '#218B82' }
    } else if (value < 0) {
      return { backgroundColor: '#da707434', color: '#da7073' }
    } else {
      return {
        backgroundColor: '#80808034',
        color: 'var(--textBase)',
        fontWeight: 400,
      }
    }
  }

  return (
    <div className='w-full flex flex-col space-y-2 '>
      <div className='w-full flex flex-row space-x-2 items-center justify-start'>
        <div className='flex flex-row space-x-2 items-center justify-center'>
          <span className='text-lg font-semibold'>$54,000.28</span>
        </div>
        <div className='flex flex-row space-x-2'>
          <div
            className="font-semibold rounded px-3 transform text-xs flex items-center justify-center"
            style={colorVariants(545.36)}
          >
            $545.36
          </div>
        </div>
        <div className='flex flex-row space-x-2'>
          <div
            className="font-semibold rounded px-3 transform text-xs flex items-center justify-center"
            style={colorVariants(9329.54)}
          >
            $9,329.54
          </div>
        </div>           
      </div>
      <ChartDiv
        style={{ width: '1000', height: '240' }}
        className="cursor-auto"
      >
        {data?.chartData && !isFetching ? (
          <PriceGraph
            priceChartTimeframe={"6M"}
            chartData={{
              priceData: data.chartData.map((data) => data.close).reverse(),
              volumeData: data.chartData.map((data) => data.volume).reverse(),
              labels: data.chartData.map((data) => data.date).reverse(),
              candlestickData: data.chartData.map((data) => ({
                open: data.open,
                high: data.high,
                low: data.low,
                close: data.close,
              })).reverse(),
            }}
            chartColour={borderColourSelector(data.chartData)}
            chartFillColour={palette.colors.priceChartFill}
            labelDisplay={
              priceToggle.find((price) => price.name === "6M").labels
            }
          />
        ) : null}
        {isFetching && (
          <div className="flex justify-center items-center h-full">
            <BoxAnimation/>
          </div>
        )}
      </ChartDiv>
    </div>
  )
}

export const DividendsGraph = ({symbol, fundamentalPeriodType}) => {

  const {
    data: fundamentalData,
    isError,
    isFetching,
  } = useGetFundamentalQuery({ symbol, period: fundamentalPeriodType })

  const getChartDataAndLabels = (key, innerKey) => {
    if (isFetching || isError) return { data: [], labels: [] }
    const data = []
    const labels = []
    let values = undefined
    values = fundamentalData[key]
    for (const val of values) {
      data.push(val[innerKey])
      labels.push(val.date)
    }
    return {
      data: data.slice(0, 20).reverse(),
      labels: labels.slice(0, 20).reverse(),
    }
  }
  
  const graphContent = [
    {
      statType: 'Dividends',
      chartData: [
        {
          name: 'Dividends',
          ...getChartDataAndLabels('historicDailyDividend', 'dividend'),
        },
      ],
      chartType: 'bar',
      chartColours: ['#aebdce'],
      isStacked: false,
      valueFormat: 'currency',
      tickRounding: 2,
      dataRounding: 2,
    }
  ]

  return (
    <div className='w-full h-[250px]'>
      <Graph
          statType={graphContent[0].statType}
          chartData={graphContent[0].chartData}
          chartType={graphContent[0].chartType}
          chartColours={graphContent[0].chartColours}
          isStacked={graphContent[0].isStacked}
          valueFormat={graphContent[0].valueFormat}
          displayLegend={graphContent[0].displayLegend}
          tickRounding={graphContent[0].tickRounding}
          dataRounding={graphContent[0].dataRounding}
        />
    </div>
    
  )
}

export const Portfolio = () => {
  const [display, setDisplay] = useState('portfolio');
  const [openCashTransactionModal, setOpenCashTransactionModal] = useState(false)

  const { data: portfolioData, error, isLoading, refetch } = useGetPortfolioDataQuery();

  // Refetch the portfolio data when a cash transaction or stock trade is made
  useEffect(() => {
    const handleUpdate = () => {
      refetch();
    };

    // Add event listeners for custom events that signal a data update
    window.addEventListener('cashTransaction', handleUpdate);
    window.addEventListener('stockTrade', handleUpdate);

    return () => {
      window.removeEventListener('cashTransaction', handleUpdate);
      window.removeEventListener('stockTrade', handleUpdate);
    };
  }, [refetch]);

  if (isLoading) return <BoxAnimation/>;
  if (error) return <div>Error: {error.message}</div>;

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  }
  
  return (
    <Container>
      <ContentContainer
        Header={'Summary'}
        Icons={
          <>
            <IconButtons
              action={null}
              icon={<BsPlusSquare  />}
              text={'Watch List'}
            />
            <IconButtons
              action={() => {
                setOpenCashTransactionModal(true)
              }}
              icon={<BsPiggyBank/>}
              text={'Deposit'}
            />
            <CashTransactionModal
              open={openCashTransactionModal}
              close={() => {
                setOpenCashTransactionModal()
              }}
              icon={<BsCreditCard2Front/>}
              header="Deposit"
              type="Deposit"
            />
            {/* Withdrawal option */}
          </>
        }
        maxWidth={'2500px'}
        Content={
          <div className='w-full'>
            {/* {formatCurrency(portfolioData?.cashBalance?.value)} */}
            <div className='flex flex-row items-start w-[fit-content] space-x-2'> <span>Cash Balance: </span>  <AnimatingNumber value={portfolioData?.cashBalance?.value} /> </div>
            {display === 'portfolio' && <PortfolioValueGraph/>}
            {display === 'dividends' && 
              <DividendsGraph symbol='VICI' fundamentalPeriodType='Annual'/>
            }
          </div>
        }
      />
      <HoldingsTable data={portfolioData}/>
      <div className='text-textBase'>
        {/* {portfolioData?.stockHoldings.map((holding, index) => (
          <div key={index}>
            <p>Ticker: {holding.ticker}</p>
            <p>Cost Basis: {holding.costBasis}</p>
            <p>Number of Shares: {holding.shares}</p>
          </div>
        ))} */}
      </div>
    </Container>
  )
}