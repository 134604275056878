import React, { useEffect, useRef, useState } from 'react'
import { ContentContainer } from '../../components'
import {
  Container as ProfileContainer,
  PathHeader as ProfileHeader,
} from './userProfile'
import { MdOutlineClose } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { IconButtons } from '../../components'
import {
  ParagraphDiv as FooterParagraphDiv,
  SubHeader as FooterSubHeader,
} from '../footer/footerPages'
import { createElement } from 'react'

export const AccountSettingCardLayout = ({icon, pageName, text, yScroll=false, maxHeight=null, content=null}) => {
  const ref = useRef(null)
  const [isYScrollable, setIsYScrollable] = useState(false)

  useEffect(() => {
    const handler = () => {
      if (ref.current && ref.current.scrollHeight > ref.current.clientHeight) {
        setIsYScrollable(true)
      } else {
        setIsYScrollable(false)
      }
    }

    handler();

    window.addEventListener('resize', handler)
    return () => window.removeEventListener
  }, [ref.current])

  return (
    <ProfileContainer>
      <ContentContainer
        Header={
          <ProfileHeader
            icon={createElement(icon)}
            pageName={pageName}
          />
        }
        Icons={
          <Link to="/account">
            <IconButtons icon={<MdOutlineClose />} />
          </Link>
        }
        maxWidth={'800px'}
        yScroll={yScroll}
        maxHeight={maxHeight}
        Content={ content ? content :
          <div 
            className={`space-y-2 overflow-y-auto ${
            !isYScrollable ? 'pr-0' : 'pr-2'
          }`}
          ref={ref}
          >
            {text.map((section, index) => (
              <FooterParagraphDiv key={index}>
                <FooterSubHeader>{section.header}:</FooterSubHeader>
                {section.header === 'Contact Us' ? (
                  <span className='text-textMuted'>
                    {section.body.slice(0, section.body.indexOf('support'))}
                    <Link to="/account/support" className="underline hover:text-blue-500 text-textBase font-semibold">
                      support
                    </Link>
                    .
                  </span>
                ) : (
                  <p className='text-textMuted'>{section.body}</p>
                )}
              </FooterParagraphDiv>
            ))}
          </div>
        }
      />
    </ProfileContainer>
  )
}
