// External imports
import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

// Styling
const Container = styled.div`${tw`w-full bg-primaryBg text-textBase flex flex-col justify-center items-center p-2 rounded shadow-md relative border-1 border-textBase`};`
const ContainerTopRow = styled.div`${tw`w-full flex flex-row items-center border-b border-neutral justify-between pb-2 mb-2 text-xl font-semibold`};`
const ContentDiv = styled.div`${tw`w-full flex flex-col items-center`};`
const HorizontalDiv = styled.div`${tw`flex flex-row items-center`};`

export const ContentContainer = ({ Header, Icons, Toggle, Content, maxWidth, maxHeight = null, yScroll = false, handleScroll = null, footer = null }) => {
  const hasTopRowContent = Header || Toggle || Icons

  return (
    <>
      <div className="relative w-full" style={{ maxWidth: maxWidth }}>
        <Container style={{ maxWidth: maxWidth }}>
          {hasTopRowContent && (
            <ContainerTopRow>
              <HorizontalDiv className="md:w-full sm:w-fit justify-start">
                {Header}
              </HorizontalDiv>
              {Toggle && (
                <HorizontalDiv className="md:w-full sm:w-fit justify-center">
                  {Toggle}
                </HorizontalDiv>
              )}
              <HorizontalDiv className="md:w-full sm:w-fit justify-end space-x-2">
                {Icons}
              </HorizontalDiv>
            </ContainerTopRow>
          )}
          <ContentDiv style={{ maxHeight, overflowY: yScroll ? 'scroll' : '' }} className={`${ yScroll ? '' : 'justify-center'}`} onScroll={handleScroll}>
            {Content}
          </ContentDiv>
        </Container>
      </div>
      {footer}
    </>
  )
}
