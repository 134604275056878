// External imports
import React from 'react';
import styled from "styled-components";
import tw from "twin.macro";
import { NumericFormat } from "react-number-format";

// Styling
const StyledNumericFormat = styled(NumericFormat)`
  ${tw`outline-none bg-neutralBg rounded text-right text-textMuted hover:text-textBase px-1 w-full min-w-[100px] h-[32px]`};
  &::placeholder {
    transition: all 500ms ease-in-out;
    color: ${({ theme }) => theme.colors.placeholderText};
  }
  &:hover::placeholder {
    color: ${({ theme }) => theme.colors.placeholderTextHover};
  }
`

export const StyledInput = ({ placeholder, value, onChange, decimalScale }) => {
  return (
    <StyledNumericFormat
      thousandSeparator={true}
      decimalScale={decimalScale}
      placeholder={placeholder}
      fixedDecimalScale={true}
      value={value}
      onChange={onChange}
    />
  );
};