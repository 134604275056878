import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { stockAnalysisAPi } from '../rtk';
import stockReducer from '../reducers/stockReducer';
import watchListReducer from '../reducers/userReducer';
import userPreferenceReducer from '../reducers/userPreferenceReducer';
import screenerReducer from '../reducers/screenerReducer';
import searchReducer from '../reducers/stockSearchReducer';


const store = configureStore({
  reducer: {
    stockReducer, searchReducer, watchListReducer, userPreferenceReducer, screenerReducer,
    [stockAnalysisAPi.reducerPath]: stockAnalysisAPi.reducer
  },
  middleware: [thunk, stockAnalysisAPi.middleware]
});

export default store;