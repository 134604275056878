// External imports
import React, { useState, useEffect } from 'react';

// Internal imports
import { Modal, StyledInput, FormButton } from '..';
import { useAddCashTransactionMutation, useAddTickerTransactionMutation } from '../../rtk';

export const BuyModal = ({ open, close, icon, header, ticker, type }) => {

  const [inputSharesValue, setInputSharesValue] = useState('')
  const [displayedSharesValue, setDisplayedSharesValue] = useState('')

  const [inputAmountValue, setInputAmountValue] = useState('')
  const [displayedAmountValue, setDisplayedAmountValue] = useState('')

  const [transactionType, setTransactionType] = useState('Buy');

  const [addTicker] = useAddTickerTransactionMutation();

  const bid = 200.41;
  const ask = 200.10;

  const price = transactionType === 'Buy' ? bid : ask;
  
  useEffect(() => {
    setTransactionType(type);
  }, [type]);
  

  useEffect(() => {
    if (inputSharesValue) {
      const amount = parseFloat(inputSharesValue) * price;
      setDisplayedAmountValue(amount.toFixed(2));
    } else {
      setDisplayedAmountValue('');
    }
  }, [inputSharesValue, price]);

  useEffect(() => {
    if (inputAmountValue) {
      const shares = parseFloat(inputAmountValue) / price;
      setDisplayedSharesValue(shares.toFixed(4));
    } else {
      setDisplayedSharesValue('');
    }
  }, [inputAmountValue, price]);

  const handleTransaction = async () => {
    try {
      const transaction = {
        ticker: ticker,
        date: new Date().toISOString(),
        shares: parseFloat(inputSharesValue) || parseFloat(displayedSharesValue),
        amount: parseFloat(inputAmountValue) || parseFloat(displayedAmountValue),
        transactionType: transactionType.toLowerCase(),
      };
      console.log(transaction)
      addTicker(transaction).catch((err) => console.error(err))

      // Clear inputs after successful transaction
      setInputSharesValue('');
      setDisplayedSharesValue('');
      setInputAmountValue('');
      setDisplayedAmountValue('');
      close();
    } catch (error) {
      console.error("Error handling transaction:", error);
    }
  };

  return (
    <Modal 
      open={open} 
      closeModal={close}  
      headerContent={<span className='flex flex-row items-center'>{icon}<h1 className='ml-2'>{header}</h1></span>}
      maxWidth='300px'
      height='fit-content'
      content={
        <div className='w-full flex flex-col items-center space-y-2 pt-2'>
          <div className='w-full flex flex-row items-start text-lg font-semibold'>{ticker}</div>
          <div className='w-full flex flex-row items-start space-x-2'>
            <span className='text-textPositive font-semibold'>Bid: ${bid}</span> <span> / </span><span className='text-textNegative font-semibold'>Ask: ${ask}</span>
          </div>
          <div className='w-full p-2 flex flex-col space-y-2 border-1 border-neutral rounded'>
            <StyledInput
              decimalScale={4}
              placeholder={"Shares (#)"}
              value={displayedSharesValue}
              onChange={(e) => {
                const rawValue = e.target.value.replace(/,/g, '');
                setInputSharesValue(rawValue);
                setDisplayedSharesValue(e.target.value);
                setInputAmountValue(''); // Clear amount input
              }}
            />
            <StyledInput
              decimalScale={2}
              placeholder={"Amount ($)"}
              value={displayedAmountValue}
              onChange={(e) => {
                const rawValue = e.target.value.replace(/,/g, '');
                setInputAmountValue(rawValue);
                setDisplayedAmountValue(e.target.value);
                setInputSharesValue(''); // Clear shares input
              }}
            />
          </div>

          {/* Date */}
          <div className='w-full flex flex-row space-x-2'>
            {transactionType === 'Buy' && 
              <FormButton
                // isDisabledisabled={isLoading || !stripe || !elements}
                isSecondary={true}
                text={"Buy"}
                onClick={() => { handleTransaction() }}
                type="button"
              />
            }
            {transactionType === 'Sell' && 
              <FormButton
                // isDisabledisabled={isLoading || !stripe || !elements}
                isNegative={true}
                text={"Sell"}
                onClick={() => { handleTransaction() }}
                type="button"
              />
            }
          </div>
        </div>
      } 
    />
  )
}

export const CashTransactionModal = ({ open, close, icon, header, type }) => {
  const [inputAmountValue, setInputAmountValue] = useState('')
  const [displayedAmountValue, setDisplayedAmountValue] = useState('')
  const [transactionType, setTransactionType] = useState('Deposit');
  const [addCashTransaction] = useAddCashTransactionMutation();
  
  useEffect(() => {
    setTransactionType(type);
  }, [type]);

  const handleTransaction = async () => {
    try {
      const transaction = {
        date: new Date().toISOString(),
        amount: parseFloat(inputAmountValue),
        transactionType: transactionType.toLowerCase(),
      };
      console.log(transaction)
      addCashTransaction(transaction).catch((err) => console.error(err))

      // Clear inputs after successful transaction
      setInputAmountValue('');
      setDisplayedAmountValue('');
      close();
    } catch (error) {
      console.error("Error handling transaction:", error);
    }
  };

  return (
    <Modal 
      open={open} 
      closeModal={close}  
      headerContent={<span className='flex flex-row items-center'>{icon}<h1 className='ml-2'>{header}</h1></span>}
      maxWidth='300px'
      height='fit-content'
      content={
        <div className='w-full flex flex-col items-center space-y-2 pt-2'>
          <div className='w-full p-2 flex flex-col space-y-2 border-1 border-neutral rounded'>
            <StyledInput
              decimalScale={2}
              placeholder={"Amount ($)"}
              value={displayedAmountValue}
              onChange={(e) => {
                const rawValue = e.target.value.replace(/,/g, '');
                setInputAmountValue(rawValue);
                setDisplayedAmountValue(e.target.value);
              }}
            />
          </div>

          {/* Date */}
          <div className='w-full flex flex-row space-x-2'>
            {transactionType === 'Deposit' && 
              <FormButton
                isSecondary={true}
                text={"Deposit"}
                onClick={() => { handleTransaction() }}
                type="button"
              />
            }
            {transactionType === 'Withdraw' && 
              <FormButton
                isNegative={true}
                text={"Withdraw"}
                onClick={() => { handleTransaction() }}
                type="button"
              />
            }
          </div>
        </div>
      } 
    />
  )
}