import initialState from "../state";

const stockReducer = (state = initialState, action) => {
  switch (action.type) {
    // case 'FILTER_MULTIPLE_STOCKS':
    //   return {
    //     ...state,
    //     watchListData: action.payload
    //   };
    case 'FETCH_ALL_STOCKS':
      return {
        ...state,
        searchedStockData: action.payload
      };
    case 'SET_1D_DATA':
      return {
        ...state,
        price1dData: action.payload
      };
    case 'SET_5D_DATA':
      return {
        ...state,
        price5dData: action.payload
      };
    case 'SET_1M_DATA':
      return {
        ...state,
        price1mData: action.payload
      };
    case 'SET_6M_DATA':
      return {
        ...state,
        price6mData: action.payload
      };
    case 'SET_1Y_DATA':
      return {
        ...state,
        price1yData: action.payload
      };
    case 'SET_5Y_DATA':
      return {
        ...state,
        price5yData: action.payload
      };
    case 'SET_10Y_DATA':
      return {
        ...state,
        price10yData: action.payload
      };
    case 'SET_MAX_DATA':
      return {
        ...state,
        priceMaxData: action.payload
      };
    case 'SET_HAS_CHART_DATA':
      return {
        ...state,
        hasChartData: action.payload
      };
    case 'SET_PRICE_CHART_SYMBOL':
      return {
        ...state,
        priceChartSymbol: action.payload
      };
      
    // case 'FILTER_STOCKS':  
    //   return {
    //     ...state,
    //     searchData: action.payload
    //   };
    default:
      return state;
  }
}

export default stockReducer;