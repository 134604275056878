// External imports
import { ContentContainer } from "../../components";
import styled from 'styled-components';
import tw from "twin.macro";
import { useAuth0 } from "@auth0/auth0-react";
import { termsOfServiceText } from "../../data/text";
import { Link } from "react-router-dom";

// Internal imports
import { FormButton } from '../../components';

// Styling
const Section = styled.section`${tw`w-full flex flex-col items-center p-8`};`;
const ParagraphDiv = styled.div` ${tw`w-full flex flex-col pr-2 justify-start text-justify align-top text-xs`}; `;
const SubHeader = styled.h1` ${tw`w-[fit] flex flex-row font-semibold text-base`}; `;

const SignupTerms = () => {
        const { loginWithRedirect} = useAuth0();
      
         return (
          <Section className='mt-5'>
            <ContentContainer 
              Header={'Terms of Service'}
              hasExpand={false}
              maxWidth={'800px'}
              maxHeight={'60vh'}
              Content={
                <div className='space-y-2 overflow-y-auto'>
                  {termsOfServiceText.map((section, index) => (
                    <ParagraphDiv key={index}>
                      <SubHeader>{section.header}:</SubHeader>
                      <span className='text-textMuted'>
                        {section.header === "Contact Us" ? (
                          <span>
                            {section.body.slice(0, section.body.indexOf("support"))}
                            <Link to="/support" className="underline">support</Link>.
                          </span>
                        ) : (
                          section.body
                        )}
                      </span>
                    </ParagraphDiv>
                  ))}
                </div>
              }
              footer={
                <>
                  <div className='max-w-[800px] w-full flex justify-between mt-2'>
                    <div className="w-full flex justify-start">
                      <Link to={'/'}>
                        <FormButton isNegative={true} text={'Decline'} width={'100px'}/>
                      </Link>
                    </div>
                    <div className="w-full flex items-end justify-end content-end">
                      <FormButton
                        isSecondary={true} 
                        text={'Accept'} 
                        width={'100px'} 
                        onClick={() => loginWithRedirect({authorizationParams: { screen_hint: "signup", redirect_uri: `${process.env.REACT_APP_URL}/afterSignup`}})}
                      />
                    </div>
                  </div>
                </>
              }
            />
          </Section>
        )
}

export default SignupTerms;