import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

const Switch = styled.label`${tw`relative inline-block w-[40px] h-[22px] bg-none`}; `;
const SwitchInput = styled.input`${tw`hidden`};`;

const Slider = styled.span` ${tw`absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-textMuted transition-transform duration-200 rounded-full`}; 
  &:before {
    ${tw`absolute content-[''] w-[16px] h-[16px] left-[4px] bottom-[3px] bg-[#ffffff] rounded-full transition-transform duration-200`};
  };
  ${SwitchInput}:checked ~ &::before {transform: translateX(16px);};
`;

export const ToggleButton = ({ id, value, checked, onChange, bgColor=null }) => (
  <Switch htmlFor={id}>
    <SwitchInput type="checkbox" id={id} value={value} checked={checked} onChange={onChange} className="peer"/>
    <Slider className={`${bgColor ? `peer-checked:bg-[${bgColor}]` : 'peer-checked:bg-[#6ec2b8]'}`} />{/* #5aa9e6 */}
  </Switch>
);