// External imports
import React from 'react';
import styled  from "styled-components";
import tw from "twin.macro";
import { IoClose } from "react-icons/io5";

// Styling
const FilterSelection = styled.div` ${tw`max-h-[35px] w-[fit-content] text-xs text-textPositive rounded-md flex flex-row items-center justify-start hover:shadow-md text-[#ffffff] bg-primaryBg space-x-1`}; `;
const CloseIcon = styled.span` ${tw`h-5 w-5 cursor-pointer flex items-center justify-center hover:text-sm`}; `;

export const SelectedItemCard = ({ selection, closeAction }) => (
  <FilterSelection>
  <span className=''>{selection}</span>
  <div className='h-[20px] w-[2px] bg-neutralBg'/>
    <CloseIcon>
      <IoClose onClick={closeAction}/>
    </CloseIcon>
  </FilterSelection>
)