export const getWeekNumber = (date) => {
  const onejan = new Date(date.getFullYear(), 0, 1);
  const week = Math.ceil(((date - onejan) / 86400000 + onejan.getDay() + 1) / 7);
  return week;
}

export const getDateAfterOrMostRecent = (data) => {
  const currentDate = new Date();

  if (!data || data.length === 0) {
    // Handle the case when data is undefined or empty
    return null;
  }

  // Sort the data by the 'date' property in ascending order
  data.sort((a, b) => new Date(a.date) - new Date(b.date));

  // Find the first date that is greater than the current date
  const nextDate = data.find(item => new Date(item.date) > currentDate);

  // If a date is found, return it; otherwise, return the first date in the array
  return nextDate ? nextDate.date : data[0].date;
}

export const getNearestDate = (data) => {
  const currentDate = new Date();

  if (!data || data.length === 0) {
    // Handle the case when data is undefined or empty
    return null;
  }

  // Calculate the absolute difference between each date in the array and the current date
  const dateDifferences = data.map(item => Math.abs(new Date(item.date) - currentDate));

  // Find the index of the minimum difference
  const nearestDateIndex = dateDifferences.indexOf(Math.min(...dateDifferences));

  // Get the nearest date using the index
  const nearestDate = data[nearestDateIndex].date;

  return nearestDate;
}