// External imports
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Internal imports
import { Modal, SettingsTable } from '..'
import { setUserGraphSettings, getUserGraphSettings } from '../../actions/userPreferences'

export const GraphSettingsModal = ({ open, close, header, icon }) => {
  const dispatch = useDispatch()
  const { userGraphSettings } = useSelector((state) => state.userPreferenceReducer)
  const [scalingEnabled, setScalingEnabled] = useState(false)

  // Load user graph settings on component mount
  useEffect(() => {
    dispatch(getUserGraphSettings())
  }, [dispatch])

  // Update the local state when userGraphSettings is fetched
  useEffect(() => {
    if (userGraphSettings && userGraphSettings.scaling !== undefined) {
      setScalingEnabled(userGraphSettings.scaling)
    }
  }, [userGraphSettings])

  // Handle the toggle change for scaling
  const handleScalingToggle = () => {
    const updateScalingSetting = !scalingEnabled
    setScalingEnabled(updateScalingSetting)

    dispatch(setUserGraphSettings(userGraphSettings, 'scaling', updateScalingSetting))
  }

  // Setting table fields
  const setting = [{ field: 'scaling', displayedText: 'Scale Comparative graph' }]

  return (
    <Modal
      open={open}
      closeModal={close}
      headerContent={
        <span className="flex flex-row items-center">
          {icon}
          <h1 className="ml-2">{header}</h1>
        </span>
      }
      maxWidth='405px'
      verticalPosition='150px'
      content={
        <div className="w-full h-fit">
          <div className="pt-4 pb-4 text-sm text-textMuted font-semibold text-justify space-y-2">
              Apply a scaling factor to the data in the
              <span className='text-bold text-textBase'> Comparative </span> 
              graphs.
              Scaling can assist in comparing the changes in the
              <span className='text-bold text-textBase'> Reference </span>and
              <span className='text-bold text-textBase'> Comparative </span> 
              data over the period.
          </div>

          <div className="flex flex-row items-start w-full border-t-1 pt-2">
            <div
              className={`w-full flex flex-col rounded`}
            >
              <div className="grid grid-cols-1 grid-rows-10 gap-2">
              <div className=" border-neutral rounded flex flex-col px-2 pt-2 overflow-hidden bg-neutralBg">
                <div className="flex flex-row items-center justify-between font-semibold border-b-1 border-neutral pb-2">
                  <div className="flex flex-row items-center">
                    Scaling
                  </div>
                </div>
                <SettingsTable
                  settingsOptions={setting}
                  selectedSettings={scalingEnabled ? ['scaling'] : []}
                  setSelectedSettings={handleScalingToggle}
                />
              </div>
              </div>
            </div>
          </div>
        </div>
      }
    />
  )
}