import React from 'react';
import styled, { keyframes } from "styled-components";
import tw from "twin.macro";

const loadingAnimation = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;
// #3f8b8160, #81b8af60, #3f8b8160
const LoadingDiv = styled.div`
  ${tw`w-full min-h-[15px] max-h-[25px] h-full my-0.5 rounded`};
  background: linear-gradient(270deg, #e7e7e75f, #b4b4b45f, #e7e7e75f);
  background-size: 400% 100%;
  animation: ${loadingAnimation} 5.5s ease-in-out infinite;
`;

export const renderLoadingOrContent = (content, loading) => (loading ? <LoadingDiv /> : content);