import initialState from "../state";

const watchListReducer = (state = initialState, action) => {

  switch(action.type) {
    case 'FETCH_WATCHLIST':
      return {
        ...state,
        isFetchingWatchlist: false,
        watchList: action.payload
      };
    case 'GET_WATCHLIST':
      return {
        ...state,
        isFetchingWatchlistData: false,
        watchListData: action.payload
      };
    case 'ADD_DATA_TO_WATCHLIST':
      if(!state.watchList) state.watchList=[]
      if(!state.watchListData) state.watchListData=[]
      return {
        ...state,
        watchList: [...state.watchList, action.payload.symbol],
        watchListData: [...state.watchListData, action.payload]
      };
    case 'REMOVE_DATA_FROM_WATCHLIST':
      return {
        ...state,
        watchList: state.watchList.filter(symbol => symbol !== action.payload),
        watchListData: state.watchListData.filter(data => data.symbol !== action.payload)
      };
    case 'LOGIN':
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token
      };
    case 'LOGOUT':
      return {
        ...state,
        user: null,
        token: null
      };
    case 'GET_TOKEN':
      return {
        ...state,
        email: action.payload.email,
        token: action.payload.token
      };
    case 'SET_CANCELLED_SUBSCRIPTION':
      return {
        ...state,
        subscriptionType: action.payload.stripe.subscriptionType,
        billingPeriod: action.payload.stripe.billingPeriod,
        stripeCustomerId: action.payload.stripe.customerId,
        stripeSubscriptionId: null,
        hasSubscription: false,
        stripeIntent: null,
        // customerCards: action.payload.stripe.paymentMethods,
        subscriptionStatus: "cancelled",
        subscriptionDetails: null,
        hasActivePremiumSubscription:false,
      };
    case 'SET_SUBSCRIPTION':
      return {
        ...state,
        subscriptionType: action.payload.stripe.subscriptionType,
        billingPeriod: action.payload.stripe.billingPeriod,
        stripeCustomerId: action.payload.stripe.customerId,
        stripeSubscriptionId: action.payload.stripe.subscriptionId,
        stripeIntent: action.payload.stripe.setupIntent,
        hasSubscription: action.payload.hasSubscription,
        subscriptionStatus: action.payload.subscription.status,
        subscriptionDetails: action.payload.subscription,
        hasActivePremiumSubscription: 
       ( (action.payload.subscription.status === "trialing" || action.payload.subscription.status === "active") 
       && action.payload.stripe.subscriptionType === 'premium'),
       isGettingSubscriptionDetails: false,
       customerCards: action.payload.stripe.card,
       trialBefore: action.payload.stripe.trialBefore,
       subscriptionStatus: action.payload.stripe.subscriptionStatus,
      };
    case 'UPDATE_SUBSCRIPTION_TYPE':
      return {
        ...state,
        subscriptionType: action.payload.stripe.subscriptionType,
        billingPeriod: action.payload.stripe.billingPeriod,
        hasActivePremiumSubscription: 
       ( (state.subscriptionDetails.status === "trialing" || state.subscriptionDetails.status === "active") 
       && action.payload.stripe.subscriptionType === 'premium'),
      };
    case 'SET_CUSTOMER_CARDS':
      return {
        ...state,
        customerCards: action.payload
      };
    case 'SET_PRICING':
      return {
        ...state,
        pricing: action.payload
      };
      
    default:
      return state;
  }
};

export default watchListReducer;