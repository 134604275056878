// External imports
import React from 'react'
import styled from "styled-components";
import tw from "twin.macro";
import { useMediaQuery } from 'react-responsive';

// Internal imports
import { WebsiteLogo } from '../../assets';
import { COMPANY_WEBSITE_SHORT } from '../../data/text'
import { deviceSize } from '../../data/devices';

// Styling
const LogoImg = styled.div` 
  ${tw`flex items-center justify-center p-2 select-none`};
  width: ${({ width }) => (width ? width : "60px")};
  height: ${({ height }) => (height ? height : "60px")};
  img { width: 100%; height: 100%; }
`;

// Logo image
export function LogoImage(props) { 
  const{width, height} = props;

  return (
    <LogoImg width={width} height={height}>
      <img src={WebsiteLogo} alt="Logo" type="image/png"/>
    </LogoImg>
  );
}

// Logo text
export function LogoText() {
  const isMobile = useMediaQuery({ maxWidth: deviceSize.mobile })

  return (
    <h2 className='flex items-center justify-items-start m-0 ml-1 text-lg text-textBase tracking-widest font-light'>
      {!isMobile && COMPANY_WEBSITE_SHORT}
    </h2>
  );
}