// External imports
import React from 'react'

// Internal imports
import { ToggleButton } from '../../components'

export const SettingsTable = ({
  settingsOptions,
  selectedSettings,
  setSelectedSettings,
}) => {
  const handleSelection = (e) => {
    const { value } = e.target
    if (selectedSettings.includes(value)) {
      setSelectedSettings(
        selectedSettings.filter((setting) => setting !== value)
      )
    } else {
      setSelectedSettings([...selectedSettings, value])
    }
  }

  return (
    <div className="py-2 overflow-y-auto">
      <table className="w-full text-base">
        <tbody className="space-y-2">
          {settingsOptions.map((setting) => (
            <tr
              key={setting.field}
              className="flex flex-row justify-center items-center h-[22px]"
            >
              <td className="flex justify-center items-start">
                <label htmlFor={setting.field} className="h-[22px]">
                  <ToggleButton
                    id={`toggle-${setting.field}`}
                    value={setting.field}
                    checked={selectedSettings.includes(setting.field)}
                    onChange={handleSelection}
                  />
                </label>
              </td>
              <td className="w-full ml-2 pl-2 border-l-1 border-neutral">
                <h1
                  htmlFor={`toggle-${setting.field}`}
                  className="text-xs text-textBase"
                >
                  {setting.displayedText}
                </h1>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
