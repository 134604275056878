import * as api from '../api';

// Action Creators

// Create an action to fetch all stock data from the API
export const getStockData = (ticker) => async (dispatch) => {
  try {
    window.history.replaceState(null, '', `/stockanalysis/${ticker}`);
    const { data } = await api.fetchStockData(ticker);
    dispatch({ type: 'FETCH_ALL_STOCKS', payload: data });
  } catch (error) {
    console.log(error.message)
  }
}

export const getIsValidStock = (ticker) => async (dispatch) => {
  try {
    return await api.fetchStockData(ticker).then((data)=>{
      return true;
    })
  } catch (error) {
    return false;
  }
}

export const getStockHistoricalPrice = (symbol, interval, fromDate, toDate, timeframe) => async (dispatch) => {
  try {
    const { data } = await api.getStockHistoricalPrice({symbol, interval, fromDate, toDate, timeframe});
    let priceData =data.chartData.map((element)=>{
      element.date = new Date(element.date);
      return element;
    });
    dispatch({ type: `SET_PRICE_CHART_SYMBOL`, payload: symbol });
    dispatch({ type: `SET_${timeframe}_DATA`, payload: priceData });
    return priceData;
  } catch (error) {
    return false;
  }
}