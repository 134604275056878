import React, { useRef } from 'react'
import { Link } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";

import { LandingPageNavbar, ContentContainer, FormButton } from '../../components';
import { aboutText, supportText, termsOfServiceText, privacyPolicyText } from '../../data/text.js'
// import { useAuth0 } from '@auth0/auth0-react';
import { Footer } from './index.jsx';
import emailjs from '@emailjs/browser';
import toast, { Toaster } from 'react-hot-toast';

export const ParagraphDiv = styled.div` ${tw`w-full flex flex-col justify-start text-justify align-top text-xs`}; `
export const SubHeader = styled.h1` ${tw`w-[fit] flex flex-row font-semibold text-base`}; `

export const About = () => {
  return (
    <div className='w-full h-screen flex flex-col justify-between items-center px-0 overflow-y-auto'>
      <LandingPageNavbar className='flex border-b-1' />
      <div className='m-6'>
        <ContentContainer 
          Header={'About'}
          hasExpand={false}
          maxWidth={'800px'}
          Content={
            <div className='space-y-2'>
              {aboutText.map((section, index) => (
                <ParagraphDiv key={index}>
                  <SubHeader>{section.header}:</SubHeader>
                  <span className='text-textMuted'>{section.body}</span>  
                </ParagraphDiv>
              ))}
            </div>
          }
        />
      </div>
      <Footer/>
    </div>
  );
}

export const Support = () => {
  const formRef = useRef(0);

  const sendEmail = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const templateParams = {
      from_name: formData.get('email'),
      message: `Type: ${formData.get('select')}\nMessage: ${formData.get('message')}`
    };
    toast.loading('Sending...', {duration: 2000});
    emailjs.send(
      process.env.REACT_APP_EMAIL_SERVICE_ID,
      process.env.REACT_APP_EMAIL_TEMPLATE_ID,
      templateParams,
      process.env.REACT_APP_EMAIL_PUB_KEY,
    ).then(() => {
      formRef.current?.reset();
      toast.success('Successfully sent!');
    }).catch(() => toast.error('Failed to send'));
  };

  return (
    <div className='w-full h-screen flex flex-col justify-between items-center px-0 overflow-y-auto'>
      <LandingPageNavbar className='flex border-b-1'/>
      <div className='m-6'>
        <ContentContainer 
          Header={'Support'}
          hasExpand={false}
          maxWidth={'800px'}
          Content={
            <div className='w-[fit] flex flex-col justify-start align-top space-y-2'>
              {supportText.map((section, index) => (
                <div className='w-full flex flex-col justify-start text-justify align-top text-xs' key={index}>
                  <h1 className='w-[fit] flex flex-row font-semibold text-base'>{section.header}:</h1>
                  {section.body}
                </div>
              ))}
              <div><Toaster /></div>
              <form className='w-[fit] flex flex-col' onSubmit={sendEmail} ref={formRef}>
                <label className='font-semibold' htmlFor="email">Email:</label>
                <input 
                  className='rounded mb-2 bg-neutralBg text-sm h-[30px]' 
                  type="text" 
                  id="email" 
                  name="email" 
                  required
                />
                <label className='font-semibold' htmlFor="Type">Support type:</label>
                <select 
                  className='rounded mb-2 bg-neutralBg text-sm h-[30px]' 
                  id="issue-type" 
                  name='select'
                >
                  <option value="Technical issue" defaultValue >Technical issue</option>
                  <option value="General question">General question</option>
                  <option value="Suggestion">Suggestion</option>
                  <option value="Other">Other</option>
                </select>
                <label className='font-semibold' htmlFor="message">Message:</label>
                <textarea 
                  className='h-[200px] rounded mb-2 bg-neutralBg p-1 text-sm' 
                  id="message" 
                  name="message" 
                  required
                />
                <FormButton text="Submit"/>
              </form>
            </div>
          }
        />
      </div>  
      <Footer setFooterHeight={() => {}}/>
    </div>
  );
}

export const Terms = () => {
   return (
    <div className='w-full h-screen flex flex-col justify-between items-center overflow-y-auto'>
      <LandingPageNavbar className='flex border-b-1'/>
      <div className='m-6'>  
        <ContentContainer 
          Header={'Terms of Service'}
          hasExpand={false}
          maxWidth={'800px'}
          maxHeight={'800px'}
          yScroll={true}
          Content={
            <div className='space-y-2 pr-2'>
              {termsOfServiceText.map((section, index) => (
                <ParagraphDiv key={index}>
                  <SubHeader>{section.header}:</SubHeader>
                  <span className='text-textMuted'>
                    {section.header === "Contact Us" ? (
                      <span>
                        {section.body.slice(0, section.body.indexOf("support"))}
                        <Link to="/support" className="underline hover:text-blue-500 text-textBase font-semibold">support</Link>.
                      </span>
                    ) : (
                      section.body
                    )}
                  </span>
                </ParagraphDiv>
              ))}
            </div>
          }
        />
      </div>  
      <Footer setFooterHeight={() => {}}/>
    </div>
  )
}

export const PrivacyPolicy = () => {
  return (
    <div className='w-full h-screen flex flex-col justify-between items-center overflow-y-auto'>
      <LandingPageNavbar className='flex border-b-1'/>  
      <div className='m-6'>
        <ContentContainer 
          Header={'Privacy Policy'}
          hasExpand={false}
          maxWidth={'800px'}
          maxHeight={'800px'}
          yScroll={true}
          Content={
            <div className='space-y-2 pr-2'>
              {privacyPolicyText.map((section, index) => (
                <ParagraphDiv key={index}>
                  <SubHeader>{section.header}:</SubHeader>
                  <span className='text-textMuted'>
                    {section.header === "Contact Us" ? (
                      <span>
                        {section.body.slice(0, section.body.indexOf("support"))}
                        <Link to="/support" className="underline hover:text-blue-500 text-textBase font-semibold">support</Link>.
                      </span>
                    ) : (
                      section.body
                    )}
                  </span>
                </ParagraphDiv>
              ))}
            </div>
          }
        />
      </div>
      <Footer setFooterHeight={() => {}}/>
    </div>
  )
}