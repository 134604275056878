// External imports
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
// import { BsToggles } from "react-icons/bs";
// import { HiOutlineSquaresPlus } from "react-icons/hi2";
import { MdOutlineFitScreen } from 'react-icons/md'
import { useMediaQuery } from 'react-responsive'
import { motion } from 'framer-motion';

// Internal imports
import { Graph, Modal, IconButtons, ContentContainer, NumericOptionSelector, TextOptionSelector, SingleSelectDropdown, BoxAnimation } from '../../components/index'
import { updateFundamentalTimeHorizon, updateFundamentalPeriodType } from '../../actions/userPreferences'
import { useGetFundamentalQuery } from '../../rtk'
import { useDispatch, useSelector } from 'react-redux'

// Styling
const motionConfig = (delay = 0) => ({
  initial: { opacity: 0, y: 15 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.8, delay },
});

const MovingComponent = (props) => ( <motion.div {...motionConfig(props.delay)} {...props}/> );

const CardContainer = styled.div`
  ${tw`w-full grid items-center justify-center bg-primaryBg text-textBase gap-2 rounded`};
  /* grid-template-columns: repeat(auto-fit, minmax(500px, 1fr)); */
  @media (min-width: 1800px) {
    ${tw`grid-cols-4`};
  }
  @media (max-width: 1799px) {
    ${tw`grid-cols-3`};
  }
  @media (max-width: 1399px) {
    ${tw`grid-cols-2`};
  }
  @media (max-width: 899px) {
    ${tw`grid-cols-1`};
  }
`
const Card = styled.div`${tw`w-[fit] h-[fit] text-xl font-semibold p-2 flex flex-col flex-1 justify-start items-center bg-primaryBg`};`
const ContainerTopRow = styled.div`${tw`w-full flex flex-row items-center justify-between font-semibold text-base border-b border-neutral pb-2`};`
const ChartContainer = styled.div`${tw`pt-2 w-full h-[250px]`};`

// Padding logic: Pads the shorter dataset with nulls at the start
const padData = (data1, data2) => {
  const maxLength = Math.max(data1?.[0]?.data.length || 0, data2?.[0]?.data.length || 0);
  const pad = (data) => new Array(maxLength - data.length).fill(null).concat(data);
  const padDataset = (dataset) => dataset?.map((item) => ({ ...item, data: pad(item.data) })) || [];
  return { data1: padDataset(data1), data2: padDataset(data2) };
};

// Section for displaying the fundamentals of a stock
export const Fundamentals = ({ symbol, compareSymbol, fundamentalTimeHorizon, fundamentalPeriodType }) => {
  const dispatch = useDispatch()
  const showDropDownToggles = useMediaQuery({ maxWidth: '850px' })

  const { scaling } = useSelector(
    (state) => state.userPreferenceReducer.userGraphSettings
  )

  const { data: fundamentalData, isError, isFetching } = useGetFundamentalQuery({ symbol, period: fundamentalPeriodType })
  const { data: compareFundamentalData, isError: isCompareError, isFetching: isCompareFetching } = useGetFundamentalQuery(
    { symbol: compareSymbol, period: fundamentalPeriodType }, { skip: !compareSymbol }
  );

  const getChartData = (data, key, innerKey) => {
    if (isFetching || isError) return { data: [], labels: [] };
    const values = data[key] || [];
    const result = values.map(val => ({ data: val[innerKey], label: val.date }));
    return {
      data: result.slice(0, fundamentalTimeHorizon).reverse().map(item => item.data),
      labels: result.slice(0, fundamentalTimeHorizon).reverse().map(item => item.label),
    };
  };

  // Content for all fundamental graphs
  const graphContent = [
    {
      statType: 'Revenue',
      chartData: [
        { name: 'Revenue', ...getChartData(fundamentalData, 'incomeStatement', 'revenue') },
      ],
      comparativeData: compareSymbol != null && !isCompareFetching ? [
        { name: 'Revenue', ...getChartData(compareFundamentalData, 'incomeStatement', 'revenue') }
      ] : null,
      chartType: 'bar',
      chartColours: ['#84dcc6', '#84dcc6'],
      isStacked: false,
      valueFormat: 'currency',
      tickRounding: 1,
      dataRounding: 2,
      scaling: scaling,
    },
    {
      statType: 'Expenses',
      chartData: [
        { name: 'Cost of Revenue', ...getChartData(fundamentalData, 'incomeStatement', 'costOfRevenue'), stack: 'Expense' },
        { name: 'R&D', ...getChartData(fundamentalData, 'incomeStatement', 'researchExpenses'), stack: 'Expense' },
        { name: 'Admin', ...getChartData(fundamentalData, 'incomeStatement', 'generalAdmin'), stack: 'Expense' },
        { name: 'Marketing', ...getChartData(fundamentalData, 'incomeStatement', 'marketing'), stack: 'Expense' },
      ],
      comparativeData: compareSymbol != null && !isCompareFetching ? [
        { name: 'Cost of Revenue', ...getChartData(compareFundamentalData, 'incomeStatement', 'costOfRevenue'), stack: 'CompExpense' },
        { name: 'R&D', ...getChartData(compareFundamentalData, 'incomeStatement', 'researchExpenses'), stack: 'CompExpense' },
        { name: 'Admin', ...getChartData(compareFundamentalData, 'incomeStatement', 'generalAdmin'), stack: 'CompExpense' },
        { name: 'Marketing', ...getChartData(compareFundamentalData, 'incomeStatement', 'marketing'), stack: 'CompExpense' },
      ] : null,
      chartType: 'bar',
      chartColours: ['#AD2831', '#FF6961', '#FFC0CB', '#FFA07A', '#AD2831', '#FF6961', '#FFC0CB', '#FFA07A'],
      isStacked: true,
      valueFormat: 'currency',
      tickRounding: 1,
      dataRounding: 2,
      scaling: scaling,
    },
    {
      statType: `Earnings`,
      chartData: [
        { name: 'Revenue', ...getChartData(fundamentalData, 'incomeStatement', 'revenue') },
        { name: 'Gross Profit', ...getChartData(fundamentalData, 'incomeStatement', 'grossProfit') },
        { name: 'EBITDA', ...getChartData(fundamentalData, 'incomeStatement', 'ebitda') },
      ],
      comparativeData: compareSymbol != null && !isCompareFetching ? [
        { name: 'Revenue', ...getChartData(compareFundamentalData, 'incomeStatement', 'revenue') },
        { name: 'Gross Profit', ...getChartData(compareFundamentalData, 'incomeStatement', 'grossProfit') },
        { name: 'EBITDA', ...getChartData(compareFundamentalData, 'incomeStatement', 'ebitda') },
      ] : null,
      chartType: 'bar',
      chartColours: ['#84dcc6', '#6ec2b8', '#5f9ea0', '#84dcc6', '#6ec2b8', '#5f9ea0'],
      isStacked: false,
      valueFormat: 'currency',
      tickRounding: 1,
      dataRounding: 2,
      scaling: scaling,
    },
    {
      statType: 'Free Cash Flow',
      chartData: [
        { name: 'FCF', ...getChartData(fundamentalData, 'cashFlow', 'freeCashFlow') },
        { name: 'Stock Based Comp', ...getChartData(fundamentalData, 'cashFlow', 'stockBasedCompensation') },
        { name: 'SBC adj FCF', ...getChartData(fundamentalData, 'cashFlow', 'stockBasedCompensationAdjustedFreeCashFlow') },
      ],
      comparativeData: compareSymbol != null && !isCompareFetching ? [
        { name: 'FCF', ...getChartData(compareFundamentalData, 'cashFlow', 'freeCashFlow') },
        { name: 'Stock Based Comp', ...getChartData(compareFundamentalData, 'cashFlow', 'stockBasedCompensation') },
        { name: 'SBC adj FCF', ...getChartData(compareFundamentalData, 'cashFlow', 'stockBasedCompensationAdjustedFreeCashFlow') },
      ] : null,
      chartType: 'bar',
      chartColours: ['#a7cbd9', '#fa898b', '#809bce', '#a7cbd9', '#fa898b', '#809bce',],
      isStacked: false,
      valueFormat: 'currency',
      tickRounding: 1,
      dataRounding: 2,
      scaling: scaling,
    },
    {
      statType: 'Cash & Debt',
      chartData: [
        { name: 'Cash',  ...getChartData(fundamentalData, 'balanceSheet', 'cash'), stack: 'Cash' },
        { name: 'Long Term Debt', ...getChartData(fundamentalData, 'balanceSheet', 'debt'), stack: 'Debt' },
        { name: 'Capital Lease Obligations', ...getChartData(fundamentalData, 'balanceSheet', 'capitalLeaseObligations'), stack: 'Debt' },
      ],
      comparativeData: compareSymbol != null && !isCompareFetching ? [
        { name: 'Cash',  ...getChartData(compareFundamentalData, 'balanceSheet', 'cash'), stack: 'CompCash' },
        { name: 'Long Term Debt', ...getChartData(compareFundamentalData, 'balanceSheet', 'debt'), stack: 'CompDebt' },
        { name: 'Capital Lease Obligations', ...getChartData(compareFundamentalData, 'balanceSheet', 'capitalLeaseObligations'), stack: 'CompDebt' },
      ] : null,
      chartType: 'bar',
      chartColours: ['#bae0a9', '#fa898b', '#ffbf87', '#bae0a9', '#fa898b', '#ffbf87'],
      isStacked: true,
      valueFormat: 'currency',
      tickRounding: 1,
      dataRounding: 2,
      scaling: scaling,
    },
    {
      statType: 'Shares Outstanding',
      chartData: [
        { name: 'Shares Outstanding', ...getChartData(fundamentalData, 'incomeStatement', 'sharesOutstanding') },
      ],
      comparativeData: compareSymbol != null && !isCompareFetching ? [
        { name: 'Shares Outstanding', ...getChartData(compareFundamentalData, 'incomeStatement', 'sharesOutstanding') },
      ] : null,
      chartType: 'bar',
      chartColours: ['#be9ddf', '#be9ddf'],
      isStacked: false,
      valueFormat: '',
      tickRounding: 1,
      dataRounding: 2,
      scaling: scaling,
    },
    {
      statType: 'Dividends',
      chartData: [
        { name: 'Dividends', ...getChartData(fundamentalData, 'historicDailyDividend', 'dividend') },
      ],
      comparativeData: compareSymbol != null && !isCompareFetching ? [
        { name: 'Dividends', ...getChartData(compareFundamentalData, 'historicDailyDividend', 'dividend') },
      ] : null,
      chartType: 'bar',
      chartColours: ['#aebdce', '#aebdce'],
      isStacked: false,
      valueFormat: 'currency',
      tickRounding: 2,
      dataRounding: 2,
      scaling: scaling,
    },
    {
      statType: 'Effectiveness Ratios',
      chartData: [
        { name: 'ROIC', ...getChartData(fundamentalData, 'keyMetrics', 'roic') },
        { name: 'ROCE', ...getChartData(fundamentalData, 'financialRatios', 'roce') },
        { name: 'ROA', ...getChartData(fundamentalData, 'financialRatios', 'roa') },
        { name: 'ROE', ...getChartData(fundamentalData, 'keyMetrics', 'roe') },
      ],
      comparativeData: compareSymbol != null && !isCompareFetching ? [
        { name: 'ROIC', ...getChartData(compareFundamentalData, 'keyMetrics', 'roic') },
        { name: 'ROCE', ...getChartData(compareFundamentalData, 'financialRatios', 'roce') },
        { name: 'ROA', ...getChartData(compareFundamentalData, 'financialRatios', 'roa') },
        { name: 'ROE', ...getChartData(compareFundamentalData, 'keyMetrics', 'roe') },
      ] : null,
      chartType: 'line',
      chartColours: ['#809bce', '#EC8F6A', '#cdeac0', '#be9ddf', '#809bce80', '#EC8F6A80', '#abce9baa', '#be9ddf80'],
      isStacked: false,
      valueFormat: 'percentage',
      tickRounding: 0,
      dataRounding: 2,
    },
    {
      statType: 'Margins',
      chartData: [
        { name: 'Gross Profit', ...getChartData(fundamentalData, 'incomeStatement', 'grossProfitRatio') },
        { name: 'EBITDA', ...getChartData(fundamentalData, 'incomeStatement', 'ebitdaratio') },
        { name: 'Profit', ...getChartData(fundamentalData, 'incomeStatement', 'netIncomeRatio') },
      ],
      comparativeData: compareSymbol != null && !isCompareFetching ? [
        { name: 'Gross Profit', ...getChartData(compareFundamentalData, 'incomeStatement', 'grossProfitRatio') },
        { name: 'EBITDA', ...getChartData(compareFundamentalData, 'incomeStatement', 'ebitdaratio') },
        { name: 'Profit', ...getChartData(compareFundamentalData, 'incomeStatement', 'netIncomeRatio') },
      ] : null,
      chartType: 'line',
      chartColours: ['#6ec2b8', '#5f9ea0', '#486989', '#6ec2b880', '#5f9ea080', '#48698980'],
      isStacked: false,
      valueFormat: 'percentage',
      tickRounding: 0,
      dataRounding: 2,
    },
    {
      statType: 'Growth Ratios',
      chartData: [
        { name: 'Revenue', ...getChartData(fundamentalData, 'financialGrowth', 'revenueGrowth') },
        { name: 'Profit', ...getChartData(fundamentalData, 'financialGrowth', 'netIncomeGrowth') },
        { name: 'FCF', ...getChartData(fundamentalData, 'financialGrowth', 'freeCashFlowGrowth') },
      ],
      comparativeData: compareSymbol != null && !isCompareFetching ? [
        { name: 'Revenue', ...getChartData(compareFundamentalData, 'financialGrowth', 'revenueGrowth') },
        { name: 'Profit', ...getChartData(compareFundamentalData, 'financialGrowth', 'netIncomeGrowth') },
        { name: 'FCF', ...getChartData(compareFundamentalData, 'financialGrowth', 'freeCashFlowGrowth') },
      ] : null,
      chartType: 'line',
      chartColours: ['#84dcc6', '#486989', '#A7CBD9', '#84dcc680', '#48698980', '#A7CBD980'],
      isStacked: false,
      valueFormat: 'percentage',
      tickRounding: 0,
      dataRounding: 2,
    },
    {
      statType: 'Price Ratios',
      chartData: [
        { name: 'P/E', ...getChartData(fundamentalData, fundamentalPeriodType === 'TTM' ? 'priceRatio' : 'keyMetrics', 'peRatio' ) },
        { name: 'P/FCF', ...getChartData(fundamentalData, fundamentalPeriodType === 'TTM' ? 'priceRatio' : 'keyMetrics', 'pfcfRatio') },
      ],
      comparativeData: compareSymbol != null && !isCompareFetching ? [
        { name: 'P/E', ...getChartData(compareFundamentalData, fundamentalPeriodType === 'TTM' ? 'priceRatio' : 'keyMetrics', 'peRatio' ) },
        { name: 'P/FCF', ...getChartData(compareFundamentalData, fundamentalPeriodType === 'TTM' ? 'priceRatio' : 'keyMetrics', 'pfcfRatio') },
      ] : null,
      chartType: 'line',
      chartColours: ['#FBC687', '#32AFA9', '#FBC68780', '#32AFA980'],
      isStacked: false,
      valueFormat: '',
      tickRounding: 1,
      dataRounding: 2,
    },
    {
      statType: 'Per Share Ratios',
      chartData: [
        { name: 'EPS', ...getChartData(fundamentalData, fundamentalPeriodType === 'TTM'  ? 'perShareRatio' : 'incomeStatement', 'eps') },
        { name: 'FCFPS', ...getChartData(fundamentalData, fundamentalPeriodType === 'TTM' ? 'perShareRatio' : 'keyMetrics', 'freeCashFlowPerShare') },
      ],
      comparativeData: compareSymbol != null && !isCompareFetching ? [
        { name: 'EPS', ...getChartData(compareFundamentalData, fundamentalPeriodType === 'TTM'  ? 'perShareRatio' : 'incomeStatement', 'eps') },
        { name: 'FCFPS', ...getChartData(compareFundamentalData, fundamentalPeriodType === 'TTM' ? 'perShareRatio' : 'keyMetrics', 'freeCashFlowPerShare') },
      ] : null,
      chartType: 'line',
      chartColours: ['#D45D79', '#8AC6D1', '#D45D7980', '#8AC6D180'],
      isStacked: false,
      valueFormat: 'currency',
      tickRounding: 2,
      dataRounding: 2,
      scaling: scaling,
    },
  ]

  // Padding data if needed
  const paddedGraphContent = graphContent.map((content) => {
    if (content.comparativeData) {
      const { data1: chartData, data2: comparativeData } = padData(content.chartData,content.comparativeData);
      return {...content, chartData, comparativeData};
    }
    return content;
  });

  return (
    <ContentContainer
      Header={'Financials'}
      hasExpand={true}
      maxWidth={'2500px'}
      Toggle={
        !showDropDownToggles && (
          <TextOptionSelector
            options={['TTM', 'Annual', 'Quarterly']}
            selection={fundamentalPeriodType}
            setSelection={updateFundamentalPeriodType}
          />
        )
      }
      Icons={
        <div className="flex flex-row space-x-2 items-center">
          {!showDropDownToggles && (
            <NumericOptionSelector
              name={ fundamentalPeriodType === 'Quarterly' ? 'Quarters' : 'Years' }
              options={[5, 10, 20, 40]}
              selection={fundamentalTimeHorizon}
              setSelection={updateFundamentalTimeHorizon}
            />
          )}
          {showDropDownToggles && (
            <div className="flex flex-row space-x-2 w-[200px]">
              <SingleSelectDropdown
                setSelected={(option) =>
                  dispatch(updateFundamentalPeriodType(option))
                }
                dropDownOptions={['TTM', 'Annual', 'Quarterly']}
                width={'full'}
                useParentInput={true}
                parentInput={fundamentalPeriodType}
              />
              <SingleSelectDropdown
                setSelected={(option) => dispatch(updateFundamentalTimeHorizon(option))}
                suffix={ fundamentalPeriodType === 'Quarterly' ? 'Quarters' : 'Years' }
                dropDownOptions={[5, 10, 20, 40]}
                width={'fit'}
                useParentInput={true}
                parentInput={fundamentalTimeHorizon}
              />
            </div>
          )}
        </div>
      }
      Content={
        <CardContainer>
          {paddedGraphContent.map((card, index) => (
            <MovingComponent key={index} delay={index * 0.1}>
              <StatCard
                key={index}
                {...card}
                isFetchingData={isFetching}
                symbol={symbol}
                compareSymbol={compareSymbol}
              />
            </MovingComponent>
          ))}
        </CardContainer>
      }
    />
  )
}

// Card for each graph
const StatCard = (
  { statType, chartData, comparativeData, chartColours, chartType, isStacked, valueFormat, displayLegend, tickRounding, dataRounding, isFetchingData, symbol, compareSymbol, scaling }
) => {
  const [openModal, setOpenModal] = useState(false)
  // const [openSettingsModal, setOpenSettingsModal] = useState(false);
  
  // Disable scrolling when modal is open
  useEffect(() => {
    document.body.style.overflow = openModal ? 'hidden' : 'auto'
  }, [openModal])

  // Individual chart component
 const getChartContent = () => {
    if (!chartData?.[0]?.labels?.length) {
      return (
        <div className="flex justify-center items-center h-full">
          {!isFetchingData ? (
            <h1 className="text-textMuted text-base font-bold">No data to display</h1>
          ) : (
            <BoxAnimation />
          )}
        </div>
      );
    }
    return (
      <Graph
        statType={statType}
        chartData={chartData}
        comparativeData={comparativeData}
        chartType={chartType}
        chartColours={chartColours}
        isStacked={isStacked}
        valueFormat={valueFormat}
        displayLegend={displayLegend}
        tickRounding={tickRounding}
        dataRounding={dataRounding}
        symbol={symbol}
        compareSymbol={compareSymbol}
        scaling={scaling}
      />
    );
  };

  const Chart = ({ width, height }) => (
    <ChartContainer style={{ width, height }} className="cursor-auto">
      {getChartContent()}
    </ChartContainer>
  );

  return (
    <>
      <Card className="border-1 border-neutral">
        <ContainerTopRow>
          {statType}
          <div className="flex flex-row space-x-2">
            <IconButtons
              action={() => setOpenModal(true)}
              icon={<MdOutlineFitScreen />}
              text="Expand"
            />
          </div>
        </ContainerTopRow>
        <ChartContainer style={{ width: '98%', height: 235 }} className="cursor-auto">
          {getChartContent()}
        </ChartContainer>
      </Card>
      <Modal
        open={openModal}
        closeModal={() => setOpenModal(false)}
        headerContent={<div className="text-lg flex items-center">{symbol} - {statType}</div>}
        content={<Chart height="min(60vw, calc(45vh - 60px))" />}
        footerContent="InvestmentHorizon.io"
        maxWidth="1500px"
        maxHeight="50vh"
      />
    </>
  );
};