export const backgroundDotsPlugin = {
  id: 'backgroundDots',
  beforeDraw: (chart) => {
    const { ctx, chartArea: { left, right, top, bottom } } = chart;
    ctx.save();
    ctx.fillStyle = 'rgba(128, 128, 128, 0.3)' //palette.colors.chartDots

    const dotSpacing = 5;
    const dotRadius = 0.5;

    const dotCanvas = document.createElement('canvas');
    dotCanvas.width = dotSpacing;
    dotCanvas.height = dotSpacing;
    const dotCtx = dotCanvas.getContext('2d');

    dotCtx.beginPath();
    dotCtx.arc(dotSpacing / 2, dotSpacing / 2, dotRadius, 0, 2 * Math.PI);
    dotCtx.fillStyle = ctx.fillStyle;
    dotCtx.fill();

    const pattern = ctx.createPattern(dotCanvas, 'repeat');
    ctx.fillStyle = pattern;

    ctx.fillRect(left, top, right - left, bottom - top);
    ctx.restore();
  }
};