import { profileSettings } from '../../data/profile'
import { AccountSettingCardLayout } from './accountSettingCardLayout'
import { privacyPolicyText } from '../../data/text'

export const AccountPrivacy = () => {
  const privacySetting = profileSettings.find(
    (setting) => setting.header === 'Privacy policy'
  )
  return (
    <AccountSettingCardLayout
      icon={privacySetting.icon}
      pageName={privacySetting.header}
      text={privacyPolicyText}
      yScroll={false}
      maxHeight={'80vh'}
    />
  )
}
