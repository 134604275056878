import { useRef, useEffect} from 'react';

export const useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let eventHandler = (event) => {
      if(!domNode.current.contains(event.target)) {
        handler();
      }
    }

    document.addEventListener('mousedown', eventHandler);

    return () => {
      document.removeEventListener('mousedown', eventHandler);
    };
  });

  return domNode;
}