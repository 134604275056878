import { metricOptions, nameConversions } from '../data/metricOptions';

export const getNameConversion = (text) => {
  const conversion = nameConversions.find((name) => name.shortName === text);
  const metricOption = metricOptions.find((option) => option.field === text);

  if (conversion) {
    return (
      <>
        <img
          className='mr-2.5'
          src={`https://flagcdn.com/16x12/${text.toLowerCase()}.png`}
          width="16"
          height="12"
          alt="Flag"
          type="image/png"
        />
        {conversion.name}
      </>
    );
  } else if (metricOption) {
    return metricOption.displayedText || text;
  } else {
    return text;
  }
};