// External imports
import React, { useMemo } from 'react'
import { Bar, Line } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js'
import { useTheme } from 'styled-components'
import dayjs from 'dayjs'

// Internal imports
import { getFormattedValue } from '../../utils/formatUtils'
import { fundamentalGraphHelper, isOptionShouldNotHide, graphOptionHelper } from '../../utils/fundamentalUtils'
import { useDispatch, useSelector } from 'react-redux'
import { setUserGraphPreferences } from '../../actions/userPreferences'

import { backgroundDotsPlugin } from '../graphs/plugins';
import { CompanyLabel } from '../graphs/companyLabel';

// Register chart.js plugins
Chart.register(...registerables)

/**
 * Handle when a legend (graph option like revenue / R&D) is clicked
 * @param {UserGraphPreferences} userGraphPreferences
 * @param {Dispatch} displatch
 * @param {string} statType
 * @returns a function
 */

const handleLegendClick = (userGraphPreferences, dispatch, statType) => (e, legendItem, legend) => {
    const index = legendItem.datasetIndex
    const ci = legend.chart
    let bool
    if (ci.isDatasetVisible(index)) {
      ci.hide(index)
      legendItem.hidden = true
      bool = false
    } else {
      ci.show(index)
      legendItem.hidden = false
      bool = true
    }
    dispatch(
      setUserGraphPreferences(
        userGraphPreferences,
        statType,
        graphOptionHelper(legendItem.text, statType),
        bool
      )
    )
  }

/**
 * Graph tooltip formatter
 * @param {object} tooltipItems
 * @returns formatted tooltip title
 */

const tooltipTitle = (tooltipItems) => {
  return dayjs(tooltipItems[0].dataset.date[tooltipItems[0].dataIndex]).format(
    'DD MMM YYYY'
  )
}

const calculateScalingPerDataset = (chartData, comparativeData) => {
  return comparativeData.map((compData, index) => {
    const chartDataset = chartData[index];
    
    if (chartDataset && chartDataset.data.length && compData.data.length) {
      // Find the first non-zero/non-null data point index in both datasets
      const firstChartIndex = chartDataset.data.findIndex(value => value !== 0 && value !== null);
      const firstCompIndex = compData.data.findIndex(value => value !== 0 && value !== null);
      
      const commonIndex = Math.max(firstChartIndex, firstCompIndex);
      
      let scalingFactor = 1;
      if (commonIndex !== -1 && chartDataset.data[commonIndex] && compData.data[commonIndex]) {
        scalingFactor = chartDataset.data[commonIndex] / compData.data[commonIndex];
      }
      
      // Scale comparative dataset based on the common index scaling factor
      return {
        ...compData,
        data: compData.data.map(value => {
          if (value === null || value === 0) {
            return value;
          }
          return value * Math.abs(scalingFactor);
        }),
      };
    }
    return compData;
  });
};

export const Graph = ({
  statType,
  chartData,
  comparativeData,
  chartType,
  chartColours,
  isStacked,
  valueFormat,
  displayLegend,
  tickRounding,
  dataRounding,
  symbol,
  compareSymbol,
  scaling,
}) => {
  const { userGraphPreferences } = useSelector(
    (state) => state.userPreferenceReducer
  )

  const dispatch = useDispatch()
  const palette = useTheme()

  // Find the longer dataset between chartData and comparativeData
  const longestLabels = useMemo(() => {
    const chartLabels = chartData[0]?.labels || [];
    const compLabels = comparativeData && comparativeData[0]?.labels || [];
    return chartLabels.length >= compLabels.length ? chartLabels : compLabels;
  }, [chartData, comparativeData]);

  // Scale comparative data based on the first visible data point
  const scaledComparativeData = useMemo(() => {
    if (comparativeData && scaling) {
      return calculateScalingPerDataset(chartData, comparativeData);
    }
    return comparativeData;
  }, [chartData, comparativeData, scaling]);

  // Calculate max value based on visible datasets
  const visibleMaxValue = useMemo(() => {
    return Math.max(...chartData.flatMap((data, index) =>
      isOptionShouldNotHide(userGraphPreferences, statType, data.name) ? data.data : []
    ));
  }, [chartData, userGraphPreferences, statType]);

  const visibleCompMaxValue = useMemo(() => 
    Math.max(
      0,
      ...(scaledComparativeData?.flatMap((data) => 
        isOptionShouldNotHide(userGraphPreferences, statType, data.name) ? data.data : []
      ) || [])
    ), 
    [scaledComparativeData, userGraphPreferences, statType]
  );
  
  const visibleCombinedMaxValue = useMemo(() => {
    const getMax = (data) =>
      Math.max(
        0,
        ...(data?.flatMap((d) =>
          isOptionShouldNotHide(userGraphPreferences, statType, d.name) ? d.data : []
        ) || [])
      );
    
    return Math.max(getMax(chartData), getMax(scaledComparativeData));
  }, [chartData, scaledComparativeData, userGraphPreferences, statType]);

  const options = {
    maintainAspectRatio: false,
    width: 700,
    height: 450,
    plugins: {
      legend: {
        display: displayLegend,
        labels: {
          usePointStyle: true,
          pointStyle: 'rectRounded',
          color: palette.colors.text,
          filter: (legendItem, data) => {
            // Filter out comparative labels by checking for the "(Comp)" suffix
            return !legendItem.text.includes('(Comp)');
          },
        },
        onClick: handleLegendClick(
          userGraphPreferences,
          dispatch,
          fundamentalGraphHelper(statType)
        ),
        onHover: (event) => {
          event.native.target.style.cursor = 'pointer'
        },
        onLeave: (event) => {
          event.native.target.style.cursor = 'default'
        },
      },
      tooltip: {
        callbacks: {
          title: tooltipTitle,
        },
      },
      // chartColour: palette.colors.text,
    },
    scales: {
      x: {
        stacked: isStacked,
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
        },
        ticks: {
          display: true,
          color: palette.colors.textMuted,
          autoSkip: false,
          callback: function (value) {
            const labels = this.getLabels()
            const len = labels.length
            if (len > 10) {
              if (len % 2 === 0)
                return value % 2 === 1
                  ? dayjs(this.getLabelForValue(value)).format("MMM 'YY")
                  : ''
              else
                return value % 2 === 0
                  ? dayjs(this.getLabelForValue(value)).format("MMM 'YY")
                  : ''
            }
            return dayjs(this.getLabelForValue(value)).format("MMM 'YY")
          },
        },
      },
      y: {
        stacked: isStacked,
        beginAtZero: true,
        grid: {
          display: false,
          color: palette.colors.borderSecondary,
          drawBorder: false,
          drawOnChartArea: false,
        },
        ticks: {
          display: true,
          includeBounds: false,
          color: palette.colors.textMuted,
          callback: function (value, index, values) {
            const decimals = tickRounding
            return getFormattedValue({ value, valueFormat, maxValue: visibleCombinedMaxValue, decimals })
          },
        },
      },
    },
    indexAxis: 'x', // Ensures bars are vertical
    categoryPercentage: 0.95, // Adjusts the space between the bars
  }

  const datasets = chartData.map((data, index) => ({
    hidden: !isOptionShouldNotHide(userGraphPreferences, statType, data.name),
    label: data.name,
    data: data.data,
    backgroundColor: chartColours[index],
    borderColor: chartColours[index],
    borderWidth: chartType === 'line' ? 2 : 0,
    borderRadius: function (context) {
      const borderWidth = chartData.length >= 3 ? 2 : 3;
      return borderWidth;
    },
    stack: data.stack,
    pointRadius: 2,
    cubicInterpolationMode: 'monotone',
    date: data.labels,
    yAxisID: 'y',
    tooltip: {
      callbacks: {
        label: function (context) {
          const value = context.parsed.y;
          const decimals = dataRounding;
          return getFormattedValue({ value, valueFormat, maxValue: visibleMaxValue, decimals });
        },
      },
    },
  }));

  // Add comparative datasets if they exist
  if (scaledComparativeData) {
    scaledComparativeData.forEach((compData, index) => {
      datasets.push({
        hidden: !isOptionShouldNotHide(userGraphPreferences, statType, compData.name),
        label: `${compData.name} (Comp)`,
        data: compData.data,
        backgroundColor: chartColours[index] + '60', // Semi-transparent background
        borderColor: chartColours[index + chartData.length],
        borderWidth: chartType === 'line' ? 2 : 2,
        borderDash: [3, 3],
        borderRadius: function (context) {
          const borderWidth = chartData.length >= 3 ? 2 : 3;
          return borderWidth;
        },
        stack: compData.stack,
        pointRadius: 2,
        cubicInterpolationMode: 'monotone',
        date: compData.labels,
        yAxisID: 'y',
        tooltip: {
          callbacks: {
            label: function (context) {
              const value = context.parsed.y;
              const decimals = dataRounding;
              return getFormattedValue({ value, valueFormat, maxValue: visibleCompMaxValue, decimals });
            },
          },
        },
      });
    });
  }

  const data = {
    labels: longestLabels.map((date) => dayjs(date).format('MMM YYYY')),
    datasets: datasets,
    statType,
  };

  return chartData && chartData[0]?.labels ? (
    <div className={compareSymbol ? 'h-[calc(100%_-_22px)]' : 'h-full'}>

      {chartType === 'bar' ? (
        <Bar data={data} options={options} plugins={[backgroundDotsPlugin]} />
      ) : (
        <Line data={data} options={options} plugins={[backgroundDotsPlugin]} />
      )}

      {compareSymbol && ( 
        <CompanyLabel symbol={symbol} compareSymbol={compareSymbol} scaling={scaling} />
      )}
    </div>
  ) : null;
};