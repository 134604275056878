// External imports
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useMediaQuery } from 'react-responsive';
import { BsInfoSquare, BsBookmarkX, BsBookmarkHeart, BsSoundwave, BsToggles } from 'react-icons/bs'
import { HiOutlineShare, HiOutlineDocumentDuplicate, HiOutlineViewGridAdd } from 'react-icons/hi'
import { TbSettings } from "react-icons/tb";
import { IoNotificationsOutline } from 'react-icons/io5'
import { MdAutoGraph } from "react-icons/md";

// Internal imports
import {
  IconButtons,
  ContentContainer,
  Slider,
  ValueChangeText,
  OverviewTable,
  CompareModal,
  GraphSettingsModal,
  FiltersModal,
  ShareModal,
  BoxAnimation,
} from '../../../components'
import { getNearestDate, renderLoadingOrContent } from '../../../utils'
import { CompanyInfo } from './companyInfo'
import { Avatar } from '@mui/material'
import numeral from 'numeral'
import {
  useAddTickerToWatchlistMutation,
  useFetchWatchlistQuery,
  useRemoveTickerToWatchlistMutation,
} from '../../../rtk'

//Styling
const StockSummaryContainer = styled.div`
  ${tw`w-full h-full flex flex-row items-start`};
`
const CompanyInfoContainer = styled.div`
  ${tw`flex flex-row min-w-[calc(100vw_-_0px)] w-[430px] h-[100%] items-start justify-start`};
`
const CompanyLogoContainer = styled.div`
  ${tw`w-[80px] h-[90px] flex items-center justify-center p-1 rounded bg-neutralBg shadow-sm`};
`
const CompanyInfoText = styled.div`
  ${tw`flex flex-col h-[100%] items-start px-2 space-y-0.5`};
`
const StockPriceContainer = styled.div`
  ${tw`w-[fit] flex flex-row items-center text-center font-semibold space-x-1`};
`
const StockPrice = styled.div`
  ${tw`font-semibold mr-1 text-xl`};
`
const SummaryText = styled.h1`
  ${tw`text-center flex flex-nowrap text-xs`};
`
const TableContainer = styled.div`
  ${tw`flex flex-col mx-2`}
`

// Section for displaying stock summary
export const Overview = ({ symbol, ticker, isFetching, isError }) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const [openCompanyInfoModal, setOpenCompanyInfoModal] = useState(false)
  const [openShareModal, setOpenShareModal] = useState(false)
  const [openCompareModal, setOpenCompareModal] = useState(false)
  const [openNotificationsModal, setOpenNotificationsModal] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState([])
  const [screenerResults, setScreenerResults] = useState([])
  const [openSettingsModal, setOpenSettingsModal] = useState(false)

  const { data: watchlistData } = useFetchWatchlistQuery()

  const [addTicker] = useAddTickerToWatchlistMutation()
  const [removeTicker] = useRemoveTickerToWatchlistMutation()

  const watchList = watchlistData?.data ?? []

  const twelveMonthsAgo = new Date(
    new Date().setFullYear(new Date().getFullYear() - 1)
  )

  const earningsDate = new Date(
    getNearestDate(ticker?.earningsCalendar)
  ).toLocaleDateString('en-UK', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })
  const dividendDate = ticker?.historicDailyDividend?.[0]?.date

  const price = ticker?.dailyStockPrice[0].close
  const priceChange =
    ticker?.dailyStockPrice[0].close - ticker?.dailyStockPrice[1].close
  const pricePercentageChange =
    (priceChange / ticker?.dailyStockPrice[1].close) * 100

  const showRatios = useMediaQuery({ maxWidth: '700px' })

  // Data for stock summary
  const sections = [
    {
      name: 'Valuation ratios',
      metrics: [
        { name: 'P/E', value: `${ticker?.keyMetricsTTM?.[0]?.peRatioTTM?.toFixed(2)}` },
        {
          name: 'P/FCF',
          value: `${ticker?.keyMetricsTTM?.[0]?.pfcfRatioTTM?.toFixed(2)}`,
        }, // Update
        { name: 'P/B', value: `${ticker?.keyMetricsTTM?.[0]?.pbRatioTTM?.toFixed(2)}` },
      ],
    },
    {
      name: 'Margins (mrq)',
      metrics: [
        {
          name: 'Gross Profit',
          value: `${(ticker?.incomeStatementPerQuarter?.[0]?.grossProfitRatio * 100)?.toFixed(2)}`,
          suffix: '%',
        },
        {
          name: 'EBITDA',
          value: `${(ticker?.incomeStatementPerQuarter?.[0]?.ebitdaratio * 100)?.toFixed(2)}`,
          suffix: '%',
        },
        {
          name: 'Profit',
          value: `${(ticker?.incomeStatementPerQuarter?.[0]?.netIncomeRatio * 100)?.toFixed(2)}`,
          suffix: '%',
        },
        {
          name: 'FCF',
          value: `${(ticker?.freeCashFlowMarginMRQ * 100)?.toFixed(2)}`,
          suffix: '%',
        },
      ],
    },
    // Have a 5yr CAGR version of growth
    {
      name: 'Growth (ttm)',
      metrics: [
        { name: 'Revenue', value: `${ticker?.revenueGrowthTTM}`, suffix: '%' },
        { name: 'EBITDA', value: `${ticker?.ebitdaGrowthTTM}`, suffix: '%' },
        { name: 'Profit', value: `${ticker?.profitGrowthTTM}`, suffix: '%' },
      ],
    },
    {
      name: 'Cashflow (ttm)',
      metrics: [
        {
          name: 'FCF Yield',
          value: `${(ticker?.keyMetricsTTM?.[0]?.freeCashFlowYieldTTM)?.toFixed(2)}`,
          suffix: '%',
        },
        {
          name: 'FCF growth',
          value: `${ticker?.freeCashFlowGrowthTTM}`,
          suffix: '%',
        },
        {
          name: 'SBC adj FCF: 5Y CAGR',
          value: `${ticker?.stockBasedCompensationAdjustedFreeCashFlow5yrCAGR}`,
          suffix: '%',
        },
        {
          name: 'SBC adj FCF: 10Y CAGR',
          value: `${ticker?.stockBasedCompensationAdjustedFreeCashFlow10yrCAGR}`,
          suffix: '%',
        },
      ],
    },
    {
      name: 'Effectiveness (ttm)',
      metrics: [
        {
          name: 'ROIC',
          value: `${(ticker?.keyMetricsTTM?.[0]?.roicTTM)?.toFixed(2)}`,
          suffix: '%',
        },
        {
          name: 'ROCE',
          value: `${(ticker?.financialRatiosTTM?.[0]?.returnOnCapitalEmployedTTM)?.toFixed(2)}`,
          suffix: '%',
        },
        {
          name: 'ROA',
          value: `${(ticker?.financialRatiosTTM?.[0]?.returnOnAssetsTTM)?.toFixed(2)}`,
          suffix: '%',
        },
        {
          name: 'ROE',
          value: `${(ticker?.financialRatiosTTM?.[0]?.returnOnEquityTTM)?.toFixed(2)}`,
          suffix: '%',
        },
      ],
    },
    {
      name: 'Liquidity & Solvency',
      metrics: [
        {
          name: 'Current Ratio',
          value: `${ticker?.keyMetricsTTM?.[0]?.currentRatioTTM?.toFixed(2)}`,
        }, // (mrq)
        {
          name: 'Net Debt to EBITDA',
          value: `${ticker?.keyMetricsTTM?.[0]?.netDebtToEBITDATTM?.toFixed(2)}`,
        }, // (ttm)
        {
          name: 'Debt Growth',
          value: `${(ticker?.financialGrowthPerQuarter?.[0]?.debtGrowth)?.toFixed(2)}`,
          suffix: '%',
        }, // (mrq)
      ],
    },
    {
      name: 'Dividends',
      metrics: [
        { name: 'Yield', value: `${(ticker?.financialRatiosTTM?.[0]?.dividendYieldTTM)}`, suffix: '%' },
        {
          name: 'Growth',
          value: `${(ticker?.financialGrowth?.[0]?.dividendsperShareGrowth)?.toFixed(2)}`,
          suffix: '%',
        },
        {
          name: 'Payout Ratio',
          value: `${(ticker?.financialRatiosTTM?.[0]?.payoutRatioTTM)?.toFixed(2)}`,
          suffix: '%',
        },
      ],
    },
  ]

  // Disable scrolling when modal is open
  useEffect(() => {
    document.body.style.overflow = openCompanyInfoModal ? 'hidden' : 'auto'
  }, [openCompanyInfoModal])

  // Add the stock to the local state of watchList
  const handleUpdateWatchList = (ticker) => {
    addTicker({ ticker }).catch((err) => console.error(err))
  }

  // Remove the stock from the local state of watchList
  const removeStockFromWatchList = (stockSymbol) => {
    removeTicker({ ticker: stockSymbol }).catch((err) => console.error(err))
  }

  function handleImageLoad() {
    setImageLoaded(true)
  }

  return (
    <ContentContainer
      Header={'Overview'}
      hasExpand={false}
      maxWidth={'2500px'}
      minHeight={'100px'}
      Icons={
        <>
          <IconButtons
            action={() => setOpenCompanyInfoModal(true)}
            icon={<BsInfoSquare />}
            text={'Company Info'}
          />
          <CompanyInfo
            open={openCompanyInfoModal}
            closeModal={() => {
              setOpenCompanyInfoModal()
            }}
            icon={<BsInfoSquare />}
            symbol={symbol}
          />
          {/* <IconButtons
            action={() => {
              setOpenShareModal(true)
            }}
            icon={<HiOutlineShare />}
            text={'Share'}
          />
          <ShareModal
            open={openShareModal}
            close={() => {
              setOpenShareModal()
            }}
            icon={<HiOutlineShare />}
            header="Share"
          /> */}
          {/* <IconButtons 
            action={() => {
              setOpenNotificationsModal(true)
            }} 
            icon={<IoNotificationsOutline/>} 
            text={'Notifications'}
          />
          <FiltersModal
            open={openNotificationsModal}
            close={() => {
              setOpenNotificationsModal()
            }}
            type="settings"
            header="Notifications"
            icon={<IoNotificationsOutline />}
            callToAction={<div>Select notifications</div>}
            setSelectedFilters={setSelectedFilters}
            setScreenerResults={setScreenerResults}
            selectedFilters={selectedFilters}
          /> */}
          {!watchList.includes(ticker?.symbol) ? (
            <IconButtons
              action={() => handleUpdateWatchList(ticker?.symbol)}
              icon={<BsBookmarkHeart />}
              text={'Watch List'}
            />
          ) : (
            <IconButtons
              action={() => removeStockFromWatchList(ticker?.symbol)}
              icon={<BsBookmarkX />}
              text={'Watch List'}
            />
          )}
          <IconButtons
            action={() => {
              setOpenCompareModal(true)
            }}
            icon={<MdAutoGraph/>}
            text={'Compare'}
          />
          <CompareModal
            open={openCompareModal}
            close={() => {
              setOpenCompareModal()
            }}
            icon={<MdAutoGraph/>}
            header="Compare"
          />
          <IconButtons
            action={() => {
              setOpenSettingsModal(true)
            }}
            icon={<BsToggles/>}
            text={'Graph settings'}
          />
          <GraphSettingsModal
            open={openSettingsModal}
            close={() => {
              setOpenSettingsModal()
            }}
            icon={<BsToggles/>}
            header="Graph settings"
          />
        </>
      }
      Content={
        <StockSummaryContainer>
          {isFetching ? (
            <div className="w-full flex justify-center items-center h-[92px]">
              <BoxAnimation/>
            </div>
          ) : (
            <>
              <CompanyInfoContainer>
                <CompanyLogoContainer>
                  <img
                    src={ticker?.profile?.logo}
                    width="50"
                    height="50"
                    className={imageLoaded ? 'm-2' : 'm-2 hidden'}
                    onLoad={handleImageLoad}
                    onError={() => setImageLoaded(false)}
                    style={{
                      display: imageLoaded ? 'block' : 'none',
                      filter: 'drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.4))',
                      borderRadius: '3px',
                    }}
                    alt="stock logo"
                    type="image/png"
                  />
                  {!imageLoaded && (
                    <Avatar
                      sx={{
                        bgcolor: '#5f8799',
                        color: '#FFFFFF',
                        width: '64px',
                        height: '64px',
                        fontSize: '32px',
                      }}
                    >
                      {ticker?.symbol
                        ? ticker?.symbol[0]?.toLocaleUpperCase()
                        : 'A'}
                    </Avatar>
                  )}
                </CompanyLogoContainer>
                <CompanyInfoText>
                  <div className="text-left text-textMuted flex flex-row">
                    <span className="text-textBase mr-1 font-semibold min-w-fit">
                      {ticker?.symbol}{' '}
                    </span>
                    {ticker?.symbol && ticker?.profile?.companyName && (
                      <span className="text-textBase mr-1 font-semibold">: </span>
                    )}
                    <div className='truncate max-w-[250px] w-[calc(100vw_-_210px)]'>
                      {/* {ticker?.companyName?.length > 30
                        ? `${ticker?.companyName.slice(0, 30)}...`
                        : ticker?.companyName} */}
                      {ticker?.profile?.companyName}
                    </div>
                  </div>
                  {renderLoadingOrContent(
                    <StockPriceContainer>
                      <StockPrice>
                        {numeral(price).format('$0,0.00')}
                      </StockPrice>
                      <ValueChangeText
                        value={priceChange}
                        currencyValue={priceChange}
                        percentage={pricePercentageChange}
                      />
                    </StockPriceContainer>,
                    !ticker?.profile?.price
                  )}
                  <SummaryText>
                    <span className="text-textBase mr-2">Earnings:</span>{' '}
                    <span className="text-textMuted">{earningsDate}</span>
                  </SummaryText>
                  <SummaryText>
                    <span className="text-textBase mr-2">Dividends:</span>
                    <span className="text-textMuted">
                      {dividendDate && new Date(dividendDate) >= twelveMonthsAgo
                        ? new Date(dividendDate).toLocaleDateString('en-UK', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                          })
                        : 'N/A'}
                    </span>
                  </SummaryText>
                </CompanyInfoText>
              </CompanyInfoContainer>
              {!showRatios && 
                <>
                  <div className='bg-neutral h-[90px] w-[1px]'/>
                  <Slider>
                    {sections.map((section, index) => (
                      <TableContainer key={index}>
                        <OverviewTable data={section} minWidth={'135'} />
                      </TableContainer>
                    ))}
                  </Slider>
                </>
              }
            </>
          )}
        </StockSummaryContainer>
      }
    ></ContentContainer>
  )
}
