import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const stockAnalysisAPi = createApi({
  reducerPath: 'stockAnalysisAPi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      headers.set('authorization', token ? `Bearer ${token}` : '')
      return headers
    },
  }),
  providesTags: [
    'fundamental',
    'fundamentalTimeHorizon',
    'fundamentalPeriodType',
    'Ticker',
    'PriceChart',
  ],
  endpoints: (builder) => ({
    getFundamental: builder.query({
      query: (args) => `/stocks/fundamental/${args.symbol}/${args.period}`,
      providesTags: (result) => [
        { type: 'fundamental', symbol: result?.symbol, period: result?.period },
      ],
    }),
    getFundamentalTimeHorizon: builder.query({
      query: () => `/preferences/fundamentalTimeHorizon/get`,
      providesTags: ['fundamentalTimeHorizon'],
    }),
    getFundamentalPeriodType: builder.query({
      query: () => `/preferences/fundamentalPeriodType/get`,
      providesTags: ['fundamentalPeriodType'],
    }),
    getPriceChartData: builder.query({
      query: ({ symbol, interval, fromDate, toDate, timeframe }) => ({
        url: `/stocks/priceChart`,
        method: 'GET',
        params: { symbol, interval, fromDate, toDate, timeframe },
      }),
      providesTags: (result, _, args) => [
        {
          type: 'PriceChart',
          symbol: result?.symbol,
          fromData: args.fromDate,
          toData: args.toDate,
          interval: args.interval,
          timeframe: args.timeframe,
        },
      ],
    }),
    getStockData: builder.query({
      query: (ticker) => `/stocks/search/${ticker}`,
      providesTags: (result) => [{ type: 'Ticker', id: result?.symbol }],
    }),
    getHistoricEmployeeCount: builder.query({
      query: (symbol) => `/stocks/employeeCount/${symbol}`,
      providesTags: (_, __, args) => [`EmployeeCount/${args.symbol}` ],
    }),
    getTotalUsers: builder.query({
      query: () => `misc/totalUsers`,
    }),
    fetchStockScreener: builder.query({
      keepUnusedDataFor: 600,
      query: ({
        searchCriteria,
        tableMetrics,
        page,
        sortField,
        sortOrder,
        tableRows,
      }) => ({
        url: '/screener/fetch',
        body: {
          searchCriteria,
          tableMetrics,
          page,
          sortField,
          sortOrder,
          tableRows,
        },
        method: 'POST',
      }),
    }),
    getUserScreenerSettings: builder.query({
      keepUnusedDataFor: 600,
      query: () => `/preferences/screenerSettings`,
      providesTags: ['SCREENER_SETTINGS'],
    }),
    updateUserScreenerSettings: builder.mutation({
      query: ({ screenerFilters }) => ({
        url: '/preferences/screenerSettings',
        method: 'POST',
        body: { screenerFilters },
      }),
      invalidatesTags: ['SCREENER_SETTINGS'],
    }),
    fetchWatchlistData: builder.query({
      keepUnusedDataFor: 600,
      query: ({ page, sortField, sortOrder, tableRows, tableMetrics }) => ({
        url: '/watchlist/postData',
        body: { page, sortField, sortOrder, tableRows, tableMetrics },
        method: 'POST',
      }),
      providesTags: ['WATCHLIST_DATA'],
    }),
    fetchWatchlist: builder.query({
      keepUnusedDataFor: 600,
      providesTags: ['WATCHLIST_SYMBOLS'],
      query: () => ({
        url: '/watchlist',
        method: 'GET',
      }),
    }),
    addTickerToWatchlist: builder.mutation({
      invalidatesTags: ['WATCHLIST_SYMBOLS', 'WATCHLIST_DATA'],
      query: ({ ticker }) => ({
        url: '/watchList/addData',
        body: { ticker },
        method: 'POST',
      }),
    }),
    removeTickerToWatchlist: builder.mutation({
      invalidatesTags: ['WATCHLIST_SYMBOLS', 'WATCHLIST_DATA'],
      query: ({ ticker }) => ({
        url: '/watchList/remove',
        body: { ticker },
        method: 'POST',
      }),
    }),
    getPortfolioData: builder.query({
      query: () => `/portfolio/getPortfolioData`,
      providesTags: ['Portfolio'],
    }),
    addCashTransaction: builder.mutation({
      invalidatesTags: ['Portfolio'],
      query: (transaction) => ({
        url: '/portfolio/cashTransaction',
        body: transaction,
        method: 'POST',
      }),
    }),
    addTickerTransaction: builder.mutation({
      invalidatesTags: ['Portfolio'],
      query: (transaction) => ({
        url: '/portfolio/tradeStock',
        body: transaction,
        method: 'POST',
      }),
    }),
    fetchEarningsOrDividends: builder.query({
      query: ({ category, from, to }) => `/calendar/${category}/${from}-${to}`,
      providesTags: (_, __, args) => [args.category, args.from, args.to],
    }),
    fetchStockNews: builder.query({
      query: (symbol) => `/stockNews/${symbol}`,
      providesTags: (_, __, args) => [`StockNews/${args.symbol}` ],
    }),
    fetchEarningCalls: builder.query({
      query: (symbol) => `/earningcalls/${symbol}`,
      providesTags: (_, __, args) => [`EarningCalls/${args.symbol}` ],
    }),
    fetchEarningCallsDetails: builder.query({
      query: ({symbol, year, quarter}) => `/earningcalls/${symbol}/${year}-${quarter}`,
      providesTags: (_, __, args) => [`EarningCallDetails/${args.symbol}/${args.year}/${args.quarter}`]
    }),
    fetchInsiderTrading: builder.query({
      query: (symbol) => `/insiderTrading/${symbol}`,
      providesTags: (_, __, args) => [`InsiderTrading/${args.symbol}` ],
    }),
  }),
})

export const {
  useGetFundamentalQuery,
  useGetPriceChartDataQuery,
  useGetStockDataQuery,
  useGetHistoricEmployeeCountQuery,
  useGetTotalUsersQuery,
  useFetchStockScreenerQuery,
  useGetUserScreenerSettingsQuery,
  useUpdateUserScreenerSettingsMutation,
  useFetchWatchlistDataQuery,
  useFetchWatchlistQuery,
  useAddTickerToWatchlistMutation,
  useRemoveTickerToWatchlistMutation,
  useGetPortfolioDataQuery,
  useAddCashTransactionMutation,
  useAddTickerTransactionMutation,
  useFetchEarningsOrDividendsQuery,
  useFetchStockNewsQuery,
  useFetchEarningCallsQuery,
  useFetchEarningCallsDetailsQuery,
  useFetchInsiderTradingQuery,
} = stockAnalysisAPi
