// External imports
import styled from 'styled-components'
import tw from 'twin.macro'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

// Internal imports
import { Modal } from '..'
import { FormButton } from '../buttons/formButton'
import { getCustomerCards } from '../../actions/payment'
import { setTrialBefore } from '../../api'

// Styling
const CardContainer = styled.div`
  ${tw`w-full h-[fit-content] flex flex-col justify-center items-center rounded`};
`

export const PaymentDetailsCard = ({
  buttonText,
  successfulPayment,
  secondaryButtonText,
  secondarySuccessfulPayment,
  isUpdating,
  setupSecret,
  successfulUpdate,
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const [message, setMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false)

  useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    )
    if (!clientSecret) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!')
          break
        case 'processing':
          setMessage('Your payment is processing.')
          break
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.')
          break
        default:
          setMessage('Something went wrong.')
          break
      }
    })
  }, [stripe])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!stripe || !elements) {
      return
    }

    setIsLoading(true)

    const data = await stripe.confirmSetup({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // return_url: `${process.env.REACT_APP_URL}/account/payments-and-packages`,
      },
      redirect: 'if_required',
    })

    const { error } = data

    if (error) {
      if (error.type === 'card_error' || error.type === 'validation_error') {
        setMessage(error.message)
      } else {
        setMessage('An unexpected error occurred.')
      }
      setIsLoading(false)
    } else {
      if (!isUpdating) {
        dispatch(getCustomerCards())
          .then(async (card) => {
            await setTrialBefore()
          })
          .catch((err) => console.error(err))
          .finally(() => {
            successfulPayment()
            
            setIsLoading(false)
          })
      } else {
        //retrieve set up intent
        stripe
          .retrieveSetupIntent(setupSecret)
          .then(({ setupIntent }) => {
            dispatch(getCustomerCards()).then(async (res) => {
              // await setTrialBefore();
              if (res) {
                successfulUpdate()
              }
            })
          })
          .catch((err) => console.error(err))
          .finally(() => setIsLoading(false))

          // Reddit Pixel Event: Track successful payment
          if (window.rdt) {
            window.rdt('track', 'SignUp', {
              currency: 'AUD',
              value: 9.0,
            });
          }
      }
    }
  }

  const paymentElementOptions = {
    layout: 'tabs',
    paymentMethodOrder: ['card'],
  }

  return (
    <CardContainer>
      <form id="payment-form" className='w-full'>
        <div className="mb-4">
          <PaymentElement
            id="payment-element"
            options={paymentElementOptions}
          />
        </div>
        <div style={{ display: 'flex' }}>
          {secondaryButtonText && (
            <div style={{ marginRight: '10px', width: '100%' }}>
              <FormButton
                isDisabledisabled={isLoading || !stripe || !elements}
                isNegative={true}
                text={secondaryButtonText}
                onClick={() => setShowCancelConfirmation(true)}
                type="button"
              />
            </div>
          )}
          <FormButton
            isDisabledisabled={isLoading || !stripe || !elements}
            isSecondary={true}
            isLoading={isLoading}
            text={buttonText}
            onClick={handleSubmit}
          />
        </div>
        {message && <div id="payment-message">{message}</div>}
      </form>
      <Modal
        open={showCancelConfirmation}
        isDismissable={false}
        isCloseable={false}
        headerContent={'Confirm'}
        content={
          <>
            <div className="flex flex-col items-center justify-center pb-5">
              <div className="px-2 py-4 text-center mb-5">
                <p className="font-semibold text-[#3498b8]">
                  Are you sure you want cancel?
                </p>
                <span className="flex justify-center">
                  <p className="mt-3 w-96">
                    Card details will not be saved.
                  </p>
                </span>
              </div>
              <div className="flex space-x-2">
                <FormButton
                  text="Yes"
                  onClick={() => secondarySuccessfulPayment()}
                  width="150px"
                />
                <FormButton
                  text="No"
                  isNegative={true}
                  onClick={() => setShowCancelConfirmation(false)}
                  width="150px"
                />
              </div>
            </div>
          </>
        }
        width={'fit-content'}
      />
    </CardContainer>
  )
}
