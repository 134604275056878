// External imports
import { useAuth0 } from '@auth0/auth0-react'
import { FormButton } from '../../components'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { resendVerificationCode } from '../../api'
import { PuffLoader } from 'react-spinners'
import { useMediaQuery } from 'react-responsive';

// Internal imports
import { Emailbox } from '../../assets';

const AfterSignup = () => {
  const { user, logout } = useAuth0()
  const navigate = useNavigate()
  const [wait, setWait] = useState(false)
  const isHorizontal = useMediaQuery({ maxHeight: '470px' })

  if (user?.email_verified) navigate('/stockanalysis')

  const backToSignup = () => {
    if (user) {
      logout({ logoutParams: { returnTo: `${process.env.REACT_APP_URL}` } })
    } else {
      navigate(`${process.env.REACT_APP_URL}`)
    }
  }

  const resendEmail = () => {
    if (user) {
      setWait(true)
      setTimeout(() => setWait(false), 15000)
      resendVerificationCode(user.sub)
        .then()
        .catch((err) => console.log(err))
    }
  }

  return (
    <div className="w-full h-[100vh] flex justify-center items-center">
      <div className="w-[300px] max-w-[95vw] max-h-[95vh] h-fit bg-primaryBg text-textBase text-sm flex flex-col justify-center items-center p-4 rounded space-y-4 border-1 border-neutralBg shadow-lg">
        <div className="w-full flex flex-row items-center justify-center text-2xl font-semibold text-center">
          Verify your email
        </div>
        <div className="flex flex-col justify-center items-center ">
          <p className="text-textMuted text-center">
            Verify your email to complete your registration.
          </p>
          {!isHorizontal ? 
            (
              <div className='my-0'>
                <img src={Emailbox} alt="email inbox" type="image/svg" width={250} draggable={false}/>
              </div>
            ) : (null)
          }
          <p className={`text-textMuted flex text-center text-xs ${isHorizontal ? `pt-3` : ``}`}>
            An email has been sent to your email address with a link to verify your account.
            Check your spam folder if you have not received an email after a few minutes.
          </p>
        </div>
        <div className="flex justify-around w-full space-x-2">
          {!wait && 
            <FormButton
              text={'Resend email'}
              width={'full'}
              isSecondary={true}
              secondaryStyle
              onClick={resendEmail}
            />
          }
          {
            wait && <FormButton
            text={<PuffLoader size={20} />}
            width={'full'}
          />
          }
          <FormButton text={'Log in'} width={'full'} onClick={backToSignup} />
        </div>
      </div>
    </div>
  )
}

export default AfterSignup