// External Imports
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import tw from 'twin.macro'
import { BsListUl, BsFillGrid3X3GapFill } from 'react-icons/bs'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import { Avatar } from '@mui/material'
import { useMediaQuery } from 'react-responsive'
import dayjs from 'dayjs'

// Internal Imports
import { ContentContainer, IconButtons, TextOptionSelector, SingleSelectDropdown, BoxAnimation } from '../../components'
import { addToRecentSearchList } from '../../actions/searchLists'
import { deviceSize } from '../../data/devices'
import { useFetchEarningsOrDividendsQuery } from '../../rtk'

// Styling
const Container = styled.div` ${tw`w-full flex flex-col items-center p-6`} `
const CompanyContainer = styled.div` 
  ${tw`flex items-center w-full h-[fit-content] bg-neutralBg rounded font-semibold text-xs text-textMuted border-1 border-neutralBg transition-transform duration-300 ease-in-out`}
  ${tw`hover:cursor-pointer hover:shadow-md hover:text-textBase hover:border-blue-500`}

  &:hover {transform: translateY(-2px)}
`;

const ButtonContainer = styled.div`
  ${tw`flex text-center justify-center rounded border-1 border-textMuted cursor-pointer text-textMuted text-xs font-semibold p-2 mx-2 mb-2`};
  ${tw`hover:text-textBase hover:shadow-md`};
`

const WeekContainer = styled.div`
  ${tw`flex w-full min-h-[800px] border-1 border-neutral divide-neutral`};
  @media (min-width: 1600px) { 
    ${tw`flex-row divide-x-1`} 
  }
  @media (max-width: 1599px) { 
    ${tw`flex-col divide-y-1`} 
  }
`

const CompanyGrid = styled.div`
  ${tw`grid gap-2 p-2 min-h-[100px]`};
  @media (min-width: 2000px) {
    ${(props) => props.layout === 'grid' ? tw`grid-cols-3` : tw`grid-cols-1`};
  }
  @media (max-width: 1999px) {
    ${(props) => props.layout === 'grid' ? tw`grid-cols-2` : tw`grid-cols-1`};
  }
  @media (max-width: 1599px) {
    ${(props) => props.layout === 'grid' ? tw`grid-cols-10` : tw`grid-cols-5`};
  }
  @media (max-width: 1099px) {
    ${(props) => props.layout === 'grid' ? tw`grid-cols-8` : tw`grid-cols-4`};
  }
  @media (max-width: 899px) {
    ${(props) => props.layout === 'grid' ? tw`grid-cols-5` : tw`grid-cols-3`};
  }
  @media (max-width: 699px) {
    ${(props) => props.layout === 'grid' ? tw`grid-cols-4` : tw`grid-cols-2`}
  }
  @media (max-width: 599px) {
    ${(props) => props.layout === 'grid' ? tw`grid-cols-3` : tw`grid-cols-2`}
  }
  @media (max-width: 499px) {
    ${(props) => props.layout === 'grid' ? tw`grid-cols-2` : tw`grid-cols-2`}
  }
`

const Company = ({ symbol, eps, epsEstimated, dividend, layout }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [imageLoaded, setImageLoaded] = useState(false)

  const navigateToStockAnalysis = (stockSymbol) => {
    dispatch(addToRecentSearchList(stockSymbol))
    navigate('/stockanalysis')
  }

  function handleImageLoad() { setImageLoaded(true) }

  return (
    <CompanyContainer
      className={`${ layout === 'grid' ? 'flex-col p-2' : 'flex-row' }`}
      onClick={() => navigateToStockAnalysis(symbol)}
    >
      <div
        className={`
          flex items-center justify-center p-1 rounded
          ${layout === 'grid' ? 'w-[80px] h-[65px]' : 'w-[40px] h-[40px] mr-2'} 
        `}
      >
        <img
          src={`https://financialmodelingprep.com/image-stock/${symbol}.png`}
          width={layout === 'grid' ? '40' : '20'}
          height={layout === 'grid' ? '40' : '20'}
          className={imageLoaded ? '' : 'hidden'}
          onLoad={handleImageLoad}
          onError={() => setImageLoaded(false)}
          style={{
            display: imageLoaded ? 'block' : 'none',
            filter: 'drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.4))',
            borderRadius: '3px',
          }}
          alt="stock logo"
          type="image/png"
        />
        {!imageLoaded && (
          <Avatar
            sx={{
              bgcolor: '#5f8799',
              color: '#FFFFFF',
              width: `30px`,
              height: `30px`,
              fontSize: '15px',
            }}
          >
            {symbol[0]?.toLocaleUpperCase()}
          </Avatar>
        )}
      </div>
      {layout === 'list' && (
        <div className="w-full flex flex-row justify-between items-center pr-2">
          <span>{symbol}</span> 
          <div className='flex flex-row text-textMuted'>
            {epsEstimated && (
              <div 
                className={`font-semibold text-xs
                ${epsEstimated > 0 ? 'text-textPositive' : epsEstimated < 0 ? 'text-textNegative' : 'text-textMuted'}
                `}
              >
                {Number(epsEstimated).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </div>
            )}
            {dividend && (
              <div className='text-textMuted font-semibold text-xs'>
                {Number(dividend).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </div>
            )}
          </div>
          
        </div>
      )}
      {layout === 'grid' && (
        <span> {symbol} </span>
      )}
    </CompanyContainer>
  )
}

export const Calendar = () => {
  const isMobile = useMediaQuery({ maxWidth: deviceSize.mobile })
  const showToggles = useMediaQuery({ maxWidth: '600px' })
  const [displayedData, setDisplayedData] = useState('Earnings')
  const [layout, setLayout] = useState('grid')
  const [earningsOrDividends, setEarningsOrDividends] = useState([])
  const [currentWeekStartDate, setCurrentWeekStartDate] = useState(dayjs().startOf('week'))
  const thisWeek = dayjs().startOf('week').isSame(currentWeekStartDate, 'week')
  const minItemsPerColumn = 24
  const [showMoreEarnings, setShowMoreEarnings] = useState({})
  const [showMoreDividends, setShowMoreDividends] = useState({})

  const { data, isFetching } = useFetchEarningsOrDividendsQuery({
    category: displayedData === 'Earnings' ? 'earnings' : 'dividends',
    from: currentWeekStartDate.format('YYYY,MM,DD'),
    to: currentWeekStartDate.add(6, 'day').format('YYYY,MM,DD'),
  })

  useEffect(() => {
    if (data?.data) setEarningsOrDividends(data.data)
  }, [data])

  const handleShowMore = (date, dataType) => {
    if (dataType === 'Earnings') {
      setShowMoreEarnings((prev) => ({ ...prev, [date]: !prev[date] }))
    } else if (dataType === 'Dividends') {
      setShowMoreDividends((prev) => ({ ...prev, [date]: !prev[date] }))
    }
  }

  const showMoreData = (date) => {
    return displayedData === 'Earnings' ? showMoreEarnings[date] : showMoreDividends[date];
  }

  const toggleLayout = () => setLayout(layout === 'grid' ? 'list' : 'grid')

  const daysOfWeek = Array.from({ length: 7 }, (_, i) => currentWeekStartDate.add(i, 'day').format('YYYY-MM-DD'));

  const handleToday = () => setCurrentWeekStartDate(dayjs().startOf('week'));
  const handlePreviousWeek = () => setCurrentWeekStartDate(currentWeekStartDate.subtract(1, 'week'));
  const handleNextWeek = () => setCurrentWeekStartDate(currentWeekStartDate.add(1, 'week'));

  const groupDataByDay = (data) => {
    return data.reduce((acc, item) => {
      const date = dayjs(item.date).format('YYYY-MM-DD');
      acc[date] = acc[date] ? [...acc[date], item] : [item];
      return acc;
    }, {});
  };

  const calendarData = groupDataByDay(earningsOrDividends)

  const onSetDisplyedData = (val) => {
    setEarningsOrDividends([])
    setDisplayedData(val)
  }

  return (
    <Container>
      <ContentContainer
        Header={'Calendar'}
        maxWidth={'2500px'}
        Toggle={
          <div className="flex flex-row items-center space-x-2">
            <IconButtons
              icon={<FaAngleLeft className="text-xs" />}
              action={handlePreviousWeek}
            />
            {!isMobile && (
              <IconButtons
                displayText={true}
                text="Today"
                isActive={thisWeek}
                action={handleToday}
              />
            )}
            <IconButtons
              icon={<FaAngleRight className="text-xs" />}
              action={handleNextWeek}
            />
          </div>
        }
        Icons={
          <>
            {!showToggles && (
              <>
                <div>
                  <TextOptionSelector
                    options={['Earnings', 'Dividends']}
                    selection={displayedData}
                    setSelection={onSetDisplyedData}
                    isDispatchable={false}
                  />
                </div>
                <div className="flex flex-row pl-2 space-x-1 border-l-1 border-neutral">
                  <IconButtons
                    icon={
                      layout === 'grid' ? <BsListUl/> : <BsFillGrid3X3GapFill /> 
                    }
                    action={toggleLayout}
                  />
                </div>
              </>
            )}
            {showToggles && (
              <SingleSelectDropdown
                setSelected={onSetDisplyedData}
                dropDownOptions={['Earnings', 'Dividends']}
                width={'100px'}
                useParentInput={true}
                parentInput={displayedData}
              />
            )}
          </>
        }
        Content={
          <WeekContainer>
            {daysOfWeek.map((date, index) => (
              <div key={index} className="flex flex-col w-full">
                <div
                  className={`border-b-4 text-sm p-2 pt-2.5 space-x-1 bg-neutralBg
                    ${ dayjs(date).isSame(dayjs(), 'day') ? 'border-[#3498b8]' : 'border-neutral' } 
                  `}
                >
                  <span className="font-semibold text-textBase">
                    {dayjs(date).format('ddd')}
                  </span>
                  <span className="font-semibold text-textMuted">
                    {dayjs(date).format('- DD MMM')}
                  </span>
                </div>
                {calendarData[date]?.length > 0 ? (
                  <CompanyGrid layout={layout}>
                    {calendarData[date]
                      ?.slice(
                        0,
                        displayedData === 'Earnings'
                          ? showMoreEarnings[date]
                            ? calendarData[date].length
                            : minItemsPerColumn
                          : showMoreDividends[date]
                          ? calendarData[date].length
                          : minItemsPerColumn
                      )
                      .map((stock, index) => (
                        <Company
                          key={index}
                          symbol={stock.symbol}
                          eps={stock.eps}
                          epsEstimated={stock.epsEstimated}
                          dividend={stock.dividend}
                          layout={layout}
                        />
                      ))}
                  </CompanyGrid>
                ) : (
                  <div className="w-full h-[120px] flex items-center justify-center text-textMuted font-semibold">
                    {!isFetching ? (
                      <h1>No data to display</h1>
                    ) : (
                      <BoxAnimation/>
                    )}
                  </div>
                )}
                {calendarData[date]?.length > minItemsPerColumn && (
                  <ButtonContainer onClick={() => handleShowMore(date, displayedData)}>
                    {showMoreData(date) ? 'Show less' : 'Show more'}
                  </ButtonContainer>
                )}
              </div>
            ))}
          </WeekContainer>
        }
      />
    </Container>
  )
}