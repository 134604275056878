// External imports
import React, { useEffect, useCallback, useRef } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import tw from 'twin.macro'
import { MdOutlineClose } from 'react-icons/md'
import { useSelector } from 'react-redux'
import FocusTrap from 'focus-trap-react'

// Internal imports
import { IconButtons } from '../buttons/iconButtons'

// Styling
const Overlay = styled.div`
  ${tw`fixed top-[0px] bottom-[0px] left-[0px] right-[0px] z-[25] flex justify-center items-center select-none p-10 cursor-pointer bg-overlayBgOpaque`};
  backdrop-filter: blur(8.5px);
  ${({ verticalPosition }) => verticalPosition && `align-items: flex-start; padding-top: ${verticalPosition};`}
`
const ModalContainer = styled.div`${tw`w-[100%] h-[fit-content] flex flex-col items-start justify-center p-2 rounded cursor-default bg-primaryBg text-textBase border-1 border-textBase`}`
const Header = styled.span`${tw`w-[100%] flex flex-row items-center justify-between text-xl font-semibold border-b-1 pb-2`}`
const HeaderLeft = styled.span`${tw`flex flex-row`}`
const GraphContainer = styled.div`${tw`w-[100%] h-[100%] flex flex-col items-center justify-center`}`
const Footer = styled.span`${tw`w-full text-xl font-semibold pt-2 mt-2 border-t-1 border-neutral tracking-wide`}`

export const Modal = ({
  open,
  closeModal,
  headerContent,
  content,
  footerContent,
  width,
  isDismissable = true,
  isCloseable = true,
  height = '',
  maxWidth = null,
  maxHeight = null,
  verticalPosition = null,
}) => {
  const { theme } = useSelector((state) => state.userPreferenceReducer)
  const modalRef = useRef(null)

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }

    return () => {
      document.body.style.overflow = ''
    }
  }, [open])

  const handleEscape = useCallback(
    (event) => {
      if (event.key === 'Escape' && open) {
        closeModal(false)
      }
    },
    [open, closeModal]
  )

  useEffect(() => {
    if (open) {
      document.addEventListener('keydown', handleEscape)
    } else {
      document.removeEventListener('keydown', handleEscape)
    }

    return () => {
      document.removeEventListener('keydown', handleEscape)
    }
  }, [open, handleEscape])

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        isDismissable
      ) {
        closeModal()
      }
    }
    if (open) {
      document.addEventListener('mousedown', handleOutsideClick)
    } else {
      document.removeEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [open, isDismissable, closeModal])

  if (!open) return null

  return ReactDOM.createPortal(
    <Overlay className={theme === 'light' ? 'theme-light' : 'theme-dark'} verticalPosition={verticalPosition}>
      <FocusTrap>
        <ModalContainer
          ref={modalRef}
          onClick={(e) => e.stopPropagation()}
          style={{
            width: width,
            height: height,
            maxWidth: maxWidth,
            maxHeight: maxHeight,
          }}
        >
          <Header>
            <HeaderLeft>{headerContent}</HeaderLeft>
            {isCloseable && (
              <IconButtons
                action={() => closeModal(false)}
                icon={<MdOutlineClose />}
              />
            )}
          </Header>
          <GraphContainer>{content}</GraphContainer>
          {footerContent && 
            <Footer>{footerContent}</Footer>
          }
        </ModalContainer>
      </FocusTrap>
    </Overlay>,
    document.getElementById('portal')
  )
}
