// External imports
import React from 'react';
import styled from "styled-components";
import tw from "twin.macro";
import { IoClose } from "react-icons/io5";

// Internal imports
import { metricOptions } from '../../data/metricOptions';

// Styling
const Divider = styled.div`${tw`min-h-[16px] min-w-[1px] w-[1px] bg-neutral`}`;

export const SelectedCriteriaContainer = ({ name, operator, value, index, removeFilter }) => {

  const metricDetails = metricOptions.find((metric) => metric.displayedText === name)

  const addThousandSeparators = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const MetricLogic = (comparator) => {
    switch (comparator) {
      case 'Greater than':
        return '>';
      case 'Less than':
        return '<';
      default:
        return '===';
    }
  };

  const convertedOperator = MetricLogic(operator);

  return (
    <div className='relative'>
      <div className='absolute -inset-0.5 bg-gradient-to-r from-pink-600 to-purple-600 rounded blur-sm opacity-[0.15]'/>
    
      <div key={index} className='min-h-[34px] h-fit flex flex-row items-center justify-center rounded cursor-auto p-2 space-x-2 text-xs shadow-md bg-primaryBg border-1 border-neutral relative'>
        
        <span className='font-semibold text-textBase w-fit flex-wrap'>{name}</span>
        <Divider/>
        <span className='text-textMuted w-fit flex-none'>is</span>
        <Divider/>
        <span className='text-textMuted w-fit flex-none'>{convertedOperator}</span>
        <Divider/>
        <span className='min-w-fit'>
          {metricDetails && metricDetails.prefix && (<label className='text-textMuted'>{metricDetails.prefix} </label>)}
          <span className='text-textBase font-semibold'>{addThousandSeparators(value)}</span>
          {metricDetails && metricDetails.suffix && (<label className='text-textMuted '> {metricDetails.suffix}</label>)}
        </span>
        <Divider/>
        <div className='w-[14px]'>
          <button className='w-[14px] flex items-center justify-center text-textMuted hover:text-textBase text-xs hover:text-sm font-semibold hover:cursor-pointer' onClick={() => removeFilter(index)}>
            <IoClose/>
          </button>
        </div>
      </div>
    </div>
  )
};