// External imports
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Internal imports
import { Modal } from '..';

export const PlanRestrictionModal = ({ open, close, icon, header, ticker }) => {
  const navigate = useNavigate();

  const doNavigate = () => {
    navigate('/account/payments-and-packages')
  }


  return (
    <Modal
      open={open} 
      closeModal={close}  
      headerContent={<span className='flex flex-row items-center'>
        <h1 className='ml-2'>
          Restricted
        </h1></span>}
      width='400px'
      content={
      <div>
        <p className='p-8'>
          You have reached your maximum searches. Upgrade your plan to get unlimited search.
        </p>
        <button 
          className="w-full text-[#ffffff] bg-[#3498b8] border-[#3498b8] p-2 rounded font-semibold"
          onClick={()=>{doNavigate()}}> Upgrade </button>
      </div>}>
    </Modal>
  )
}