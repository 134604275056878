import * as api from '../api';

export const createCustomer = () => async (dispatch) => {
  const { data } = await api.createCustomer();
  dispatch({ type: 'SET_CUSTOMER', payload: data });
  localStorage.setItem('customer', data);
  localStorage.setItem('stripeCustomerId', data.customerId)
  return data;
};

export const createSubscription = (subscriptionDetails) => async (dispatch) => {
  try {
    const {data} = await api.createSubscription(subscriptionDetails);
    dispatch({ type: 'SET_CUSTOMER', payload: data });
    localStorage.setItem('customer', data);
    localStorage.setItem('stripeSubscriptionId', data.subscriptionId)
    return data;
  } catch (error) {
    console.log(error.message);
    return;
  }
};

export const updateSubscription = (priceId) => async (dispatch) => {
  const data = await api.updateSubscription(priceId);
  return data;
};

export const cancelSubscription = () => async (dispatch) => {
  try {
    const {data} = await api.cancelSubscription();
    dispatch({ type: 'SET_CANCELLED_SUBSCRIPTION', payload: data });
    return data;
  } catch (error) {
    console.log(error.message);
    return;
  }
};

export const cancelSubscriptionById = (subscriptionId) => async (dispatch) => {
  try {
    const {data} = await api.cancelSubscriptionById(subscriptionId);
    dispatch({ type: 'SET_CANCELLED_SUBSCRIPTION', payload: data });
    return data;
  } catch (error) {
    console.log(error.message);
    return;
  }
};
export const createCustomerCard = (card) => async (dispatch) => {
  try {
    const { data } = await api.createCustomerCard(card);
    dispatch({ type: 'SET_CUSTOMER_CARDS', payload: data });
    localStorage.setItem('customer', data);
    return data;
  } catch (error) {
    console.log(error.message);
    return;
  }
};

export const getCustomerCards = () => async (dispatch) => {
  const { data } = await api.getCustomerCards();
  dispatch({ type: 'SET_CUSTOMER_CARDS', payload: data.paymentMethods });
  localStorage.setItem('customer', data);
  return data;
};

export const setDefaultCustomerCard = (id) => async (dispatch) => {
  try {
    const { data } = await api.setDefaultCustomerCard(id);
    return data;
  } catch (error) {
    console.log(error.message);
    return;
  }
};

export const createSetupIntent = () => async () => {
  const { data } = await api.createSetupIntent();
  return data.client_secret;
};

export const getSubscriptionPrices = () => async (dispatch) => {
  try {
    const { data } = await api.getSubscriptionPrices();
    dispatch({ type: 'SET_PRICING', payload: data });
  } catch (error) {
    console.log(error.message);
    return;
  }
};