// External imports
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { useTheme } from 'styled-components'
import tw from 'twin.macro'
import html2canvas from 'html2canvas';
import _ from 'lodash'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { useMediaQuery } from 'react-responsive'
import { MdOutlineFitScreen, MdOutlineShowChart  } from 'react-icons/md'
import { HiOutlineShare, HiOutlineDocumentDuplicate  } from 'react-icons/hi'
import { TbChartCandle } from "react-icons/tb";

// Internal imports
import { 
  Modal, ContentContainer, PriceGraph, TextOptionSelector, SingleSelectDropdown, ShareModal,IconButtons, BoxAnimation
} from '../../../components'
import { getNameConversion } from '../../../utils'
import { updatePriceChartTimeframe } from '../../../actions/userPreferences'
import { useGetPriceChartDataQuery } from '../../../rtk'

dayjs.extend(utc)
dayjs.extend(timezone)

// Styling
const ChartDiv = styled.div`${tw`w-full h-[250px] flex flex-col text-sm`};`
const borderColourSelector = (data) => {
  if (data.length >= 2 && data[0].close <= data[data.length - 1].close)
    return '#8b2126'
  return '#218B82'
}

const priceToggle = [
  { name: '1D', labels: 'HH:mm', interval: '1min', useLiveData: true },
  { name: '5D', labels: 'DD MMM', interval: '15min', useLiveData: true },
  { name: '1M', labels: 'DD MMM', interval: '30min', useLiveData: true },
  { name: '6M', labels: 'MMM YY', interval: '1day', useLiveData: true },
  { name: '1Y', labels: 'MMM YY', interval: '1day', useLiveData: true },
  // {name:"YTD", labels:"MMM", interval:null, useLiveData: true },
  { name: '5Y', labels: 'YYYY', interval: '1day', useLiveData: true },
  { name: '10Y', labels: 'YYYY', interval: '1day', useLiveData: true },
  { name: 'MAX', labels: 'YYYY', interval: '1day', useLiveData: true },
];

const getProperDay = (est) => {
  if (est.day() === 0) return est.subtract(2, 'days')
  else if (est.day() === 1) return est.subtract(3, 'days')
  else return est.subtract(1, 'days')
}

const getBackDate = (timeframe) => {
  const est = dayjs().tz('America/New_York');
  const timeframes = {
    '1D': () => getProperDay(est),
    '5D': () => est.subtract(5, 'days'),
    '1M': () => est.subtract(1, 'month'),
    '6M': () => est.subtract(6, 'months'),
    'YTD': () => est.startOf('year'),
    '1Y': () => est.subtract(1, 'year'),
    '5Y': () => est.subtract(5, 'years'),
    '10Y': () => est.subtract(10, 'years'),
    'MAX': () => dayjs(new Date(1950, 1, 1)),
  };
  return (timeframes[timeframe] || timeframes['MAX'])();
};

// Padding logic: Pads the shorter dataset with nulls at the start
const padData = (data1 = [], data2 = [], data3 = []) => {
  const maxLength = Math.max(data1.length, data2.length, data3.length);
  const pad = (data) => new Array(maxLength - data.length).fill(null).concat(data);
  return [pad(data1), pad(data2), pad(data3)];
};

// Section containing price / valuation data for a searched stock
export const Valuation = ({ symbol, priceChartTimeframe, ticker, compareSymbol }) => {
  const palette = useTheme()
  const isTablet = useMediaQuery({ maxWidth: '1100px' })
  const showDropDownToggles = useMediaQuery({ maxWidth: '650px' })
  const [openShareModal, setOpenShareModal] = useState(false)
  const [showCandleChart, setShowCandleChart] = useState(true);
  const [openModal, setOpenModal] = useState(false)
  const chartRef = useRef(null);
  const [chartImage, setChartImage] = useState('');
  const statType = 'price'
  const dispatch = useDispatch()

  const { scaling } = useSelector(
    (state) => state.userPreferenceReducer.userGraphSettings
  )

  // Data for price movements
  const priceChange = [
    {
      name: '1D: ',
      change: `${
        ((ticker?.dailyStockPrice[0].close - ticker?.dailyStockPrice[1].close) / ticker?.dailyStockPrice[1].close) * 100
      }`,
      changeSuffix: '%',
    },
    { name: '5D: ', change: `${ticker?.priceChanges?.fiveDayPricePercentageChange}`, changeSuffix: '%' },
    { name: '1M: ', change: `${ticker?.priceChanges?.oneMonthPricePercentageChange}`, changeSuffix: '%' },
    { name: '6M: ', change: `${ticker?.priceChanges?.sixMonthPricePercentageChange}`, changeSuffix: '%' },
    { name: '1Y: ', change: `${ticker?.priceChanges?.oneYearPricePercentageChange}`, changeSuffix: '%' },
    { name: '5Y: ', change: `${ticker?.priceChanges?.fiveYearPricePercentageChange}`, changeSuffix: '%' },
    { name: '10Y: ', change: `${ticker?.priceChanges?.tenYearPricePercentageChange}`, changeSuffix: '%' },
  ];

  // Disabling scrolling when modal is open
  useEffect(() => {
    document.body.style.overflow = openModal ? 'hidden' : 'auto'
  }, [openModal])

  const filteredPriceToggle = priceToggle.find(
    (toggle) => toggle.name === priceChartTimeframe
  )

  const { data, isFetching } = useGetPriceChartDataQuery({
    symbol: symbol,
    interval: filteredPriceToggle?.interval,
    fromDate: getBackDate(filteredPriceToggle?.name)?.format('YYYY-MM-DD'),
    toDate: dayjs().format('YYYY-MM-DD'),
    timeframe: filteredPriceToggle?.name,
  })

  const { data: comparePriceData, isFetching: isCompareFetching } = useGetPriceChartDataQuery(
    {
      symbol: compareSymbol,
      interval: filteredPriceToggle?.interval,
      fromDate: getBackDate(filteredPriceToggle?.name)?.format('YYYY-MM-DD'),
      toDate: dayjs().format('YYYY-MM-DD'),
      timeframe: filteredPriceToggle?.name,
    }, { skip: !compareSymbol }
  );

  const captureChart = () => {
    html2canvas(chartRef.current).then((canvas) => {
      setChartImage(canvas.toDataURL('image/png'));
    });
  }

  const [paddedPriceData, paddedVolumeData, paddedComparativeData] = data?.chartData && compareSymbol && comparePriceData?.chartData ? 
  padData(
    data.chartData.map((d) => d.close).reverse(),
    data.chartData.map((d) => d.volume).reverse(),
    comparePriceData.chartData.map((d) => d.close).reverse()
  )
    : data?.chartData ?
      [
        data.chartData.map((d) => d.close).reverse(),
        data.chartData.map((d) => d.volume).reverse(),
        [] // Empty array for comparative data
      ]
  : [[], [], []]; // If no data, set empty arrays

  const updatePriceChart = (value) => {
    dispatch(updatePriceChartTimeframe(value)).then(() => {
      // getData(value)
    })
  }

  // Individual chart component
  const renderChart = () => {
    if (!data?.chartData || isFetching 
      || isCompareFetching
    ) {
      return (
        <div className="flex justify-center items-center h-full">
          <BoxAnimation />
        </div>
      );
    }
  
    const chartData = {
      priceData: paddedPriceData,
      volumeData: paddedVolumeData,
      labels: data.chartData.map(data => data.date).reverse(),
      candlestickData: data.chartData.map(data => ({
        open: data.open, high: data.high, low: data.low, close: data.close
      })).reverse(),
    };
  
    const comparativeData = compareSymbol != null && paddedComparativeData && comparePriceData ? {
      priceData: paddedComparativeData,
      labels: comparePriceData?.chartData.map(data => data.date).reverse(),
    } : null;
  
    return (
      <PriceGraph
        priceChartTimeframe={priceChartTimeframe}
        chartData={chartData}
        comparativeData={comparativeData}
        chartColour={borderColourSelector(data.chartData)}
        chartFillColour={palette.colors.priceChartFill}
        labelDisplay={filteredPriceToggle?.labels}
        showCandleChart={showCandleChart}
        symbol={symbol}
        compareSymbol={compareSymbol}
        scaling={scaling}
      />
    );
  };

  return (
    <div className="flex flex-row space-x-6 w-full max-w-[2500px] h-full items-start">
      <ContentContainer
        Header={getNameConversion(statType)}
        Icons={
          <div className="flex flex-row space-x-1">
            {/* <IconButtons
              action={() => {
                setOpenShareModal(true)
                captureChart()
              }}
              icon={<HiOutlineShare />}
              text={'Share'}
            /> */}
            {/* <IconButtons
              action={() => setShowCandleChart(prevState => !prevState)}
              icon={showCandleChart ? <TbChartCandle /> : <MdOutlineShowChart />}
              text={showCandleChart ? 'Candlestick' : 'Line graph'}
            /> */}
            {/* <IconButtons
              action={() => setOpenModal(true)}
              icon={<MdOutlineFitScreen />}
              text={'Expand'}
            /> */}
            {showDropDownToggles && (
              <SingleSelectDropdown
                setSelected={updatePriceChart}
                dropDownOptions={priceToggle.map((price) => price.name)}
                width={'70px'}
                useParentInput={true}
                parentInput={priceChartTimeframe}
              />
            )}
          </div>
        }
        Toggle={!showDropDownToggles && (
          <TextOptionSelector
            fit={true}
            options={priceToggle.map((price) => price.name)}
            selection={priceChartTimeframe}
            setSelection={updatePriceChart}
            isDispatchable={false}
            priceChange={isTablet ? null : priceChange}
          />
        )}
        Width={'fit-content'}
        Content={
          <>
            <ChartDiv
              style={{ width: '1000', height: '240' }}
              className="cursor-auto"
              ref={chartRef}
            >
              {renderChart()}
            </ChartDiv>
          </>
        }
      />
      {/* <Modal
        open={openModal}
        maxWidth={'2600px'}
        closeModal={() => setOpenModal(false)}
        headerContent={<>{_.capitalize(statType)}</>}
        content={
          <ChartDiv
            style={{ height: "min(60vw, calc(45vh - 60px))" }}
            className="cursor-auto"
            ref={chartRef}
          >
            {renderChart()}
          </ChartDiv>
        }
      /> */}
      {/* <ShareModal
        open={openShareModal}
        close={() => {
          setOpenShareModal()
        }}
        icon={<HiOutlineShare />}
        header="Share"
        content={chartImage}
      /> */}
    </div>
  )
}
