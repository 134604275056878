import React from 'react';
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";

import { footerText } from '../../data/text.js'

//Styling
const FooterContainer = styled.footer` ${tw`w-full flex flex-col justify-center items-center py-2 px-6 mt-auto bg-primaryBg border-t-1 border-textBase`}`;
const NavlinkSection = styled.section` ${tw`flex flex-row py-1`}; `;
const TextSection = styled.section` ${tw`w-full text-xs text-textBase flex flex-col items-center justify-center py-4 border-t text-justify space-y-2`} `;
const CopyrightDiv = styled.div` ${tw` w-[100%] flex justify-center`} `;
const LinkText = styled.div` 
  ${tw`w-[fit-content] text-sm font-semibold list-none mx-3 mb-2 mt-1 cursor-pointer transition-colors text-textMuted hover:text-textBase`}
  color: ${({ isActive }) => isActive ? "var(--textBase)" : "var(--textMuted)"};
`;

export const Footer = () => {
  return (
    <FooterContainer>
      <NavlinkSection>
        <CustomLink to='/about'>About</CustomLink>
        <CustomLink to='/support'>Support</CustomLink>
        <CustomLink to='/terms'>Terms</CustomLink>
        <CustomLink to='/privacy'>Privacy</CustomLink>
      </NavlinkSection>
      <TextSection>
        <div className='max-w-[2500px]'>{footerText.Paragraph1}</div>
        <div className='max-w-[2500px]'>{footerText.Paragraph2}</div>
        {/* <CopyrightDiv>
          {footerText.CopyRightText}
        </CopyrightDiv> */}
      </TextSection>
    </FooterContainer>
  )
}

const CustomLink = ({to, children, ...props}) => {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })

  return(
      <Link to={to} {...props} className="cursor-auto">
          <LinkText isActive={isActive}>{children}</LinkText>
      </Link>
  );
}