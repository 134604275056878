// External imports
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { BsCheckCircle, BsCheckCircleFill } from 'react-icons/bs'
import { cancelSubscription } from '../../actions/payment'
import { getSubscriptionDetails } from '../../actions/user'

// Internal imports
import { paymentPlans } from '../../data/text'
import { FormButton } from '../../components'
import { resumeSubscription } from '../../api'

export const PlanCard = ({ subscriptionDetails }) => {
  const dispatch = useDispatch()
  const { stripeSubscriptionId } = useSelector(
    (state) => state.watchListReducer
  )

  // Fetch subscription details when component loads
  useEffect(() => {
    dispatch(getSubscriptionDetails())
  }, [dispatch])

  // cancel subscription
  const cancelSub = () => {
    if (stripeSubscriptionId) {
      dispatch(cancelSubscription()).then(() =>
        dispatch(getSubscriptionDetails())
      )
    }
  }

  // resume subscription
  const resumeSub = async () => {
    resumeSubscription().then(() => dispatch(getSubscriptionDetails()))
  }

  return (
    <div className="w-full rounded p-4 space-y-4 border-1 border-blue-500 shadow-md">
      <div className="w-full flex items-center text-2xl text-textBase">
        <h1 className="w-full h-[fit-content] flex items-center justify-start text-lg font-bold">
          Pro plan
        </h1>
        <BsCheckCircleFill />
      </div>

      <div className="w-full flex flex-col items-start justify-center space-y-1">
        <div className="w-full flex flex-row justify-start items-end space-x-2">
          <div className="text-3xl font-base font-bold">
            <span>$6</span>
          </div>
          <span className="text-textMuted text-lg font-bold">
            USD per month
          </span>
        </div>
        <div className="text-xs text-textMuted">
          Fee is per month and is billed monthly in advance
        </div>
      </div>

      {paymentPlans.map((card, index) => {
        return (
          <div
            key={index}
            className="h-[max-content] w-full flex items-start justify-start border-t-1 pt-6"
          >
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-2 gap-x-10">
              {card.features.map((feature, index) => {
                return (
                  <div
                    key={index}
                    className="w-full flex flex-row space-x-2 items-start"
                  >
                    <BsCheckCircle className="font-semibold text-lg text-textMuted" />
                    <div className="h-full text-sm text-textBase">
                      {feature}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}

      {
        !subscriptionDetails?.cancel_at_period_end &&
          subscriptionDetails?.default_payment_method && (
            <FormButton
              isNegative={true}
              text={'Cancel subscription'}
              onClick={() => cancelSub()}
            />
          )
      }

      {
        subscriptionDetails?.cancel_at_period_end &&
          subscriptionDetails?.default_payment_method && (
            <FormButton
              isSecondary={true}
              text={'Resume'}
              onClick={() => resumeSub()}
            />
          )
      }
    </div>
  )
}
