// External imports
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import tw from "twin.macro";
import { FacebookShareButton, FacebookMessengerShareButton, TwitterShareButton, LinkedinShareButton, RedditShareButton, WhatsappShareButton } from 'react-share';
import { FacebookIcon, FacebookMessengerIcon, TwitterIcon, LinkedinIcon, RedditIcon, WhatsappIcon } from 'react-share';

// Internal imports
import { Modal, IconButtons } from '..';

// Styling
const ChartImage = styled.img`
  ${tw`w-full h-auto`}
`

export const ShareModal = ({ open, close, icon, header, content }) => {
  const iconSize = 36;
  const [loadImgErr, setLoadImgErr] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());

  const socialButtons = [FacebookShareButton, FacebookMessengerShareButton, TwitterShareButton, LinkedinShareButton, RedditShareButton, WhatsappShareButton];
  const socialIcons = [FacebookIcon, FacebookMessengerIcon, TwitterIcon, LinkedinIcon, RedditIcon, WhatsappIcon];

  const onImgErr = () => {
    setLoadImgErr(true);
  };

  const formattedTime = currentTime.toLocaleString('en-US', {
    day: '2-digit',
    month: 'short',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZoneName: 'short'
  });

  const data = {
    companyName: 'Microsoft Inc.',
    stockTicker: 'MSFT',
    logoUrl: 'https://financialmodelingprep.com/image-stock/MSFT.png',
    graphType: 'Price', 
    graphPeriod: '10Y',
  };

  return (
    <Modal 
      open={open} 
      closeModal={close}  
      headerContent={<span className='flex flex-row items-center'>{icon}<h1 className='ml-2'>{header}</h1></span>}
      maxWidth='2000px'
      height='fit-content'
      content={
        <div className='w-full flex flex-col items-center space-y-2'>
          <div className='w-full space-y-1 mt-2 border-1 border-textBase p-2 rounded'>
            <div className='w-full flex flex-row justify-between items-center'>
              <div className='flex flex-row items-center space-x-1'>
                <span className='text-xs font-semibold'> 
                  <img
                    src={data.logoUrl}
                    alt=""
                    style={{
                      maxWidth: '20px',
                      maxHeight: '20px',
                      filter: 'drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.4))',
                      borderRadius: '3px',
                      display: loadImgErr ? 'none' : 'block',
                    }}
                    type="image/png"
                    onError={onImgErr}
                  />
                </span>
                <div className='flex flex-col'>
                  <div className='text-xs font-semibold space-x-1'> 
                    <span>{data.stockTicker}:</span> 
                    <span className='text-textMuted font-normal'>{data.companyName}</span> 
                  </div>
                </div>
              </div>

              <div className='w-fit text-xs p-2 rounded-full bg-neutralBg space-x-2'> 
                <span className='w-fit text-textBase'>{data.graphType} {data.graphPeriod}:</span>
                <span className='w-fit text-textNegative'>{formattedTime}</span>
              </div>
            </div>

            {content ? <ChartImage src={content} alt="Captured Chart" /> : <p>Loading...</p>}
          </div>

          <div className="w-full flex justify-center space-x-2 pt-2 border-t-1 border-neutral">
            {socialButtons.map((Button, index) => {
              const Icon = socialIcons[index];
              return (
                <Button key={index} url={data.graph}>
                  <Icon size={iconSize} round />
                </Button>
              );
            })}
          </div>
        </div>
      } 
    />
  );
};

// import { useSelector } from 'react-redux';

// import { HiOutlineClipboardList } from 'react-icons/hi';

// const InputContainer = styled.div` ${tw`flex flex-row items-center rounded bg-neutralBg text-textBase text-base text-left`}; `;

// const { latestStock } = useSelector((state) => state.searchReducer);

// const currentUrl = process.env.REACT_APP_AUTH0_REDIRECT_URI+'/'+latestStock;

//   // Copy the current URL to the clipboard
//   const handleCopyLink = () => {
//     navigator.clipboard.writeText(currentUrl);
//   };

          {/* <div className='w-full flex flex-row items-center space-x-2 pt-2'>
            <InputContainer className='w-full flex h-[32px] px-2 truncate'>
              <div className='flex flex-row items-center text-sm truncate'>
                {currentUrl}
              </div>
            </InputContainer>
            <IconButtons icon={<HiOutlineClipboardList/>} action={() => handleCopyLink()} />
          </div> */}