import React from 'react'
// import { Link } from "react-router-dom";
import styled from 'styled-components'
import tw from 'twin.macro'

const InfoContainer = styled.div`${tw`w-full flex`};`
const InfoItemLabel = styled.div`${tw`w-full flex flex-col items-start justify-center text-lg font-semibold`};`
const Pill = styled.div`${tw`flex flex-col items-start justify-center text-black text-sm font-semibold h-6 ml-2 bg-pillBg rounded-xl p-2`};`

export const SettingsDataContainer = ({ header, info, option, onClick, pillMsg, topPadding, footer }) => (
  <InfoContainer className={`flex-col justify-between ${ topPadding ? 'pt-2' : '' } space-y-2`}>
    <div className="flex items-center">
      <InfoItemLabel>{header}</InfoItemLabel>
    </div>
    <div className="flex flex-row items-center justify-between">
      <div className="flex items-center">
        {info}
        {pillMsg && <Pill>{pillMsg}</Pill>}
      </div>
      <button 
        className="text-textMuted hover:text-textBase hover:underline" 
        onClick={onClick}
      >
        {option}
      </button>
    </div>
    {footer}
  </InfoContainer>
)

export const SettingsSingleRowContainer = ({ header, info, option, topPadding}) => (
  <InfoContainer className={`flex-row items-center justify-between ${ topPadding ? 'pt-2' : '' }`}>
    <InfoItemLabel>{header}</InfoItemLabel>
    <div>{info}</div>
    <div>{option}</div>
  </InfoContainer>
)