import dayjs from 'dayjs'

/**@type{Map<string, Map<string, string>>} */
const hashMap = new Map([
  [
    'incomeStatement',
    new Map([
      ['Annual', 'incomeStatement'],
      ['Quarterly', 'incomeStatementPerQuarter'],
    ]),
  ],
  [
    'balanceSheet',
    new Map([
      ['Annual', 'balanceSheet'],
      ['Quarterly', 'balanceSheetPerQuarter'],
    ]),
  ],
  [
    'financialGrowth',
    new Map([
      ['Annual', 'financialGrowth'],
      ['Quarterly', 'financialGrowthPerQuarter'],
    ]),
  ],
  [
    'cashFlow',
    new Map([
      ['Annual', 'cashFlow'],
      ['Quarterly', 'cashflowPerQuarter'],
    ]),
  ],
  [
    'keyMetrics',
    new Map([
      ['Annual', 'keyMetrics'],
      ['Quarterly', 'keyMetricsPerQuarter'],
    ]),
  ],
  [
    'financialRatios',
    new Map([
      ['Annual', 'financialRatios'],
      ['Quarterly', 'financialRatiosPerQuarter'],
    ]),
  ],
])
/**
 * build a key that can be used to fetch data from store
 * @param {String} key
 * @param {'Annual' | 'Quarterly'} fundamentalPeriodType
 * @returns a key which is used to fetch fundametal data from store
 */
export const fundametalKeyBuider = (key, fundamentalPeriodType) => {
  if (key === 'historicDailyDividend') return key
  return hashMap.get(key).get(fundamentalPeriodType)
}

/**
 * Build a date in the format YYYY-MM for quarterly and YYYY for annual.
 * This is used to fetch data from store.
 * @param {string | Date} date
 * @param {*} fundamentalPeriodType
 * @returns a date in the format YYYY-MM for quarterly and YYYY for annual
 */
export const fundamentalDateBuilder = (date, fundamentalPeriodType) => {
  if (
    fundamentalPeriodType === 'Annual' ||
    fundamentalPeriodType === 'Quarterly'
  )
    return dayjs(date).format('DD MMM YYYY').toString()
  else return ''
}

/**
 * Return a label for graph.
 * @param {string} statType
 * @returns string
 */
export const fundamentalGraphHelper = (statType) => {
  let label = undefined
  if (statType === 'Revenue') label = 'revenue'
  else if (statType === 'Expenses') label = 'expenses'
  else if (statType?.startsWith('Earnings')) label = 'earnings'
  else if (statType === 'Free Cash Flow') label = 'freeCashFlow'
  else if (statType === 'Cash & Debt') label = 'cashAndDebt'
  else if (statType === 'Shares Outstanding') label = 'sharesOutstanding'
  else if (statType === 'Effectiveness Ratios') label = 'effectivenessRatios'
  else if (statType === 'Dividends') label = 'dividends'
  else if (statType === 'Margins') label = 'margins'
  else if (statType === 'Growth Ratios') label = 'growthRatios'
  else if (statType === 'Price Ratios') label = 'priceRatios'
  else if (statType === 'Per Share Ratios') label = 'perShareRatios'
  return label
}

/**
 * returns a correct label option
 * @param {string} option
 * @returns {string} option
 */
export const graphOptionHelper = (option, statType) => {
  if (option === 'Revenue') {
    if (statType === 'growthRatios') return 'revenueGrowth'
    return 'revenue'
  }
  if (option === 'Cost of Revenue') return 'costOfRevenue'
  if (option === 'R&D') return 'researchExpenses'
  if (option === 'Admin') return 'generalAdmin'
  if (option === 'Marketing') return 'marketing'
  if (option === 'Gross Profit') {
    if (statType === 'earnings') return 'grossProfit'
    else return 'grossProfitRatio'
  }
  if (option === 'EBITDA') {
    if (statType === 'earnings') return 'ebitda'
    else return 'ebitdaratio'
  }
  if (option === 'FCF') {
    if (statType === 'growthRatios') return 'freeCashFlowGrowth'
    return 'freeCashFlow'
}
  if (option === 'Stock Based Comp') return 'stockBasedCompensation'
  if (option === 'SBC adj FCF')
    return 'stockBasedCompensationAdjustedFreeCashFlow'
  if (option === 'Cash') return 'cash'
  if (option === 'Long Term Debt') return 'debt'
  if (option === 'Capital Lease Obligations') return 'capitalLeaseObligations'
  if (option === 'Shares Outstanding') return 'sharesOutstanding'
  if (option === 'Dividends') return 'dividend'
  if (option === 'ROIC') return 'roic'
  if (option === 'ROCE') return 'roce'
  if (option === 'ROA') return 'roa'
  if (option === 'ROE') return 'roe'
  if (option === 'Profit') {
    if (statType === 'Profit') return 'netIncomeGrowth'
    else if (statType === 'growthRatios') return 'netIncomeGrowth'
    else return 'netIncomeRatio'
  }
  if (option === 'P/E') return 'peRatio'
  if (option === 'P/FCF') return 'pfcfRatio'
  if (option === 'EPS') return 'eps'
  if (option === 'FCFPS') return 'freeCashFlowPerShare'
  return ''
}

/**
 * Checks if we need to display this option (bar /  line) in the graph associated with given statType
 * @param {userGraphPreferences} userGraphPreferences
 * @param {string} statType
 * @param {string} option
 * @returns true if graph option should not be hidden else false
 */
export const isOptionShouldNotHide = (
  userGraphPreferences,
  statType,
  option
) => {
  if (statType === 'Employees') return true
  if (!statType || !option) return false
  return userGraphPreferences[fundamentalGraphHelper(statType)][
    graphOptionHelper(option, fundamentalGraphHelper(statType))
  ]
}
