import React from 'react'
import styled from 'styled-components';
import tw from 'twin.macro';
import { BsCheck } from 'react-icons/bs';

const CardContainer = styled.div`
  ${tw`w-[240px] flex flex-col justify-center items-center m-3 p-2 rounded-lg border-1 border-textBase shadow-md relative text-textBase bg-primaryBg`};
`

export const FeatureCard = ({ title, features, price, paymentPeriod, cta }) => {

  return (
    <CardContainer>
      <div className="w-full">
        <h1 className="w-full h-[fit-content] flex items-center justify-center text-2xl font-base rounded font-black text-blue-500 mt-10">
          {title}
        </h1>

        <div className="w-full flex flex-row items-center justify-center">
          <div className="w-full flex flex-col justify-center items-center py-2">

              <div className="text-4xl font-base font-black text-center">
                <span>${Number(price)}</span>
              </div>

            <span className="text-textMuted text-xs">
              USD per month
            </span>
            <div className="text-xs text-textMuted pb-1">{paymentPeriod}</div>
          </div>
        </div>
      </div>

      <div className="h-[max-content] w-full flex items-start justify-center pb-10 pt-6">
        <div className="space-y-2 ">
          {features.map((feature, index) => {
            return (
              <div
                className="w-full flex flex-row space-x-2 items-center"
                key={index}
              >
                <BsCheck className="font-black text-lg text-emerald-600" />
                {/* <IoClose className='font-black text-lg text-textMuted'/> */}
                <div className="h-full text-sm">{feature}</div>
              </div>
            )
          })}
        </div>
      </div>
      {cta}
    </CardContainer>
  )
}
