import * as api from '../api';

// Create an action to fetch the screener options using the API
export const getScreenerOptions = () => async (dispatch) => {
  try {
    const { data } = await api.fetchScreenerOptions();
    dispatch({ type: 'FETCH_SCREENER_OPTIONS', payload: data });
  } catch (error) {
    console.log(error.message)
  }
}

// Create an action to fetch the screener results using the API
export const getScreenerResults = (query) => async (dispatch) => {
  try {
    const { data } = await api.fetchScreenerResults(query);
    dispatch({ type: 'FETCH_SCREENER_RESULTS', payload: data });
  } catch (error) {
    console.log(error.message);
  }
}