// External imports
import React from 'react';
import styled from "styled-components";
import tw from "twin.macro";
import numeral from 'numeral';

//Styling
const StyledButton = styled.button`
  ${tw`rounded p-1 font-normal flex flex-row items-center justify-center cursor-pointer text-lg  hover:text-textBase hover:shadow-md hover:border-blue-500 border-1 border-primaryBg`};
  background-color: ${
    ({ isActive }) => isActive ? "var(--activeBg)" : ({ isDisabled }) => isDisabled ? "var(--buttonDisabled)" : "var(--neutralBg)"
  };
  color: ${
    ({ isActive }) => isActive ? "var(--textButtonActive)" : ({ isDisabled }) => isDisabled ? "#ffffff" : "var(--textMuted)"
  };
  pointer-events: ${
    ({ isActive }) => isActive ? "none" : ({ isDisabled }) => isDisabled ? "none" : "auto"
  };
`;
const Icon = styled.span` ${tw`h-6 w-6 flex items-center justify-center`}; `;
const Text = styled.span` ${tw`h-6 px-1 text-xs font-semibold flex items-center justify-center`}; `;

// Buttons that include icons or icons + text 
export const IconButtons = ({icon, displayText, text, action, isActive, isDisabled, width }) => {
  return (
    <StyledButton type='button' className={`${width}`} onClick={action} isActive={isActive} isDisabled={isDisabled}>
      {icon && <Icon> {icon} </Icon>} 
      {displayText && text && <Text className='mx-1'>{text}</Text>}
    </StyledButton>
  )
}

// Buttons for numbers
export const NumberButtons = ({text, action, isActive}) => {
  return (
    <StyledButton type='button' className='h-[28px] w-fit' onClick={action} isActive={isActive}>
      <Text className='h-[20px] w-fit min-w-[20px] max-w-[36px]'>{numeral(text).format('0,0')}</Text>
    </StyledButton>
  )
}