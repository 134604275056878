// External imports
import React, { useState } from 'react';
import styled from 'styled-components';
import tw from "twin.macro";
import { NumericFormat } from "react-number-format";

// Internal imports
import { Modal, ToggleButton } from '..';
import { metricOptions } from '../../data/metricOptions';

// Styling
const InputContainer = styled.div` ${tw`flex flex-row border-1 rounded bg-primaryBg hover:border-onPrimary text-textBase`}; `;
const StyledNumericFormat = styled(NumericFormat)`
  ${tw`w-[100px] px-2 py-1 outline-none rounded text-xs text-left bg-[transparent]`};
  &::placeholder {transition: all 500ms ease-in-out; color: ${({ theme }) => theme.colors.placeholderText};}
  &:hover&::placeholder{color: ${({ theme }) => theme.colors.placeholderTextHover};}
`;
const SuffixContainer = styled.div` ${tw`w-[20px] flex flex-row justify-center items-center text-xs font-semibold text-textBase`}; `;

export const NotificationsModal = ({ open, close, icon, header, ticker }) => {

  const metrics = ["price", "oneDayPriceChangePercentage", "weeklyPricePercentageChange", "monthlyPricePercentageChange", "quarterlyPricePercentageChange", "halfyearPricePercentageChange", "yearlyPricePercentageChange", "fiveYearPricePercentageChange", "peRatioTTM", "dividendYieldTTM", "freeCashFlowYieldTTM", "declarationDate", "paymentDate", "earningsCalendar"];
  const metricSettings = metricOptions.filter(option => metrics.includes(option.field));
  const [selectedSettings, setSelectedSettings] = useState([]);

  // Define the desired category order
  const categoryOrder = [
    "Price & valuation",
    "Cashflow (ttm)",
    "Dividends (ttm)",
    "Dates"
  ];

  // Custom sorting function based on category and field order
  const customSort = (a, b) => {
    const categoryIndexA = categoryOrder.indexOf(a.category);
    const categoryIndexB = categoryOrder.indexOf(b.category);

    if (categoryIndexA !== categoryIndexB) {
      return categoryIndexA - categoryIndexB;
    }

    return metricOptions.indexOf(a) - metricOptions.indexOf(b);
  };

  // Sort the metricSettings based on the custom sorting function
  const sortedMetricSettings = [...metricSettings].sort(customSort);

  const handleSelection = (e) => {
    const { value } = e.target;
    if (selectedSettings.includes(value)) {
      setSelectedSettings(selectedSettings.filter(setting => setting !== value));
    } else {
      setSelectedSettings([...selectedSettings, value]);
    }
  };

  return (
    <Modal 
      open={open} 
      closeModal={close}  
      headerContent={<span className='flex flex-row items-center'>{icon}<h1 className='ml-2'>{header}</h1></span>}
      width='400px'
      content={
        <div className='w-full flex flex-col'>
          <div className='flex flex-row items-center border-b-1'>
            <img 
              src={`https://financialmodelingprep.com/image-stock/${ticker}.png`} 
              width="40" height="40" className='m-2' alt="stock logo" type="image/png"
              style={{filter: 'drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.4))', borderRadius: '3px'}}
            />
            <div className='w-[0px] h-[40px] border-l-1 m-2 mr-3'/>
            Receive notifications when:
          </div>
          <div className='flex flex-col w-full space-y-2 pt-2'>
            {sortedMetricSettings.map((option) => (
              <div key={option.field} className='h-[26px] flex flex-row items-center justify-between'>
                <div className='flex flex-row items-center'>
                  <label htmlFor={option.field} className='flex items-center justify-center'>
                    <ToggleButton
                      id={`toggle-${option.field}`}
                      value={option.field}
                      checked={selectedSettings.includes(option.field)}
                      onChange={handleSelection}
                    />
                  </label>
                  <div className='ml-2'>{option.displayedText || option.field}</div>
                </div>
                {option.isMetric && (
                  <InputContainer>
                    <StyledNumericFormat
                      thousandSeparator={true}
                      decimalScale={2}
                      onChange={null}
                    />
                    <SuffixContainer>{option.prefix || option.suffix}</SuffixContainer>
                  </InputContainer>
                )}
              </div>
            ))}
          </div>
        </div>
      } 
    />
  )
}