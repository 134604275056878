// External imports
import React, { useState } from 'react';
import styled from 'styled-components'
import tw from 'twin.macro'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// Internal imports
import { Modal, SearchBar, SearchBar2, Company } from '..';

export const CompareModal = ({ open, close, icon, header }) => {
  return (
    <Modal 
      open={open} 
      closeModal={close}  
      headerContent={<span className='flex flex-row items-center'>{icon}<h1 className='ml-2'>{header}</h1></span>}
      maxWidth='650px'
      height='fit-content'
      verticalPosition='150px'
      content={
        <div className='w-full flex flex-col items-center justify-start pt-2 space-y-2'>
          <SearchBar2
            dropdownLimit='5'
            close={close}
          />
        </div>
      } 
    />
  )
}