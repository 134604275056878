// External Imports
import React, { useState, useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import tw from 'twin.macro'
import { motion } from 'framer-motion'
import { useMediaQuery } from 'react-responsive'
import { NumericFormat } from 'react-number-format'
import {
  FaAngleDoubleLeft,
  FaAngleLeft,
  FaAngleRight,
  FaAngleDoubleRight,
} from 'react-icons/fa'
import {
  BsBookmarkHeart,
  BsBookmarkX,
  BsCreditCard2Front,
} from 'react-icons/bs'
import { IoEllipsisHorizontalSharp } from 'react-icons/io5'
import {
  TiArrowUnsorted,
  TiArrowSortedUp,
  TiArrowSortedDown,
} from 'react-icons/ti'

// Internal Imports
import { deviceSize } from '../../data/devices'
import { addToRecentSearchList } from '../../actions/searchLists'
import { updateTableRows } from '../../actions/userPreferences'
import { BuyModal, NumericOptionSelector, NumberButtons } from '../index'
import { formatValue } from '../../utils'
import { Avatar } from '@mui/material'
import {
  useAddTickerToWatchlistMutation,
  useRemoveTickerToWatchlistMutation,
} from '../../rtk'

//Styling
const HorizontalDiv = styled.div`
  ${tw`flex flex-row items-center border-neutral`};
`
const TableWrapper = styled.div`
  ${tw`overflow-auto w-full relative`};

  /* Hide vertical scrollbar */
  &::-webkit-scrollbar {
    width: 0;
  }

  thead {
    ${tw`sticky top-0 bg-primaryBg z-10`}
  }

  .max-h-lg {
    max-height: calc(100vh - 153px);
  }

  .max-h-sm {
    max-height: calc(100vh - 220px);
  }
`;

const TableHeader = styled.th`
  ${tw`px-2 pb-2 w-[fit-content] relative font-normal text-textMuted border-b-1 border-neutral`}
  cursor: ${({
    hasContent,
  }) => (hasContent ? 'pointer' : 'default')};
`
const ArrowContainer = styled.div`
  ${tw`w-[14px] ml-1 flex items-center`}
  /* visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')}; */
  transition: opacity 0.3s ease-in-out;
`

const TableRow = styled.tr`
  ${tw`h-[55px] border-b-1 border-neutralBg text-textMuted text-xs cursor-pointer hover:bg-neutralBg hover:shadow-md`};
`
const TableDataLeft = styled.td`
  ${tw`w-[fit-content] py-0.5 px-3 text-left`};
`
const TableDataCenter = styled.td`
  ${tw`py-0.5 px-3 text-center`};
`
const TableIconCenter = styled.td`
  ${tw`py-0.5 w-[fit-content] pr-2`};
`
const Icon = styled.div`
  ${tw`w-[25px] flex flex-col items-center justify-center text-lg cursor-pointer mx-1 hover:text-xl mb-1`};
`
const TableDataRight = styled.td`
  ${tw`py-0.5 px-3 w-[fit-content] text-right relative min-w-[100px]`};
`
const NextPreviousButton = styled(motion.button)`
  ${tw`flex items-center justify-center w-6 h-6 transition-colors ease-in-out duration-300 text-sm mx-1 text-textMuted hover:text-textBase`};
`

// const ProgressBarContainer = styled.div`
//   ${tw`w-[40px] h-[3px] rounded-lg bg-neutral overflow-hidden`}
// `

// const ProgressBarFill = styled.div`
//   ${tw`h-full rounded-lg`}
//   width: ${(props) => props.progress}%;
// `

const colorVariants = (value) => {
  if (value > 0) {
    return { backgroundColor: '#6ec2b834', color: '#218B82' }
  } else if (value < 0) {
    return { backgroundColor: '#da707434', color: '#da7073' }
  } else {
    return {
      backgroundColor: '#80808034',
      color: 'var(--textBase)',
      fontWeight: 400,
    }
  }
}

const setImgErrorReducer = (state, action) => {
  if (action.type === 'SET_ERROR') {
    const newState = [...state]
    newState[action.payload] = true
    return newState
  } else if (action.type === 'SET_ARRAY') {
    return Array(action.payload).fill(false)
  } else if (action.type === 'REMOVE_ELEM') {
    state.splice(action.payload, 1)
    return [...state]
  }
  return state
}

const DATA_FORMAT_TEST_DOLLAR_SIGN = ['price']
const DATA_FORMAT_TEST_PERCENTAGE_SIGN = [
  'price5yrCAGR',
  'price10yrCAGR',
  'revenueGrowthTTM',
  'revenue5yrCAGR',
  'revenue10yrCAGR',
  'ebitdaratio',
  'ebitdaGrowthTTM',
  'profitGrowthTTM',
  'epsgrowth',
  'debtGrowth',
  'roicTTM',
  'returnOnCapitalEmployedTTM',
  'returnOnAssetsTTM',
  'returnOnEquityTTM',
  'freeCashFlowYieldTTM',
  'sbcAdjustedFreeCashFlowYieldTTM',
  'freeCashFlowGrowthTTM',
  'freeCashFlowPerShare5yrCAGR',
  'freeCashFlowPerShare10yrCAGR',
  'stockBasedCompensationAdjustedFreeCashFlow5yrCAGR',
  'stockBasedCompensationAdjustedFreeCashFlow10yrCAGR',
  'dividendYieldTTM',
  'dividendsperShareGrowth',
  'payoutRatioTTM',
]
const DATA_FORMAT_TEST_PLAIN = [
  'peRatioTTM',
  'pfcfRatioTTM', 
  'pbRatioTTM',
  'epsTTM',
  'epsDilutedTTM',
  'currentRatioTTM',
  'bookValuePerShareTTM',
  'netDebtToEBITDATTM',
]
const DATA_FORMAT_TEST_X = []
const FORMAT_VALUE = [
  'oneDayPricePercentageChange',
  'fiveDayPricePercentageChange',
  'oneMonthPricePercentageChange',
  'threeMonthPricePercentageChange',
  'sixMonthPricePercentageChange',
  'yearToDatePricePercentageChange',
  'oneYearPricePercentageChange',
  'threeYearPricePercentageChange',
  'fiveYearPricePercentageChange',
  'tenYearPricePercentageChange',
  'maxPricePercentageChange',
]
const FORMAT_VALUE_PLAIN = ['mktCap', 'enterpriseValueTTM', 'netAssetValue']

const netDebtToEBITDATTMFormatter = (stock) => {
  if (!stock['keyMetricsTTM'] || !stock['keyMetricsTTM'][0])
    return stock['netDebtToEBITDATTM']
  const ttmObject = stock['keyMetricsTTM'][0]
  if ('netDebtToEBITDATTM' in ttmObject) return ttmObject['netDebtToEBITDATTM']
  return stock['netDebtToEBITDATTM']
}

export const DataTable = ({
  tableData,
  selectedColumns,
  columns,
  totalPages,
  currentPage,
  setCurrentPage,
  sortField,
  setSortField,
  sortOrder,
  setSortOrder,
  tableRows,
  watchList = [],
}) => {
  const selectedColumns__ = ['logo', 'symbol', ...selectedColumns]
  const filteredColumns = [
    ...columns.filter((col) => selectedColumns__.includes(col.field)),
    { field: 'button-group' },
  ]
  const isMobile = useMediaQuery({ maxWidth: deviceSize.mobile })
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loadImgErr, reducerDispatch] = useReducer(
    setImgErrorReducer,
    null,
    () => Array(tableData.length).fill(false)
  )
  const [loadFlagImg, flagReducerDispatch] = useReducer(
    setImgErrorReducer,
    null,
    () => Array(tableData.length).fill(false)
  )
  const [addTicker] = useAddTickerToWatchlistMutation()
  const [removeTicker] = useRemoveTickerToWatchlistMutation()
  const [modalType, setModalType] = useState(null)
  const [selectedSymbol] = useState(null)

  // Add stock to watchlist
  const handleUpdateWatchList = (ticker) => {
    addTicker({ ticker }).catch((err) => console.error(err))
  }

  // Remove stock from watchlist
  const removeStockFromWatchList = (stockSymbol) =>
    removeTicker({ ticker: stockSymbol })

  // Navigate to stock analysis page
  const navigateToStockAnalysis = (stockSymbol) => {
    dispatch(addToRecentSearchList(stockSymbol))
    navigate('/stockanalysis')
  }

  // Sort the table data based on the field and order
  const handleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 1 ? -1 : 1)
    } else {
      setSortField(field)
      setSortOrder(1)
    }
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1)

  // const showColumn = (column) => selectedColumns.includes(column)

  const StockIcon = ({ index, stock }) => (
    <>
      <TableDataCenter className="min-h-[55px] min-w-[65px] flex items-center justify-center">
      {/* sticky left-0 bg-primaryBg z-10 */}
        {loadImgErr[index] && (
          <Avatar
            sx={{
              bgcolor: '#5f8799',
              color: '#FFFFFF',
            }}
          >
            {stock.symbol[0]?.toLocaleUpperCase()}
          </Avatar>
        )}
        {!loadImgErr[index] && (
          <img
            onError={() =>
              reducerDispatch({ type: 'SET_ERROR', payload: index })
            }
            src={stock.logo}
            alt="Logo"
            className=""
            style={{
              display: loadImgErr[index] ? 'none' : 'block',
              maxWidth: '30px',
              maxHeight: '30px',
              filter: 'drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.4))',
              borderRadius: '3px',
            }}
            type="image/png"
          />
        )}
      </TableDataCenter>
    </>
  )

  const CountryFlag = ({ index, stock }) => (
    <TableDataCenter>
      {loadFlagImg[index] && <span>N/A</span>}
      {!loadFlagImg[index] && (
        <span className="flex flex-row justify-center items-center">
          <img
            style={{
              display: loadFlagImg[index] ? 'none' : 'block',
            }}
            onError={() =>
              flagReducerDispatch({
                type: 'SET_ERROR',
                payload: index,
              })
            }
            src={`https://flagcdn.com/24x18/${stock.country?.toLowerCase()}.png`}
            width="24"
            height="18"
            alt="Flag"
            type="image/png"
          />
        </span>
      )}
    </TableDataCenter>
  )

  const DataCell = ({ value, render, Wrapper }) => (
    <Wrapper>{value === 0 || value ? render(value) : <></>}</Wrapper>
  )

  const RenderDataCell = ({ field, stock, stockIndex }) => {
    if (field === 'logo') {
      return <StockIcon index={stockIndex} stock={stock} />
    } else if (field === 'symbol')
      return (
        <DataCell
          Wrapper={TableDataCenter}
          value={stock.symbol}
          render={(value) => (
            <div className="font-semibold text-textBase">{value}</div>
          )}
        />
      )
    else if (field === 'companyName')
      return (
        <DataCell
          value={stock.companyName}
          render={(value) => (
            <div className="text-textMuted min-w-[150px]">{value}</div>
          )}
          Wrapper={TableDataLeft}
        />
      )
    else if (field === 'country')
      return <CountryFlag index={stockIndex} stock={stock} />
    else if (field === 'exchangeShortName')
      return (
        <DataCell
          value={stock.exchangeShortName}
          render={(value) => (
            <div className="py-1 px-2 w-fit rounded-md border-1 border-textMuted text-textMuted">
              {value}
            </div>
          )}
          Wrapper={TableDataLeft}
        />
      )
    else if (field === 'sector')
      return (
        <DataCell
          value={stock.sector}
          render={(value) => (
            <div className="py-1 px-2 w-fit rounded-md border-1 border-[#ac5252] text-[#ac5252] whitespace-nowrap">
              {value}
            </div>
          )}
          Wrapper={TableDataLeft}
        />
      )
    else if (field === 'industry')
      return (
        <DataCell
          value={stock.industry}
          render={(value) => <div className="text-[#526aac]">{value}</div>}
          Wrapper={TableDataLeft}
        />
      )
    else if (DATA_FORMAT_TEST_DOLLAR_SIGN.includes(field))
      return (
        <DataCell
          value={stock[field]}
          Wrapper={TableDataRight}
          render={(value) => (
            <div className="text-textBase">${formatValueTest(value)}</div>
          )}
        />
      )
    else if (FORMAT_VALUE.includes(field)) {
      return (
        <DataCell
          value={stock[field]}
          Wrapper={TableDataRight}
          render={(value) => (
            <div
              className="font-semibold rounded px-3 absolute right-1/2 top-1/2 transform -translate-y-1/2 translate-x-1/2"
              style={colorVariants(value)}
            >
              {`${formatValue(value.toFixed(2))}%`}
            </div>
          )}
        />
      )
    } else if (DATA_FORMAT_TEST_PERCENTAGE_SIGN.includes(field)) {
      return (
        <DataCell
          value={stock[field]}
          Wrapper={TableDataRight}
          render={(value) => <>{formatValueTest(value)}%</>}
        />
      )
    } else if (FORMAT_VALUE_PLAIN.includes(field))
      return (
        <DataCell
          value={`$${formatValue(stock[field])}`}
          Wrapper={TableDataRight}
          render={(value) => <>{value}</>}
        />
      )
    else if (DATA_FORMAT_TEST_PLAIN.includes(field))
      return (
        <DataCell
          value={stock[field]}
          Wrapper={TableDataRight}
          render={(value) => <>{formatValueTest(value)}</>}
        />
      )
    else if (DATA_FORMAT_TEST_X.includes(field)) {
      return (
        <DataCell
          Wrapper={TableDataRight}
          render={(value) => (
            <>
              <NumericFormat
                value={value}
                displayType="text"
                thousandSeparator={true}
                decimalSeparator="."
                decimalScale={2}
                fixedDecimalScale={true}
              />
              x
            </>
          )}
          value={
            field === 'netDebtToEBITDATTM'
              ? netDebtToEBITDATTMFormatter(stock)
              : stock[field]
          }
        />
      )
    } else if (field === 'button-group')
      return (
        <>
          {' '}
          <TableIconCenter className="hidden group-hover:table-cell hover:text-textBase">
            {!watchList.includes(stock.symbol) ? (
              <Icon
                onClick={(e) => {
                  e.stopPropagation()
                  handleUpdateWatchList(stock.symbol)
                }}
              >
                <BsBookmarkHeart />
              </Icon>
            ) : (
              <Icon
                onClick={(e) => {
                  e.stopPropagation()
                  removeStockFromWatchList(stock.symbol)
                  reducerDispatch({ type: 'REMOVE_ELEM', payload: stockIndex })
                }}
              >
                <BsBookmarkX />
              </Icon>
            )}
          </TableIconCenter>
          <TableIconCenter className="visible group-hover:hidden text-xs">
            <Icon>
              <IoEllipsisHorizontalSharp />
            </Icon>
          </TableIconCenter>
        </>
      )
    else return <div></div>
  }

  return (
    <div className="max-w-[fit-content] w-full">
      <TableWrapper className={`lg:max-h-[calc(100vh-160px)] max-h-[calc(100vh-220px)]`}>
        <table className="table-fixed text-sm select-none">
          <thead className="p-2 text-xs bg-primaryBg">
            <tr>
              {filteredColumns.map((head, index) => (
                <TableHeader key={index} hasContent={head.displayedText !== ''}>
                  <HorizontalDiv 
                    className={`justify-center h-[50px] hover:text-textBase font-bold`}
                    onClick={() => head.sortable && handleSort(head.field)}
                  >
                    {sortField === head.field ? (
                      <span className="text-[#5288ce]">
                        {head.displayedText}
                      </span>
                    ) : (
                      <span>
                        {head.displayedText}
                      </span>
                    )}
                    {head.sortable && (
                      <ArrowContainer>
                        {sortField === head.field ? (
                          sortOrder === 1 ? (
                            <TiArrowSortedDown className="text-[#5288ce]" />
                          ) : (
                            <TiArrowSortedUp className="text-[#5288ce]" /> //#4ab6c9
                          )
                        ) : (
                          <TiArrowUnsorted />
                        )}
                      </ArrowContainer>
                    )}
                    {/* {head.sortable && filteredColumns.includes(head.displayedText) && (
                      <div className="w-[14px]">
                        <BsFunnelFill className="text-[#c94a4a]" />
                      </div>
                    )} */}
                  </HorizontalDiv>
                </TableHeader>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((stock, stockIndex) => (
              <TableRow
                key={`${stock.symbol}::${stockIndex}`}
                className="group"
                onClick={() => navigateToStockAnalysis(stock.symbol)}
              >
                {filteredColumns.map((col) => {
                  return (
                    <RenderDataCell
                      field={col.field}
                      stock={stock}
                      stockIndex={stockIndex}
                      key={`${stock.symbol}::${stockIndex}::${col.field}`}
                    />
                  )
                })}
              </TableRow>
            ))}
          </tbody>
        </table>
      </TableWrapper>

      <BuyModal
        open={modalType === 'buy'}
        close={() => setModalType(null)}
        icon={<BsCreditCard2Front />}
        header="Buy"
        type="Buy"
        ticker={selectedSymbol}
      />

      <BuyModal
        open={modalType === 'sell'}
        close={() => setModalType(null)}
        icon={<BsCreditCard2Front />}
        header="Sell"
        type="Sell"
        ticker={selectedSymbol}
      />

      <HorizontalDiv className="w-full pt-2 border-t-1 border-neutral">
        <HorizontalDiv className="w-1/3 justify-start">
          <NumericOptionSelector
            name="Rows"
            options={[5, 10, 20, 50]}
            selection={tableRows}
            setSelection={updateTableRows}
            setSelectionResolve={() => setCurrentPage(1)}
          />
        </HorizontalDiv>

        <HorizontalDiv className="w-1/3 justify-center">
          {!isMobile && (
            <>
              <HorizontalDiv className="w-[50px] justify-center">
                {currentPage > 1 && (
                  <>
                    <ArrowButton
                      icon={<FaAngleDoubleLeft />}
                      action={() => handlePageChange(1)}
                    />
                    <ArrowButton
                      icon={<FaAngleLeft />}
                      action={() => handlePageChange(currentPage - 1)}
                    />
                  </>
                )}
              </HorizontalDiv>

              <HorizontalDiv className="w-[260px] justify-center border-x-1 mx-4 space-x-2 px-2">
                {pageNumbers.map((page) => {
                  if (page === currentPage) {
                    return (
                      <NumberButtons
                        key={page}
                        text={page}
                        isActive={true}
                        action={() => handlePageChange(page)}
                      />
                    )
                  } else if (
                    page === 1 ||
                    page === totalPages ||
                    (page >= currentPage - 1 && page <= currentPage + 1)
                  ) {
                    return (
                      <NumberButtons
                        key={page}
                        text={page}
                        isActive={false}
                        action={() => handlePageChange(page)}
                      />
                    )
                  } else if (
                    (page === currentPage - 2 && currentPage > 3) ||
                    (page === currentPage + 2 && currentPage < totalPages - 2)
                  ) {
                    return (
                      <span className="mx-2" key={page}>
                        {' '}
                        ...{' '}
                      </span>
                    )
                  }
                  return null
                })}
              </HorizontalDiv>

              <HorizontalDiv className="w-[50px] justify-center">
                {currentPage < totalPages && (
                  <>
                    <ArrowButton
                      icon={<FaAngleRight />}
                      action={() => handlePageChange(currentPage + 1)}
                    />
                    <ArrowButton
                      icon={<FaAngleDoubleRight />}
                      action={() => handlePageChange(totalPages)}
                    />
                  </>
                )}
              </HorizontalDiv>
            </>
          )}
        </HorizontalDiv>
        <HorizontalDiv className="w-1/3 justify-end">
          {isMobile && (
            <>
              <HorizontalDiv className="w-[50px] justify-center">
                {currentPage > 1 && (
                  <>
                    <ArrowButton
                      icon={<FaAngleDoubleLeft />}
                      action={() => handlePageChange(1)}
                    />
                    <ArrowButton
                      icon={<FaAngleLeft />}
                      action={() => handlePageChange(currentPage - 1)}
                    />
                  </>
                )}
              </HorizontalDiv>

              <HorizontalDiv className="w-[50px] justify-center">
                {currentPage < totalPages && (
                  <>
                    <ArrowButton
                      icon={<FaAngleRight />}
                      action={() => handlePageChange(currentPage + 1)}
                    />
                    <ArrowButton
                      icon={<FaAngleDoubleRight />}
                      action={() => handlePageChange(totalPages)}
                    />
                  </>
                )}
              </HorizontalDiv>
            </>
          )}
        </HorizontalDiv>
      </HorizontalDiv>
    </div>
  )
}

const ArrowButton = ({ icon, action }) => {
  return <NextPreviousButton onClick={action}>{icon}</NextPreviousButton>
}

const formatValueTest = (value) => {
  return (
    <NumericFormat
      value={value}
      displayType="text"
      thousandSeparator={true}
      decimalSeparator="."
      decimalScale={2}
      fixedDecimalScale={true}
    />
  )
}

  // const valueChangeData = (symbol, value) => {
  //   return (
  //     <TableDataRight className="relative ">
  //       <div
  //         className="font-semibold rounded px-3 absolute right-1/2 top-1/2 transform -translate-y-1/2 translate-x-1/2"
  //         style={colorVariants(value)}
  //       >
  //         {formatValue(value)}%
  //       </div>
  //     </TableDataRight>
  //   )
  // }

  // const openModal = (type, symbol) => {
  //   setModalType(type)
  //   setSelectedSymbol(symbol)
  // }

{/* {showColumn('shares') && (
                  <TableDataCenter>
                    <span>7.30</span>
                  </TableDataCenter>
                )}
                {showColumn('avgPrice') && (
                  <TableDataCenter>
                    <span>$155.77</span>
                  </TableDataCenter>
                )}
                {showColumn('mktValue') && (
                  <TableDataCenter>
                    <span>$1,251.33</span>
                  </TableDataCenter>
                )}
                {showColumn('dailyReturn') && (
                  <TableDataCenter>
                    {valueChangeData(stock.symbol, '-13.35')}
                  </TableDataCenter>
                )}
                {showColumn('totalReturn') && (
                  <TableDataCenter>
                    {valueChangeData(stock.symbol, '114.67')}
                  </TableDataCenter>
                )}
                {showColumn('allocation') && (
                  <TableDataCenter className="text-violet-600 font-semibold ">
                    <span className="flex flex-row items-center space-x-2">
                      <ProgressBarContainer>
                        <ProgressBarFill
                          className="bg-violet-600"
                          progress={8.96}
                        />
                      </ProgressBarContainer>
                      <span>8.96%</span>
                    </span>
                  </TableDataCenter>
                )} */}

                {/* {columns.map((column, colIndex) => {
                  const Cell = TableDataCenter;

                  let category = column.field

                  return (
                    <>
                      {showColumn(column.field) && (
                        <Cell key={colIndex} onClick={() => navigateToStockAnalysis(stock.symbol)}>
                          {stock.symbol}
                        </Cell>
                      )}
                    </>
                  );
                })} */}

                {/* <TableIconCenter>
                  <Icon 
                    className='invisible group-hover:visible' 
                    onClick={() => openModal('buy', stock.symbol)}
                  >
                      <BsPlusSquare/>
                  </Icon>
                </TableIconCenter> */}

                {/* <BuyModal
                  open={openBuyModal}
                  close={() => {
                    setOpenBuyModal()
                  }}
                  icon={<BsCreditCard2Front/>}
                  header="Buy"
                  type="Buy"
                  ticker={stock.symbol}
                /> */}

                {/* <TableIconCenter>
                  <Icon 
                    className='invisible group-hover:visible' 
                    onClick={() => openModal('sell', stock.symbol)}
                  >
                    <BsShieldMinus/>
                  </Icon>
                </TableIconCenter> */}