import React from 'react';
import styled from "styled-components";
import tw from "twin.macro";
import dayjs from 'dayjs';
import { useFetchStockNewsQuery } from '../../rtk';

// Internal imports
import { ContentContainer } from '../../components/index';

// Styling
const ListContainer = styled.div` ${tw`w-full overflow-y-auto max-h-[300px]`}`;

const ListItem = styled.div` 
  ${tw`h-[50px] border-b border-neutralBg text-textMuted text-xs cursor-pointer hover:bg-neutralBg hover:shadow-md flex flex-col justify-center px-2 py-1`}
`;

const EllipsisContainer = styled.div`${tw`relative overflow-hidden`}`;
const EllipsisContent = styled.span`
  ${tw`block overflow-hidden`}
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const News = ({ symbol }) => {
  const { data: newsData, isLoading: isNewsLoading } = useFetchStockNewsQuery(symbol);

  return (
    <>
      {newsData?.data.length > 0 &&
        <ContentContainer
          Header='News'
          maxWidth='2500px'
          Content={
            <ListContainer>
              {!isNewsLoading && newsData?.data?.map(({ publishedDate, site, title, url }, index) => (
                <ListItem key={index} onClick={() => window.open(url, "_blank")}>
                  <div className='flex justify-left space-x-2'>
                    <div className='font-semibold text-xs text-textMuted'>{dayjs(publishedDate).format('DD MMM YYYY')}</div>
                    <span>-</span>
                    <EllipsisContainer className='font-semibold text-xs text-[#3b82f6]'>
                      <EllipsisContent>{site}</EllipsisContent>
                    </EllipsisContainer>
                  </div>
                  <EllipsisContainer className='font-semibold text-textBase'>
                    <EllipsisContent>{title}</EllipsisContent>
                  </EllipsisContainer>
                </ListItem>
              ))}
            </ListContainer>
          }
        />
      }
    </>
  );
}