const initialState = {
  theme: "light",
  isSettingTheme: false,
  user: null,
  token: null,
  
  fullSearchList: [],
  searchData: [],
  recentSearchList: [],
  searchedStock: 'GOOG',
  compareSymbol: {
    symbol: null,
    data: null,
  },

  mainSearchMetadata: {
    symbol: null,
    data: null,
  },

  screenerOptions: {},
  screenerResults: {},

  searchedStockData: {},
  macroeconomicData: {},
  
  watchList: [],
  watchListData: [],

  tableMetrics: ['country', 'exchangeShortName', 'sector', 'industry', 'mktCap', 'price', 'sixMonthPricePercentageChange', 'oneYearPricePercentageChange', '1YearPricePercentageChange', 'fiveYearPricePercentageChange', 'peRatio', 'ebitdaratio', 'revenueGrowthTTM', 'revenue5yrCAGR', 'revenue10yrCAGR', 'roic', 'freeCashFlowYieldTTM', 'dividendYieldTTM'],
  tableRows: 5,
  fundamentalPeriodType: '',
  fundamentalTimeHorizon: 10,
  priceChartTimeframe: '10Y',
  
  isFetchingWatchlist: true,
  isFetchingWatchlistData: true,

  latestStock: 'AAPL',
  isFetchingRecentSearchList: true,

  subscriptionType: 'starter',
  billingPeriod: '',
  hasActivePremiumSubscription: false,
  isGettingSubscriptionDetails: true,

  userGraphSettings: {},

  hasLoggedOut: false,
  price1dData: [],
  price5dData: [],
  price1mData: [],
  price6mData: [],
  price1yData: [],
  price5yData: [],
  price10yData: [],
  priceMaxData: [],
  hasChartData: false,

  userGraphPreferences: {},
  priceChartSymbol: 'AAPL',
  stripeCustomerId: undefined,
  trialBefore: false,
  subscriptionStatus: undefined,
};

export default initialState;