import React from 'react';

import { TbBuildingFactory, TbBuilding, TbWorld, TbClipboardList, TbCash, TbCalendarEvent, TbMap2, TbUsers, TbUser, TbMail, TbInfoSquare } from "react-icons/tb";

export const getCategoryIcon = (category) => {
  switch (category) {
    case 'metric': return <TbClipboardList className='text-lg mr-2' />;
    case 'country': return <TbWorld className='text-lg mr-2' />;
    case 'exchangeShortName': return <TbCash className='text-lg mr-2' />;
    case 'industry': return <TbBuildingFactory className='text-lg mr-2' />;
    case 'sector': return <TbBuilding className='text-lg mr-2' />;
    case 'date' : return <TbCalendarEvent className='text-lg mr-2' />;
    case 'location' : return <TbMap2 className='text-lg mr-2' />;
    case 'contact' : return <TbMail className='text-lg mr-2' />;
    case 'person' : return <TbUser className='text-lg mr-2' />;
    case 'people' : return <TbUsers className='text-lg mr-2' />;
    case 'details' : return <TbInfoSquare className='text-lg mr-2' />;
    default: return null;
  }
};