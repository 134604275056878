import React from 'react'

export const Company = ({ symbol }) => (
  <div className='flex flex-row items-center text-xs text-textMuted'>
    <img
      src={`https://financialmodelingprep.com/image-stock/${symbol}.png`}
      width="15"
      height="15"
      alt="stock logo"
      className="my-2 mr-2"
      style={{
        filter: 'drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.4))',
        borderRadius: '3px',
      }}
    />
    {symbol}
  </div>
);

export const CompanyLabel = ({symbol, compareSymbol, scaling}) => (
  <div className='w-full h-[10px] flex items-center justify-center pt-4'>
    {scaling &&
      <span className='w-[50px] text-xs ml-1 text-amber-500 font-semibold'></span>
    }
    <Company symbol={symbol}/>
    <div className='w-[24px]'/>
    <Company symbol={compareSymbol} scaling={scaling}/>
    {scaling &&
      <span className='w-[50px] text-xs ml-1 text-amber-500 font-semibold'>(Scaled)</span>
    }
  </div>
)