const lightTheme = {
  colors: {
    backgroundPrimary: '#FFFFFF',
    backgroundSecondary: '#E2E8F0',
    text: '#1F232E',
    textMuted: '#1f232eaa',
    placeholderText: '#929292',
    placeholderTextHover: '#1f2933',   
    borderPrimary: '#1F232E',
    borderSecondary: '#E2E8F088',
    filterSelectionBackground: '#bad1d3',
    priceChartFill: '#edf3edaa',
    chartDots: '#D9D9D9',
  },
};

const darkTheme = {
  colors: {
    backgroundPrimary: '#2F313B',
    backgroundSecondary: '#42464F',
    text: '#FFFFFF',
    textMuted: '#ffffff80',
    placeholderText: '#929292',
    placeholderTextHover: '#ffffff',   
    borderPrimary: '#D9D9D9',
    borderSecondary: '#BDBDBD22',
    filterSelectionBackground: '#95b8d1',
    priceChartFill: '#2a2c35',
    chartDots: '#BDBDBD22',
  },
};

export { lightTheme, darkTheme };