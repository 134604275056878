// External imports
import React from 'react';
import styled from "styled-components";
import tw from "twin.macro";
import { motion } from "framer-motion";
import numeral from 'numeral'
import { IoArrowUp, IoArrowDown } from "react-icons/io5";

// Styling
const HorizontalDiv = styled(motion.div)` ${tw`w-[fit-content] flex flex-row items-center justify-center font-bold rounded text-sm px-2 py-1 space-x-1 bg-neutralBg`}; `;

export const ValueChangeText = ({value, currencyValue, percentage}) => {

  return (
    <HorizontalDiv className={`${value > 0 ? 'text-textPositive' : value < 0 ? 'text-textNegative' : 'text-textBase'}`}>
      {
        value > 0 ? <IoArrowUp className='h-full flex justify-center'/> : 
        value < 0 ? <IoArrowDown className='h-full flex justify-center'/> : null
      }
      <div>{numeral(Math.abs(currencyValue)).format('$0,0.00')}</div>
      <div>
        <span className='font-thin'>(</span>
        {numeral(Math.abs(percentage)).format('0,0.00')}%
        <span className='font-thin'>)</span>
      </div>
    </HorizontalDiv>
  )
}