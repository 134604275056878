// Metadata for the metrics included in tables and dropdowns
export const metricOptions = [
  {field:"logo", displayedText:"", category:"Company info", sortable:false, isOptional:false, isMetric:false},
  {field:"symbol", displayedText:"Ticker", category:"Company info", sortable:true, isOptional:false, isMetric:false},
  {field:"companyName", displayedText:"Company name", category:"Company info", sortable:true, isOptional:true, isMetric:false},
  {field:"country", displayedText:"Country", category:"Company info", sortable:true, isOptional:true, isMetric:false},
  {field:"exchangeShortName", displayedText:"Exchange", category:"Company info", sortable:true, isOptional:true, isMetric:false},
  {field:"sector", displayedText:"Sector", category:"Company info", sortable:true, isOptional:true, isMetric:false},
  {field:"industry", displayedText:"Industry", category:"Company info", sortable:true, isOptional:true, isMetric:false},
  // {field:"earningsDate", displayedText:"Earnings Date", category:"Dates", sortable:true, isOptional:true, isMetric:false},
  // {field:"dividendDate", displayedText:"Dividend Date", category:"Dates", sortable:true, isOptional:true, isMetric:false},
  {field:"price", displayedText:"Price", category:"Price", sortable:true, isOptional:true, isMetric:true, prefix:"$"},
  {field:"oneDayPricePercentageChange", displayedText:"Price change: 1D", category:"Price", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"fiveDayPricePercentageChange", displayedText:"Price change: 5D", category:"Price", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"oneMonthPricePercentageChange", displayedText:"Price change: 1M", category:"Price", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"threeMonthPricePercentageChange", displayedText:"Price change: 3M", category:"Price", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"sixMonthPricePercentageChange", displayedText:"Price change: 6M", category:"Price", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"yearToDatePricePercentageChange", displayedText:"Price change: YTD", category:"Price", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"oneYearPricePercentageChange", displayedText:"Price change: 1Y", category:"Price", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"threeYearPricePercentageChange", displayedText:"Price change: 3Y", category:"Price", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"fiveYearPricePercentageChange", displayedText:"Price change: 5Y", category:"Price", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"tenYearPricePercentageChange", displayedText:"Price change: 10Y", category:"Price", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"maxPricePercentageChange", displayedText:"Price change: MAX", category:"Price", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"price5yrCAGR", displayedText:"Price CAGR: 5Y", category:"Price", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"price10yrCAGR", displayedText:"Price CAGR: 10Y", category:"Price", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"mktCap", displayedText:"Market Cap", category:"Valuation", sortable:true, isOptional:true, isMetric:true, prefix:"$"},
  {field:"enterpriseValueTTM", displayedText:"Enterprise Value", category:"Valuation", sortable:true, isOptional:true, isMetric:true, prefix:"$"},
  {field:"peRatioTTM", displayedText:"PE Ratio", category:"Valuation", sortable:true, isOptional:true, isMetric:true, suffix:"x"},
  {field:"pfcfRatioTTM", displayedText:"PFCF Ratio", category:"Valuation", sortable:true, isOptional:true, isMetric:true, suffix:"x"},
  {field:"pbRatioTTM", displayedText:"Price to book", category:"Valuation", sortable:true, isOptional:true, isMetric:true, suffix:"x"},
  {field:"revenueGrowthTTM", displayedText:"Revenue Growth", category:"Income statement (ttm)", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"revenue5yrCAGR", displayedText:"Revenue CAGR: 5Y", category:"Income statement (ttm)", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"revenue10yrCAGR", displayedText:"Revenue CAGR: 10Y", category:"Income statement (ttm)", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"ebitdaratio", displayedText:"EBITDA Margin", category:"Income statement (ttm)", sortable:true, isOptional:true, isMetric:true, suffix:"x"},
  {field:"ebitdaGrowthTTM", displayedText:"EBITDA Growth", category:"Income statement (ttm)", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"profitGrowthTTM", displayedText:"Profit Growth", category:"Income statement (ttm)", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"epsTTM", displayedText:"EPS", category:"Income statement (ttm)", sortable:true, isOptional:true, isMetric:true, suffix:"x"},
  {field:"epsDilutedTTM", displayedText:"EPS Diluted", category:"Income statement (ttm)", sortable:true, isOptional:true, isMetric:true, suffix:"x"},
  {field:"epsgrowth", displayedText:"EPS Growth", category:"Income statement (ttm)", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"currentRatioTTM", displayedText:"Current ratio", category:"Balance sheet", sortable:true, isOptional:true, isMetric:true, suffix:"x"},
  {field:"netAssetValue", displayedText:"Net Asset Value", category:"Balance sheet", sortable:true, isOptional:true, isMetric:true, prefix:"$"},
  {field:"bookValuePerShareTTM", displayedText:"Book Value per Share", category:"Balance sheet", sortable:true, isOptional:true, isMetric:true, suffix:"x"},
  {field:"netDebtToEBITDATTM", displayedText:"Net Debt to EBITDA", category:"Balance sheet", sortable:true, isOptional:true, isMetric:true, suffix:"x"},
  {field:"debtGrowth", displayedText:"Debt Growth", category:"Balance sheet", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"roicTTM", displayedText:"ROIC", category:"Management effectiveness", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"returnOnCapitalEmployedTTM", displayedText:"ROCE", category:"Management effectiveness", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"returnOnAssetsTTM", displayedText:"ROA", category:"Management effectiveness", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"returnOnEquityTTM", displayedText:"ROE", category:"Management effectiveness", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"freeCashFlowYieldTTM", displayedText:"FCF Yield", category:"Cashflow (ttm)", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"sbcAdjustedFreeCashFlowYieldTTM", displayedText:"SBC adj FCF Yield", category:"Cashflow (ttm)", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"freeCashFlowGrowthTTM", displayedText:"FCF Growth", category:"Cashflow (ttm)", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"freeCashFlowPerShare5yrCAGR", displayedText:"FCFPS: 5Y CAGR", category:"Cashflow (ttm)", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"freeCashFlowPerShare10yrCAGR", displayedText:"FCFPS: 10Y CAGR", category:"Cashflow (ttm)", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"stockBasedCompensationAdjustedFreeCashFlow5yrCAGR", displayedText:"SBC adj FCFPS: 5Y CAGR", category:"Cashflow (ttm)", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"stockBasedCompensationAdjustedFreeCashFlow10yrCAGR", displayedText:"SBC adj FCFPS: 10Y CAGR", category:"Cashflow (ttm)", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"dividendYieldTTM", displayedText:"Dividend Yield", category:"Dividends (ttm)", sortable:true, isOptional:true, isMetric:true, suffix:"%"},
  {field:"dividendsperShareGrowth", displayedText:"Dividend Growth", category:"Dividends (ttm)", sortable:true, isOptional:true, isMetric:true, suffix:"%"}, //UPDATE THIS
  {field:"payoutRatioTTM", displayedText:"Dividend Payout Ratio", category:"Dividends (ttm)", sortable:true, isOptional:true, isMetric:true, suffix:"%"},

  // {field:"shares", displayedText:"Shares", category:"Portfolio", sortable:true, isOptional:true, isMetric:true},
  // {field:"avgPrice", displayedText:"Average Price", category:"Portfolio", sortable:true, isOptional:true, isMetric:true},
  // {field:"mktValue", displayedText:"Mkt Value", category:"Portfolio", sortable:true, isOptional:true, isMetric:true},
  // {field:"dailyReturn", displayedText:"Daily Return", category:"Portfolio", sortable:true, isOptional:true, isMetric:true},
  // {field:"totalReturn", displayedText:"Total Return", category:"Portfolio", sortable:true, isOptional:true, isMetric:true},
  // {field:"allocation", displayedText:"Allocation", category:"Portfolio", sortable:true, isOptional:true, isMetric:true},
  
  {field:"analysis", displayedText:"", category:"Options", sortable:false, isOptional:false, isMetric:false},
  {field:"notifications", displayedText:"", category:"Options", sortable:false, isOptional:false, isMetric:false},
  {field:"add/remove", displayedText:"", category:"Options", sortable:false, isOptional:false, isMetric:false},
];

export const chartOptions = [
  {field:"price", displayedText:"Price", category:"Price & valuation"},
  {field:"marketCapTTM", displayedText:"Market capitalisation", category:"Price & valuation"},
  {field:"enterpriseValueTTM", displayedText:"Enterprise value", category:"Price & valuation"},
  {field:"peRatioTTM", displayedText:"PE ratio", category:"Price & valuation"},
  {field:"bookValuePerShareTTM", displayedText:"Book value per share", category:"Price & valuation"},
  {field:"freeCashFlowYieldTTM", displayedText:"Free Cash Flow Yield", category:"Price & valuation"},
  {field:"revenue", displayedText:"Revenue", category:"Income statement"},
  {field:"costOfRevenue", displayedText:"Cost of Revenue", category:"Income statement"},
  {field:"grossProfit", displayedText:"Gross profit", category:"Income statement"},
  {field:"researchExpenses", displayedText:"R&D", category:"Income statement"},
  {field:"generalAdmin", displayedText:"Admin", category:"Income statement"},
  {field:"marketing", displayedText:"Marketing", category:"Income statement"},
  {field:"ebitda", displayedText:"EBITDA", category:"Income statement"},
  {field:"cash", displayedText:"Cash", category:"Balance sheet"},
  {field:"debt", displayedText:"Long term debt", category:"Balance sheet"},
  {field:"capitalLeaseObligations", displayedText:"Capital lease obligations", category:"Balance sheet"},
  {field:"sharesOutstanding", displayedText:"Shares outstanding", category:"Equity"},
  {field:"dividend", displayedText:"Dividends", category:"Equity"},
  {field:"freeCashFlow", displayedText:"FCF", category:"Cashflow"},
  {field:"stockBasedCompensation", displayedText:"Stock based comp", category:"Cashflow"},
  {field:"stockBasedCompensationAdjustedFreeCashFlow", displayedText:"SBC adj FCF", category:"Cashflow"},

  {field:"roicTTM", displayedText:"ROIC", category:"Ratios"},
  {field:"roce", displayedText:"ROCE", category:"Ratios"},
  {field:"roa", displayedText:"ROA", category:"Ratios"},
  {field:"roe", displayedText:"ROE", category:"Ratios"},

  {field:"grossProfitRatio", displayedText:"Gross Profit", category:"Ratios"},
  {field:"ebitdaratio", displayedText:"EBITDA", category:"Ratios"},
  {field:"netIncomeRatio", displayedText:"Profit", category:"Ratios"},

  {field:"revenueGrowthTTM", displayedText:"Revenue", category:"Ratios"},
  {field:"netIncomeGrowth", displayedText:"Profit", category:"Ratios"},
  {field:"freeCashFlowGrowth", displayedText:"FCF", category:"Ratios"},
  {field:"peRatioTTM", displayedText:"P/E", category:"Ratios"},
  {field:"pfcfRatio", displayedText:"P/FCF", category:"Ratios"},
  {field:"epsTTM", displayedText:"EPS", category:"Ratios"},
  {field:"freeCashFlowPerShare", displayedText:"FCFPS", category:"Ratios"},
]

// Country names and their short names
export const nameConversions = [
  {name:"United States", shortName:"US", exchanges:['NYSE', 'NASDAQ', 'AMEX']},
  {name:"Canada", shortName:"CA", exchanges:['TSX']},
  {name:"United Kingdom", shortName:"UK", exchanges:['LSE']},
  {name:"Germany", shortName:"DE"},
  {name:"France", shortName:"FR"},
  {name: "Ireland", shortName:"IE"},
  {name: "Great Britain", shortName:"GB"},
  {name:"Italy", shortName:"IT"},
  {name:"Spain", shortName:"ES"},
  {name:"Netherlands", shortName:"NL"},
  {name:"Sweden", shortName:"SE"},
  {name:"Switzerland", shortName:"CH"},
  {name:"Belgium", shortName:"BE"},
  {name:"Australia", shortName:"AU", exchanges:['ASX']},
  {name:"Japan", shortName:"JP", exchanges:['JPX']},
  {name:"Hong Kong", shortName:"HK", exchanges:['HKSE']},
  {name:"Singapore", shortName:"SG"},
  {name:"South Korea", shortName:"KR"},
  {name:"China", shortName:"CN"},
  {name:"Taiwan", shortName:"TW"},
  {name:"India", shortName:"IN"},
  {name:"Brazil", shortName:"BR"},
  {name:"Mexico", shortName:"MX"},
  {name:"Argentina", shortName:"AR"},
  {name:"Chile", shortName:"CL"},
  {name:"Peru", shortName:"PE"},
  {name:"Colombia", shortName:"CO"},
  {name:"Venezuela", shortName:"VE"},
  {name:"South Africa", shortName:"ZA"},
  {name:"Egypt", shortName:"EG"},
]