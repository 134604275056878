import { useNavigate } from 'react-router-dom'
// External imports
import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { BsSun, BsPerson } from 'react-icons/bs'
import { IoLogOutOutline } from 'react-icons/io5'
import styled from 'styled-components'
import tw from 'twin.macro'

// Internal imports
import { setDisplayTheme } from '../../actions/userPreferences'
import { ContentContainer } from '../../components'
import useWindowSize from '../../hooks/useWindowSize'

//Styling
const Container = styled.div`
  ${tw`w-full flex flex-col items-center p-6`};
`

const DropDownLink = styled(Link)`
  ${tw`flex items-center text-sm gap-3.5 p-2 text-textMuted hover:bg-neutralBg rounded-md hover:text-textBase transition-transform ease-in-out`}
`

export const MobileAccountMenu = () => {
  const navigate = useNavigate()

  const resizeHanlder = () => {
    if (window.innerWidth > 550) {
      navigate(-1)
    }
  }

  const { logout } = useAuth0()
  const dispatch = useDispatch()

  useWindowSize(resizeHanlder)

  const toggleTheme = () => {
    const newTheme =
      localStorage.getItem('theme') === 'light' ? 'dark' : 'light'
    dispatch(setDisplayTheme(newTheme))
  }

  const doLogout = () => {
    dispatch({ type: 'SET_LOGOUT', payload: true })
    localStorage.removeItem('token')
    logout({ logoutParams: { returnTo: window.location.origin } })
  }

  return (
    <Container>
      <ContentContainer
        Content={
          <>
            <div className="w-full bg-primaryBg rounded space-y-2">
              <DropDownLink to="#" onClick={toggleTheme}>
                <div>
                  <BsSun className="w-[24px] h-[24px]" />
                </div>
                <div>Theme</div>
              </DropDownLink>
              <DropDownLink to="/account">
                <div>
                  <BsPerson className="w-[24px] h-[24px]" />
                </div>
                <div>Account</div>
              </DropDownLink>
              <DropDownLink to="#" onClick={() => doLogout()}>
                <div>
                  <IoLogOutOutline className="w-[24px] h-[24px]" />
                </div>
                <div>Logout</div>
              </DropDownLink>
            </div>
          </>
        }
      />
    </Container>
  )
}
