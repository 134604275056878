import React, { useEffect, useRef, useState } from 'react'
import { AccountSettingCardLayout } from './accountSettingCardLayout'
import { profileSettings } from '../../data/profile'
import { supportText } from '../../data/text'
import { Toaster, toast } from 'react-hot-toast'
import emailjs from '@emailjs/browser'
import { useAuth0 } from '@auth0/auth0-react'

import { FormButton } from '../../components/index'

export const AccountSupport = () => {
  const {user} = useAuth0();
  const formRef = useRef(0);
  const ref = useRef(null)
  const [isYScrollable, setIsYScrollable] = useState(false)

  useEffect(() => {
    const handler = () => {
      if (ref.current && ref.current.scrollHeight > ref.current.clientHeight) {
        setIsYScrollable(true)
      } else {
        setIsYScrollable(false)
      }
    }

    handler();

    window.addEventListener('resize', handler)
    return () => window.removeEventListener
  }, [ref.current])

  const sendEmail = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const templateParams = {
      from_name: user.email,
      message: `Type: ${formData.get('select')}\nMessage: ${formData.get('message')}`
    };
    toast.loading('Sending...', {duration: 2000});
    emailjs.send(
      process.env.REACT_APP_EMAIL_SERVICE_ID,
      process.env.REACT_APP_EMAIL_TEMPLATE_ID,
      templateParams,
      process.env.REACT_APP_EMAIL_PUB_KEY,
    ).then(() => {
      formRef.current?.reset();
      toast.success('Successfully sent!');
    }).catch(() => toast.error('Failed to send'));
  };

  const supportSetting = profileSettings.find(
    (setting) => setting.header === 'Support'
  )
  return (
    <AccountSettingCardLayout
      icon={supportSetting.icon}
      pageName={supportSetting.header}
      content={
        <div 
          className={`w-[fit] flex flex-col justify-start align-top space-y-2 overflow-y-auto ${
            isYScrollable ? 'pr-2' : 'pr-0'
          }`}
          ref={ref}
        >
          {supportText.map((section, index) => (
            <div
              className="w-full flex flex-col justify-start text-justify align-top text-xs"
              key={index}
            >
              <h1 className="w-[fit] flex flex-row font-semibold text-base">
                {section.header}:
              </h1>
              <p className='text-textMuted'>{section.body}</p>
            </div>
          ))}
          <div>
            <Toaster />
          </div>
          <form
            className="w-[fit] flex flex-col"
            onSubmit={sendEmail}
            ref={formRef}
          >
            <label className="font-semibold" htmlFor="Type">
              Support type:
            </label>
            <select
              className="rounded mb-2 bg-neutralBg text-sm h-[30px]"
              id="issue-type"
              name="select"
            >
              <option value="Technical issue" defaultValue>Technical issue</option>
              <option value="General question">General question</option>
              <option value="Suggestion">Suggestion</option>
              <option value="Other">Other</option>
            </select>
            <label className="font-semibold" htmlFor="message">Message:</label>
            <textarea
              className="h-[200px] rounded mb-2 bg-neutralBg p-1 text-sm"
              id="message"
              name="message"
              required
            />
            <FormButton text="Submit"/>
          </form>
        </div>
      }
      yScroll={false}
      maxHeight={'80vh'}
    />
  )
}
