import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

const SliderWrapper = styled.div`${tw`w-full overflow-hidden`}`;
const SliderContent = styled.div`${tw`w-full h-full flex flex-row items-start px-2`}`;

export const Slider = ({ children }) => {
  const containerRef = useRef(null);
  const [scrollOffset, setScrollOffset] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollStartX, setScrollStartX] = useState(0);
  const [isGrabCursorVisible, setIsGrabCursorVisible] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const { clientWidth, scrollWidth } = containerRef.current;
        setScrollOffset(Math.min(scrollOffset, scrollWidth - clientWidth));
        setIsGrabCursorVisible(scrollWidth > clientWidth);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [scrollOffset]);

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (dragging) {
        const { clientX } = event;
        const dragDistance = clientX - startX;
        containerRef.current.scrollLeft = scrollStartX - dragDistance;
        setScrollOffset(containerRef.current.scrollLeft);
      }
    };

    const handleMouseUp = () => {
      if (dragging) {
        setDragging(false);
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      }
    };

    if (dragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [dragging, startX, scrollStartX]);

  const handleMouseDown = (event) => {
    event.preventDefault();
    setDragging(true);
    setStartX(event.clientX);
    setScrollStartX(containerRef.current.scrollLeft);
  };

  useEffect(() => {
    const handleContentResize = () => {
      const { clientWidth, scrollWidth } = containerRef.current;
      if (scrollWidth <= clientWidth) {
        setScrollOffset(0);
        setIsGrabCursorVisible(false);
      } else {
        setIsGrabCursorVisible(true);
      }
    };

    const observer = new ResizeObserver(handleContentResize);
    observer.observe(containerRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <SliderWrapper ref={containerRef} onMouseDown={handleMouseDown}>
      <SliderContent className={(dragging && isGrabCursorVisible) ? 'cursor-grabbing' : isGrabCursorVisible ? 'cursor-grab' : ''}>
        {children}
      </SliderContent>
    </SliderWrapper>
  );
};